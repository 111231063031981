import './ZoomAllControl.css';

import * as olExtent from 'ol/extent';
import { Control } from 'ol/control';

import VectorLayer from 'ol/layer/Vector';

import imageZoomAll from './icons/Zoom-All.svg'

var ZoomAllControl = /*@__PURE__*/(function (Control) {
    function ZoomAllControl(opt_options) {
        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'zoom-all ol-unselectable ol-control';

        var btn_zoom_all = document.createElement('input');
        //
        btn_zoom_all.className = 'zoom-all-button-zoom-all zoom-all-button';
        btn_zoom_all.title = 'Zoom all';
        btn_zoom_all.type = 'image';
        btn_zoom_all.src = imageZoomAll;
        //
        btn_zoom_all.addEventListener('click', this.handleZoomAll.bind(this), false);
        //
        element.appendChild(btn_zoom_all);

        Control.call(this, {
            element: element,
            target: options.target
        });

        /*
        var button = document.createElement('button');
        //
        button.innerHTML = 'Z';

        var element = document.createElement('div');
        element.className = 'zoom-all ol-unselectable ol-control';
        element.appendChild(button);

        Control.call(this, {
            element: element,
            target: options.target
        });

        button.addEventListener('click', this.handleZoomAll.bind(this), false);
        */
    }

    if (Control) ZoomAllControl.__proto__ = Control;
    ZoomAllControl.prototype = Object.create(Control && Control.prototype);
    ZoomAllControl.prototype.constructor = ZoomAllControl;

    ZoomAllControl.prototype.getVectorExtent = function (layers, result) {
        //
        var array = layers.getArray();
        //
        for (var i = 0; i < array.length; i++) {
            //
            if (array[i].getLayers) {
                //
                this.getVectorExtent(array[i].getLayers(), result);
                //
            } else {
                //
                var layer = array[i];
                //
                if (layer instanceof VectorLayer)
                    olExtent.extend(result.extent, layer.getExtent());
            }
        }
    }
    ZoomAllControl.prototype.getMapExtent = function (layers, result) {
        //
        return null;

        /*
        var array = layers.getArray();
        //
        for (var i = 0; i < array.length; i++) {
          //
          if (array[i].getLayers) {
            //
            LayerSwitcher.getMapExtent(array[i].getLayers(), result);
            //
          } else {
            //
            var layer = array[i];
            //
            var image = layer.getAttribute('data-image');
            //
            if (image !== null)
              olExtent.extend(result.extent, layer.getExtent());
          }
        }
        */
    }

    ZoomAllControl.prototype.handleZoomAll = function handleZoomAll() {

        var map = this.getMap();

        //var bg = map.getLayers().item(0);
        var bg = map.getBackground();
        //
        console.log('ZoomAll: bg =' + bg);

        if (bg == null)
            return;

        console.log('ZoomAll: ZOOMING!');
        console.log('  bg             = ', bg);
        console.log('  bg.getExtent() = ', bg.getExtent());
        console.log('  map.getSize () = ', map.getSize());

        if (!bg.getExtent())
            return;

        var view = map.getView();
        //
        view.fit(bg.getExtent(), map.getSize());

        /*
        const width = 7050;
        const height = 4621;
        //
        const extent = [0.00000000, -width, height, 0.00000000];
        //
        view.fit(extent, map.getSize());
        /**/
    };

    return ZoomAllControl;
}(Control));

export default ZoomAllControl;