import Popup from '../utils/Popup'
//
import './PopupHelp.css';
//
import imageImageOpen from '../controls/icons/Image-Open.svg'
//
import imageZoomAll from '../controls/icons/Zoom-All.svg'
import imageZoomIn from '../controls/icons/Zoom-In.svg'
import imageZoomOut from '../controls/icons/Zoom-Out.svg'
//
import imageCamera from '../controls/icons/Camera.svg'
//
import imageGoogleEarth from '../controls/icons/GoogleEarth.svg'
//
import imageFullscreenOn from '../controls/icons/Fullscreen-On.svg'
import imageFullscreenOff from '../controls/icons/Fullscreen-Off.svg'
//
import imageRotate from '../controls/icons/Rotate.svg'
//
import imageLayers from '../controls/icons/Layers.svg'
//
import imageSelect from '../controls/icons/Edit-Select.svg'
import imageTrash from '../controls/icons/Edit-Trash.svg'
import imagePoint from '../controls/icons/Edit-Point.svg'
import imageLine from '../controls/icons/Edit-Line.svg'
import imageCircle from '../controls/icons/Edit-Circle.svg'
import imagePolygon from '../controls/icons/Edit-Polygon.svg'
import imageCheckOn from '../controls/icons/Edit-Check-On.svg'
import imageCheckOff from '../controls/icons/Edit-Check-Off.svg'
//
import imageExport from '../controls/icons/File-Export.svg'
import imageImport from '../controls/icons/File-Import.svg'
//
import imageSessionOpen from '../controls/icons/Session-Open.svg'
import imageSessionSave from '../controls/icons/Session-Save.svg'
//
import imageGpsOn from '../controls/icons/Gps-On.svg'
import imageGpsOff from '../controls/icons/Gps-Off.svg'

export default class PopupHelp extends Popup {

    constructor() {

        console.log('PopupHelp: constructor...');
        //
        super('popupHelp');

        var p = null;
        var ul = null;
        var li = null;

        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        super.setTitle("Help on Historical Reality");
        //----------------------------------------------------------

        /*
        // Title
        p = document.createElement("p");
        //
        p.className = 'help-title';
        p.innerHTML = "Help on Historical Reality";
        //
        super.addToContent(p);
        */

        // Contents
        p = document.createElement("p");
        //
        p.className = 'help-content';

        ul = document.createElement("ul");
        //
        ul.className = 'help-list';
        //
        this.new_li_(ul, imageImageOpen, "Open custom or predefined picture");
        //
        this.new_li_(ul, imageZoomAll, "Fit the image to the window");
        //
        this.new_li_(ul, imageZoomIn, "Zoom in");
        this.new_li_(ul, imageZoomOut, "Zoom out");
        //
        this.new_li_(ul, imageFullscreenOn, "Enter full screen mode");
        this.new_li_(ul, imageFullscreenOff, "Exit full screen mode");
        //
        this.new_li_(ul, imageRotate, "Reset rotation");
        //
        this.new_li_(ul, imageLayers, "Layer manager");
        //
        this.new_li_(ul, imagePoint, "Insert point");
        this.new_li_(ul, imageLine, "Insert polyline");
        this.new_li_(ul, imagePolygon, "Insert polygon");
        //
        /*
        this.new_li_(ul, imageCheckOn, "Check for geometry problems");
        this.new_li_(ul, imageCheckOff, "Remove check geometry points");
        */
        //
        this.new_li_(ul, imageSelect, "Select object(s)");
        this.new_li_(ul, imageTrash, "Delete object(s)");
        //
        this.new_li_(ul, imageExport, "Export object(s) to *.json, *.kml. *.gpx file");
        this.new_li_(ul, imageImport, "Import object(s) from *.json, *.kml. *.gpx file");
        //
        this.new_li_(ul, imageSessionOpen, "Restore the saved session");
        this.new_li_(ul, imageSessionSave, "Save the current session");
        //
        this.new_li_(ul, imageGpsOn, "Enable GPS navigation");
        this.new_li_(ul, imageGpsOff, "Disable GPS navigation");
        //
        this.new_li_(ul, imageCamera, "Take photo saving camera data");
        //
        this.new_li_(ul, imageGoogleEarth, "Open Google Earth from the same perspective");
        //
        p.appendChild(ul);
        //
        super.addToContent(p);

        return;

        p = document.createElement("p");
        //
        p.innerHTML = "To be completed...";
        //
        super.addToContent(p);
    }

    new_li_(ul, image, text) {
        //
        var li = document.createElement('li');
        //
        li.className = 'help-li';

        var img = document.createElement("img");
        //
        img.className = 'help-button';
        img.type = 'image';
        img.src = image;
        //
        li.appendChild(img);

        var txt = document.createTextNode(text);
        //
        txt.className = 'help-text';
        //
        li.appendChild(txt);

        ul.appendChild(li);
    }
}