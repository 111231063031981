import './Popup.css';
//
//import { Control } from 'ol/control';


export default class Popup {

  constructor(ID) {

    console.log('Popup: constructor...');

    //-------------------------------------------------------
    // Main element
    //-------------------------------------------------------
    var element = document.createElement('div');
    //
    element.id = ID;
    element.className = "modal";
    //
    element.style.display = "none";
    //
    this.element = element;
    //-------------------------------------------------------
    // Body
    //-------------------------------------------------------
    var body = document.createElement('div');
    //
    body.className = "modal-body";
    //
    this.body = body;
    //
    element.appendChild(body);
    //-------------------------------------------------------
    // Title and close button
    //-------------------------------------------------------
    var header = document.createElement('div');
    //
    header.className = "modal-header";
    //
    this.header = header;

    // Title text
    var title = document.createElement('text');
    //
    title.className = "modal-title";
    //
    title.innerHTML = "TITLE";
    //
    this.title = title;
    //
    header.appendChild(title);

    // Close button
    var span = document.createElement("span");
    //
    span.className = "modal-close";
    span.innerHTML = "&times;";
    //
    span.popup = element;
    //
    span.onclick__ = this.hide_;

    let popup = this;
    //
    span.onclick = function (e) {
      //
      /*
      console.log('span.onclick(): e = ', e);
      console.log('  this = ', this);
      console.log('  this.popup = ', this.popup);
      /**/
      popup.hide();
    }
    //
    header.appendChild(span);
    //
    body.appendChild(header);
    //-------------------------------------------------------
    // Content
    //-------------------------------------------------------
    var content = document.createElement('div');
    //
    content.className = "modal-content";
    //
    this.content = content;
    //
    body.appendChild(content);
    //-------------------------------------------------------
    // Footer
    //-------------------------------------------------------
    var footer = document.createElement('div');
    //
    footer.className = "modal-footer";
    //
    //footer.innerHTML = "FOOTER";
    //
    this.footer = footer;
    //
    body.appendChild(footer);
    //-------------------------------------------------------
    // Mouse click handler
    //-------------------------------------------------------
    this.handleClick_ = this.onClick_.bind(this);
    //-------------------------------------------------------
  }

  setTitle(title) {
    //
    this.title.innerHTML = title;
  }

  addToHeader(obj) {
    //
    this.header.appendChild(obj);
  }
  addToContent(obj) {
    //
    this.content.appendChild(obj);
  }
  addToFooter(obj) {
    //
    this.footer.appendChild(obj);
  }

  appendTo(parent) {
    //
    parent.appendChild(this.element);
  }

  onClick_(e) {
    //
    console.log('Popup.onClick_(): e = ', e);
    console.log('  this = ', this);
    console.log('  e.target = ', e.target);

    if (e.target && e.target.self && (e.target == e.target.self)) {
      //
      console.log('  e.target && e.target.self && (e.target == e.target.self');

    }

    if (this.body.contains(e.target)) {
      //
      console.log('  click in BODY!!!!!!');
      //
      return;
    }

    console.log('  click outside BODY!!!!!!');
    //
    //e.stopPropagation();
    //
    this.hide();
  }

  show() {
    //
    console.log('Popup.show()...');
    //
    var element = this.element;
    //
    element.style.display = "block";
    //
    document.addEventListener("click", this.handleClick_, true);
    //document.addEventListener("click", this.onClick_, true);
    //element.addEventListener("click", this.onClick_, true);

    return;

    window.onclick = function (e) {
      //
      //*
      console.log('Popup.window.onclick(): e = ', e);
      console.log('Popup.window.onclick(): element  = ', element);
      console.log('Popup.window.onclick(): e.target = ', e.target);
      /**/
      //
      if (e.target === element) {
        //
        e.preventDefault();
        //
        element.style.display = "none";
      }
    }

  }
  hide() {
    //
    console.log('Popup.hide()...');
    //
    var element = this.element;
    //
    document.removeEventListener("click", this.handleClick_, true);
    //document.removeEventListener("click", this.onClick_, true);
    //element.removeEventListener("click", this.onClick_, true);
    //
    element.style.display = "none";
  }
}
