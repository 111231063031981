import './FullScreenControl.css';

import FullScreen from 'ol/control/FullScreen';

import imageFullscreenOn from './icons/Fullscreen-On.svg'
import imageFullscreenOff from './icons/Fullscreen-Off.svg'

class FullScreenControl extends FullScreen {

    constructor(opt_options) {

        const options = opt_options ? opt_options : {};

        super(options);

        var element = this.element;
        //
        element.className = 'full-screen ol-unselectable ol-control';

        // Remove all children
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }

        // See FullScreen.js
        var btn_full_screen = document.createElement('input');

        btn_full_screen.className = 'full-screen-button';
        btn_full_screen.title = 'Toggle full-screen';
        btn_full_screen.type = 'image';
        //
        if (isFullScreenSupported() && isFullScreen())
            btn_full_screen.src = imageFullscreenOff;
        else
            btn_full_screen.src = imageFullscreenOn;
        //
        if (!isFullScreenSupported())
            btn_full_screen.disabled = true;
        else
            btn_full_screen.addEventListener('click', this.handleClick_.bind(this), false);
        //
        element.appendChild(btn_full_screen);

        this.button_ = btn_full_screen;
    }

    handleClick_(event) {
        //
        console.log('FullScreenControl.handleClick_()...');
        console.log('  this.getMap() = ', this.getMap());
        //
        super.handleClick_(event);
    }

    setClassName_(button, fullscreen) {
        //
        //console.log('FullScreenControl.setClassName_(' + fullscreen + ')...');
        //console.log('     button = ', button);
        //
        if (fullscreen)
            button.src = imageFullscreenOff;
        else
            button.src = imageFullscreenOn;
    }
}

// See FullScreen.js
function isFullScreenSupported() {
    const body = document.body;
    return !!(
        body.webkitRequestFullscreen ||
        (body.msRequestFullscreen && document.msFullscreenEnabled) ||
        (body.requestFullscreen && document.fullscreenEnabled)
    );
}

function isFullScreen() {
    return !!(
        document.webkitIsFullScreen || document.msFullscreenElement || document.fullscreenElement
    );
}

export default FullScreenControl;