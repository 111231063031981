import './ZoomControl.css';

import Zoom from 'ol/control/Zoom';

import imageZoomIn from './icons/Zoom-In.svg'
import imageZoomOut from './icons/Zoom-Out.svg'

class ZoomControl extends Zoom {

    constructor(opt_options) {

        const options = opt_options ? opt_options : {};

        super(options);

        var element = this.element;
        //
        element.className = 'zoom ol-unselectable ol-control';

        // Remove all children
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }

        // See Zoom.js
        const delta = options.delta !== undefined ? options.delta : 1;

        var btn_zoom_in = document.createElement('input');
        //
        btn_zoom_in.className = 'zoom-button-zoom-in zoom-button';
        btn_zoom_in.title = 'Zoom in';
        btn_zoom_in.type = 'image';
        btn_zoom_in.src = imageZoomIn;
        //
        btn_zoom_in.addEventListener('click', this.handleClick_.bind(this, delta), false);
        //
        element.appendChild(btn_zoom_in);

        var btn_zoom_out = document.createElement('input');
        //
        btn_zoom_out.className = 'zoom-button-zoom-out zoom-button';
        btn_zoom_out.title = 'Zoom out';
        btn_zoom_out.type = 'image';
        btn_zoom_out.src = imageZoomOut;
        //
        btn_zoom_out.addEventListener('click', this.handleClick_.bind(this, -delta), false);
        //
        element.appendChild(btn_zoom_out);
    }

    handleClick_(delta, event) {
        //
        super.handleClick_(delta, event);
    }

    handleZoomIn() {
        //
        //super.
    }
}

export default ZoomControl;