import './ImageControl.css';

import { Control } from 'ol/control';

import TileJSON from 'ol/source/TileJSON';

import TileLayer from 'ol/layer/Tile';

//import io from "socket.io-client";

import SocketConnection from '../utils/SocketConnection';

import imageImageOpen from './icons/Image-Open.svg'

// ImageControl
export default class ImageControl extends Control {

  constructor(opt_options) {

    var options = opt_options || {};

    var element = document.createElement('div');
    //
    element.className = 'image ol-unselectable ol-control';

    super({ element: element, target: options.target });

    var btn_open = document.createElement('input');
    //
    btn_open.className = 'session-button-open session-button';
    btn_open.title = 'Open picture';
    btn_open.type = 'image';
    btn_open.src = imageImageOpen;
    //
    btn_open.addEventListener('click', this.openImage.bind(this), false);

    element.appendChild(btn_open);
    //
    Control.call(this, {
      element: element,
      target: options.target
    });
  }

  openImage(e) {
    //
    console.log('ImageControl::openImage');
    //
    e.preventDefault();
    //
    this.getMap().askOpenPicture();
  }
}



// ImageControl
var __ImageControl = /*@__PURE__*/(function (Control) {
  function __ImageControl(opt_options) {
    var options = opt_options || {};

    console.log('ImageControl: constructor...');

    var element = document.createElement('div');
    //
    element.className = 'image ol-unselectable ol-control';

    var btn_zoom_all = document.createElement('input');
    //
    btn_zoom_all.className = 'image-button-open image-button';
    btn_zoom_all.title = 'Open picture';
    btn_zoom_all.type = 'image';
    btn_zoom_all.src = imageImageOpen;
    //
    btn_zoom_all.addEventListener('click', this.handleFileOpen.bind(this), false);
    //
    element.appendChild(btn_zoom_all);

    Control.call(this, {
      element: element,
      target: options.target
    });

    console.log('ImageControl: constructor DDDD');

    this.fileSelector = null;

    /*
    this.fileSelector = document.createElement('input');
    this.fileSelector.type = 'file';
    //this.fileSelector.accept = 'image/*';
    this.fileSelector.accept = '.mpt';
    this.fileSelector.onchange = this.handleFileChange;

    this.fileSelector.map = this.getMap();

    console.log('this.fileSelector.map = ' + this.fileSelector.map);

    console.log('ImageControl: constructor = ' + this.fileSelector);
    */

    this.map = this.getMap();
  }

  if (Control) __ImageControl.__proto__ = Control;
  __ImageControl.prototype = Object.create(Control && Control.prototype);
  __ImageControl.prototype.constructor = __ImageControl;

  __ImageControl.prototype.handleFileOpen = function handleFileOpen(e) {

    console.log('OpenFileControl::handleFileOpen');
    console.log('OpenFileControl: e = ' + e);

    var map = this.getMap();
    //
    console.log('handleFileOpen: map = ' + map);
    /*
        for (var property in map) {
          console.log(property + ': ' + map[property] + '; ');
        }
    /**/

    e.preventDefault();

    // Initialize and click file selector
    this.fileSelector = document.createElement('input');
    this.fileSelector.type = 'file';
    //this.fileSelector.accept = 'image/*';
    this.fileSelector.accept = '.m_picture';
    this.fileSelector.onchange = this.handleFileChange;
    //
    this.fileSelector.map = this.getMap();
    //
    this.fileSelector.click();

    console.log('handleFileOpen: fileSelector = ' + this.fileSelector);
    /*
    for (var property in this) {
      console.log(property + ': ' + this[property] + '; ');
    }
    /**/

    //this.getMap().getView().setRotation(0);
  };

  //ImageControl.prototype.openFile_MPT = function openFile_MPT(file, map) {
  __ImageControl.prototype.handleFileChange = function handleFileChange(e) {

    console.log('handleFileChange: fileSelector = ' + this.fileSelector);

    console.log('handleFileChange: e = ' + e);
    /*
    for (var property in e) {
      console.log(property + ': ' + e[property] + '; ');
    }
    /**/

    console.log('handleFileChange: e.srcElement = ' + e.srcElement);
    /*
    for (var property in e.srcElement) {
      console.log(property + ': ' + e.srcElement[property] + '; ');
    }
    /**/

    //return;

    const files = e.target.files;

    console.log('handleFileChange: files = ' + files);
    //console.log('handleFileChange: files.size = ' + files.getSize());

    const file = files[0]

    if (file == null) {
      alert("No file to load!");
      return;
    }

    console.log('handleFileChange: file = ' + file);
    console.log('handleFileChange: file.name = ' + file.name);

    if (file.name.toLowerCase().endsWith(".m_picture")) {
      //
      const fileSelector = e.srcElement;
      //
      const map = fileSelector.map;
      //
      __ImageControl.openFile_PICTURE(file, map);
      //
      return;
    }

    if (file.name.toLowerCase().endsWith(".png")) {
      //
      const fileSelector = e.srcElement;
      //
      const map = fileSelector.map;
      //
      ImageControl.openFile_PNG(file, map);
      //
      return;
    }

    alert("Illegal file!");
    return;

    if (false)
      if (true) {
        if (file.name.toLowerCase().endsWith(".mpt")) {
          console.log('Loading MPT fie: ' + file.name);
        } else {
          alert("Illegal file!");
          return;
        }

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;

        console.log('handleFileChange: map = ' + map);

        const reader = new FileReader();

        reader.onload = function (e) {

          const text = reader.result;

          var obj = null;

          try {

            obj = JSON.parse(text);
          }
          catch (ex) {

            alert('Error parsing file ' + file.name + ' [' + ex + ']!');
            //
            return;
          }

          map.socket = new SocketConnection(map, obj);
        }

        if (file) {
          reader.readAsText(file);
        }
      }
  }

  return __ImageControl;
}(Control));

// Open file MPT
ImageControl.openFile_PICTURE = function (file, map) {
  //
  console.log('Loading MPT file: ' + file.name);
  //
  const reader = new FileReader();
  //
  reader.onload = function (e) {

    const text = reader.result;

    var obj = null;

    try {

      obj = JSON.parse(text);
    }
    catch (ex) {

      alert('Error parsing file ' + file.name + ' [' + ex + ']!');
      //
      return;
    }

    new SocketConnection(map, obj);

    //map.socket = new SocketConnection(map, obj);
  }

  if (file) {
    reader.readAsText(file);
  }
}

//export default __ImageControl;  