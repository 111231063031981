
import './PopupFeature.css';


export default class PopupFeature {

    constructor() {
        //
        var popup = document.createElement('div');
        //
        popup.className = 'ol-feature-popup';
        //
        this.popup_ = popup;

        /*
        var popup_closer = document.createElement('a');
        //
        popup_closer.href = '#';
        //
        popup_closer.className = 'ol-feature-popup-closer';
        //
        popup.appendChild(popup_closer);
        //
        this.popup_closer_ = popup_closer;
        */

        var popup_content = document.createElement('div');
        //
        popup_content.className = 'ol-feature-popup-content';
        //
        popup.appendChild(popup_content);
        //
        this.popup_content_ = popup_content;

        this.popup_content_.onclick = function (e) {
            //
            //e.preventDefault();
            e.stopPropagation();
            /*
            console.log('PopupFeature.onclick(): e = ', e);
            console.log('PopupFeature.onclick(): e.target = ', e.target);
            console.log('PopupFeature.onclick(): e.target.tagName = ', e.target.tagName);
            /**/
            //
            if (e.target && (e.target.tagName === 'A')){
                //
                console.log('PopupFeature.onclick(): e.target.href  = ', e.target.href );


            }

            //if (e.target instanceof )
            /*
            if (e.target !== btnImport) {
                //
                //e.preventDefault();
                //
                menuFileImport.style.display = "none";
            }
            */
        }
        /*
        <div id="popup" class="ol-popup">
        <a href="#" id="popup-closer" class="ol-popup-closer"></a>
        <div id="popup-content"></div>
        </div>
        */
    }

    setOverlay(overlay) {
        //
        var popup_closer = this.popup_closer_;
        //
        if (popup_closer) {
            //
            popup_closer.onclick = function () {
                //
                overlay.setPosition(undefined);
                popup_closer.blur();
                return false;
            };
        }
        //
        overlay.setElement(this.popup_);
    }
    setContent(text) {
        //
        this.popup_content_.innerHTML = text;
    }
}
