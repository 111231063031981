//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
// String utilities: spaces
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
//exports.strSpace = (n) => {
function strSpace(n) {
    //
    var str = "";
    //
    var i;
    //
    for (i = 0; i < n; i++) {
        str += "&nbsp;";
    }
    //
    return str;
}
//---------------------------------------------------------------------------
export { strSpace };
//---------------------------------------------------------------------------
/*
const strSpace_2 = "&nbsp;&nbsp;";
const strSpace_3 = "&nbsp;&nbsp;&nbsp;";
const strSpace_4 = "&nbsp;&nbsp;&nbsp;&nbsp;";
const strSpace_5 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
const strSpace_6 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
const strSpace_7 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
const strSpace_8 = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
//---------------------------------------------------------------------------
exports.strSpace_2 = strSpace_2;
exports.strSpace_3 = strSpace_3;
exports.strSpace_4 = strSpace_4;
exports.strSpace_5 = strSpace_5;
exports.strSpace_6 = strSpace_6;
exports.strSpace_7 = strSpace_7;
exports.strSpace_8 = strSpace_8;
*/
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
