import React from 'react';

import LayerSwitcher from '../controls/LayerSwitcher';

//===================================================================
import LayerGroup from 'ol/layer/Group';

import TileLayer from 'ol/layer/Tile';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML';
import GPX from 'ol/format/GPX';

import { Select } from 'ol/interaction';

import Projection from "../utils/Projection";

import Attr_TOPO from "../utils/Attr_TOPO";

import Utilities from "../utils/Utilities";

import './LayerMngr.css';

import imageHide from './icons/Hide.svg'
import imageShow from './icons/Show.svg'

import imageMoveDown from './icons/ArrowDown.svg'
import imageMoveUp from './icons/ArrowUp.svg'
import imageEdit from './icons/Edit.svg'
import imageLayer from './icons/Layer.svg'
import imageLayers from './icons/Layers.svg'
import imageSettings from './icons/Settings.svg'
import imageDeleteX from './icons/DeleteX.svg'
import imageDeleteTrash from './icons/DeleteTrash.svg'
import { asArray } from 'ol/color';
import Layer from 'ol/layer/Layer';
//===================================================================

const fs = require('fs');
const path = require('path');

// Default layer/group name
function DefaultLayerName() {
    //
    if (typeof DefaultLayerName.counter == 'undefined') {
        //
        DefaultLayerName.counter = 0;
    }

    DefaultLayerName.counter++;
    //
    return "Layer - " + DefaultLayerName.counter;
}
function DefaultGroupName() {
    //
    if (typeof DefaultGroupName.counter == 'undefined') {
        //
        DefaultGroupName.counter = 0;
    }

    DefaultGroupName.counter++;
    //
    return "Group - " + DefaultGroupName.counter;
}

// BaseEntry
class BaseEntry {
    //
    // Constructor
    constructor(parentDiv, parentGroup, title) {
        //
        console.log('BaseEntry.constructor: parentGroup = ', parentGroup);
        //
        this.map_ = null;
        //
        this.title_ = title;
        //
        this.parentDiv_ = parentDiv;
        this.parentGroup_ = parentGroup;
        //
        this.btnMoveUp_ = null;
        this.btnMoveDown_ = null;
        //
        this.btnDeleteLayer_ = null;
    }

    // Map
    setMap(map) {
        //
        this.map_ = map;
    }
    getMap(map) {
        //
        return this.map_;
    }

    // Get json object
    toJSON() {
        //
        var entry = new Object();
        //
        entry.title = this.title_;
        entry.visible = this.isVisible() ? 1 : 0;
        //
        return entry;
    }

    // Show/hide
    isVisible() {
        //
        const class_name = this.btn_show_hide_.className;
        //
        const str_show = class_name.substring(class_name.length - 5, class_name.length);
        //
        return str_show === '-show';


        if (this.btn_show_hide_.src === imageShow)
            console.log('GroupEntry.isVisible(): this.btn_show_hide_.src == imageShow');

        return this.btn_show_hide_.src == imageShow;
    }
    isHidden() {
        //
        const class_name = this.btn_show_hide_.className;
        //
        const str_show = class_name.substring(class_name.length - 5, class_name.length);
        //
        return str_show === '-hide';
    }
    //
    show_() {
        //
        console.log('BaseEntry.show_()...');
        //
        const class_name = 'layer-mngr-button-show-hide';
        //
        this.btn_show_hide_.className = class_name + ' ' + class_name + '-show';
        //
        this.btn_show_hide_.src = imageShow;
    }
    hide_() {
        //
        console.log('BaseEntry.hide_()...');
        //
        const class_name = 'layer-mngr-button-show-hide';
        //
        this.btn_show_hide_.className = class_name + ' ' + class_name + '-hide';
        //
        this.btn_show_hide_.src = imageHide;
    }

    // Button initialization
    newShowHideButton_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button-show-hide';
        btn.title = 'Toggle layer(s) visibility';
        btn.type = 'image';
        //    btn.src = imageShow;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            if (base_entry.isVisible())
                base_entry.hide_();
            else
                base_entry.show_();
        }
        //
        this.btn_show_hide_ = btn;
        //
        this.show_();
        //
        div.appendChild(btn);
    }
    newEdit_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Edit properties';
        btn.type = 'image';
        btn.src = imageEdit;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            base_entry.onEdit_();
        }
        //
        div.appendChild(btn);

    }
    newMoveUp_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Move up';
        btn.type = 'image';
        btn.src = imageMoveUp;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            base_entry.onMoveUp_();
        }
        //
        div.appendChild(btn);
        //
        this.btnMoveUp_ = btn;
    }
    newMoveDown_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Move down';
        btn.type = 'image';
        btn.src = imageMoveDown;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            base_entry.onMoveDown_();
        }
        //
        div.appendChild(btn);
        //
        this.btnMoveDown_ = btn;
    }
    newSettings_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Settings';
        btn.type = 'image';
        btn.src = imageSettings;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            base_entry.onSettings_();
        }
        //
        div.appendChild(btn);

    }
    newDeleteLayer_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Delete all layers and groups';
        btn.type = 'image';
        btn.src = imageDeleteTrash;
        //
        var base_entry = this;
        //
        btn.onclick = function (e) {
            //
            base_entry.onDeleteLayer_();
        }
        //
        div.appendChild(btn);
        //
        this.btnDeleteLayer_ = btn;
    }

    // Button actions
    onEdit_() {
        //
        console.log('BaseEntry.onEditProperties_()...');
    }
    onDeleteLayer_() {
        //
        console.log('BaseEntry.onDeleteLayer_()...');
    }
    onMoveUp_() {
        //
        console.log('BaseEntry.onMoveUp_()...');
    }
    onMoveDown_() {
        //
        console.log('BaseEntry.onMoveDown_()...');
    }
    onSettings_() {
        //
        console.log('BaseEntry.onSettings_()...');
    }

}

// LayerEntry
class LayerEntry extends BaseEntry {
    //
    //
    // Constructor
    constructor(parentDiv, parentGroup, layer, title) {
        //
        console.log('LayerEntry.constructor: parentGroup = ', parentGroup);
        //
        super(parentDiv, parentGroup, title);
        //
        this.layer_ = layer;
        //
        this.layer_.entry = this;
        //
        this.main_div_ = null;
        //
        this.newMainDiv_(parentDiv, title);
        //
        if (layer) {
            //
            console.log('  this.parentGroup_                 = ', this.parentGroup_);
            console.log('  this.parentGroup_.getLayerGroup() = ', this.parentGroup_.getLayerGroup());
            //
            this.parentGroup_.getLayerGroup().getLayers().insertAt(0, layer);
        }
    }

    // Initialization
    newMainDiv_(parentDiv, title) {
        //
        var layer_div = document.createElement("div");
        //
        layer_div.entry = this;
        //
        layer_div.className = 'layer-mngr-layer-group';

        var main_div = document.createElement("div");
        //
        main_div.className = 'layer-mngr-layer-div';
        //
        this.newPlusMinusButton_(main_div);  // to fill the space
        this.newShowHideButton_(main_div);
        this.newTitleText_(main_div, title);
        this.newButtonBar_(main_div);

        layer_div.appendChild(main_div);
        //
        this.main_div_ = main_div;
        //
        parentDiv.appendChild(layer_div);
    }
    //
    newTitleText_(div, title) {
        //
        var text = document.createElement("div");
        //
        text.className = 'layer-mngr-main-title';
        //
        text.innerHTML = title;
        //
        div.appendChild(text);
    }
    //
    newButtonBar_(div) {
        //
        var bar = document.createElement('div');
        //
        bar.className = 'layer-mngr-button-bar';
        //
        this.newEdit_(bar);
        //
        this.newMoveDown_(bar);
        this.newMoveUp_(bar);
        //
        this.newSettings_(bar);
        //
        this.newDeleteLayer_(bar);
        //
        this.btnDeleteLayer_.title = 'Delete the layer';
        //
        div.appendChild(bar);
    }
    newPlusMinusButton_(div) {
        //
        const class_name = 'layer-mngr-button-plus-minus';

        var btn = document.createElement("div");
        //
        btn.className = class_name;
        //
        btn.innerHTML = "";
        //
        div.appendChild(btn);
    }

    // Layer
    getLayer() {
        //
        return this.layer_;
    }

    // Show/hide
    show_() {
        //
        console.log('LayerEntry.show_()...');
        console.log('  map = ', this.getMap());
        //
        super.show_();
        //
        this.layer_.setVisible(true);
        //
        if (this.getMap())
            this.getMap().updateSize();

        //this.layer_.getSource().changed();
    }
    hide_() {
        //
        console.log('LayerEntry.hide_()...');
        //
        super.hide_();
        //
        this.layer_.setVisible(false);
        //
        if (this.getMap())
            this.getMap().updateSize();

        //this.layer_.getSource().changed();
    }

    // Setup from obj
    setupFromObj(obj) {
        //

    }

    // Get json object
    toJSON() {
        //
        var entry = super.toJSON();
        //
        entry.typeName = this.constructor.name;
        //

        //
        return entry;
    }

}

// GroupEntry
class GroupEntry extends BaseEntry {
    //
    // Constructor
    constructor(parentDiv, parentGroup, title) {
        //
        console.log('GroupEntry.constructor: parentGroup = ', parentGroup);
        //
        super(parentDiv, parentGroup, title);
        //
        this.layerGroup_ = new LayerGroup();
        //
        this.layerGroup_.entry = this;
        //
        //this.map_ = null;
        //
        this.main_div_ = null;
        this.sub_div_ = null;
        //
        this.newMainDiv_(parentDiv, title);

        if (this.parentGroup_) {
            //
            this.parentGroup_.getLayerGroup().getLayers().insertAt(0, this.layerGroup_);
        }
    }

    // Initialization
    newMainDiv_(parentDiv, title) {
        //
        var group_div = document.createElement("div");
        //
        group_div.entry = this;
        //
        if (this.isMainGroup())
            group_div.className = 'layer-mngr-main-group';
        else
            group_div.className = 'layer-mngr-sub-group';

        var main_div = document.createElement("div");
        //
        main_div.className = 'layer-mngr-main-div';
        //
        //main_div.style.display = "block";
        //
        this.newPlusMinusButton_(main_div);
        this.newShowHideButton_(main_div);
        this.newTitleText_(main_div, title);
        this.newButtonBar_(main_div);

        var sub_div = document.createElement("div");
        //
        sub_div.className = 'layer-mngr-sub-div';
        //
        //sub_div.style.display = "block";

        group_div.appendChild(main_div);
        group_div.appendChild(sub_div);
        //
        this.main_div_ = main_div;
        this.sub_div_ = sub_div;
        //
        parentDiv.appendChild(group_div);
    }
    //
    newPlusMinusButton_(div) {
        //
        const class_name = 'layer-mngr-button-plus-minus';

        var btn = document.createElement("div");
        //
        //btn.className = class_name + ' ' + class_name + '-open';
        //
        btn.innerHTML = "<";
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            let btn = e.target;

            console.log('  btn   = ', btn);
            console.log('  value = ', btn.value);
            console.log('  text  = ', btn.textContent);

            if (btn.textContent === "<") {
                //
                mainGroup.collapse_(true);
            }
            else {
                //
                mainGroup.expand_(true);
            }
            //
            return;

            if (btn.textContent === "<") {
                //
                btn.className = class_name + ' ' + class_name + '-close';
                //
                btn.innerHTML = ">";
                //
                mainGroup.onCollapse_();
            }
            else {
                //
                btn.className = class_name + ' ' + class_name + '-open';
                //
                btn.innerHTML = "<";
                //
                mainGroup.onExpand_();
            }
        }
        //
        this.btn_plus_minus_ = btn;
        //
        this.expand_(false);
        //
        div.appendChild(btn);
    }
    //
    newTitleText_(div, title) {
        //
        var text = document.createElement("div");
        //
        text.className = 'layer-mngr-main-title';
        //
        text.innerHTML = title;
        //
        div.appendChild(text);
    }
    newButtonBar_(div) {
        //
        var bar = document.createElement('div');
        //
        bar.className = 'layer-mngr-button-bar';
        //
        //this.newSpacer_(bar);
        this.newInsertLayer_(bar);
        this.newInsertGroup_(bar);
        //
        this.newEdit_(bar);
        //
        this.newMoveDown_(bar);
        this.newMoveUp_(bar);
        //
        this.newSettings_(bar);
        //
        this.newDeleteLayer_(bar);
        //
        this.btnDeleteLayer_.title = 'Delete the group and its contents';
        //
        div.appendChild(bar);
    }
    newInsertLayer_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Insert new layer';
        btn.type = 'image';
        btn.src = imageLayer;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onInsertLayer_();
        }
        //
        div.appendChild(btn);

        // Menu file import/export
        this.menuFileImport_ = this.newMenuFileImport_();
        this.menuFileExport_ = this.newMenuFileExport_();
        //
        this.menuFileImport_.style.display = "none";
        this.menuFileExport_.style.display = "none";
        //
        div.appendChild(this.menuFileImport_);
        div.appendChild(this.menuFileExport_);
    }
    newInsertGroup_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Insert new layer group';
        btn.type = 'image';
        btn.src = imageLayers;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onInsertGroup_();
        }
        //
        div.appendChild(btn);
    }

    // Main group check
    isMainGroup() {
        //
        return !this.parentGroup_;
    }

    // Layer group
    getLayerGroup() {
        //
        return this.layerGroup_;
    }

    // Set map
    setMap(map) {
        //
        super.setMap(map);
        //
        this.layerGroup_.getLayers().forEach(function (layer, idx, array) {
            //
            layer.entry.setMap(map);
        });
    }

    // Show/hide
    show_() {
        //
        console.log('GroupEntry.show_()...');
        //
        super.show_();
        //
        // ...
    }
    hide_() {
        //
        console.log('GroupEntry.hide_()...');
        //
        super.hide_();
        //
        // ...
    }

    // Expand/collapse
    isCollapsed() {
        //
        const class_name = this.btn_plus_minus_.className;
        //
        let str_close = class_name.substring(class_name.length - 6, class_name.length);
        //
        return str_close === '-close';
    }
    isExpanded() {
        //
        const class_name = this.btn_plus_minus_.className;
        //
        const str_open = class_name.substring(class_name.length - 5, class_name.length);
        //
        return str_open === '-open';
    }
    //
    expand_(onclick) {
        //
        console.log('GroupEntry.expand_(' + onclick + ')...');
        //
        const class_name = 'layer-mngr-button-plus-minus';
        //
        let btn = this.btn_plus_minus_;
        //
        btn.className = class_name + ' ' + class_name + '-open';
        //
        btn.innerHTML = "<";
        //
        if (onclick)
            this.sub_div_.style.display = "block";
    }
    collapse_(onclick) {
        //
        console.log('GroupEntry.collapse_(' + onclick + ')...');
        //
        const class_name = 'layer-mngr-button-plus-minus';
        //
        let btn = this.btn_plus_minus_;
        //
        btn.className = class_name + ' ' + class_name + '-close';
        //
        btn.innerHTML = ">";
        //
        if (onclick)
            this.sub_div_.style.display = "none";
    }

    // Setup from obj
    setupFromObj(obj) {
        //
        for (let i = 0; i != obj.arrayEntry.length; i++) {
            //
            let entry = obj.arrayEntry[i];
            //
            if (entry.typeName === 'GroupEntry') {
                //
                let group_entry = new GroupEntry(this.sub_div_, this, entry.title);
                //
                group_entry.setMap(this.map_);
                //
                group_entry.setupFromObj(entry);
                //
                continue;
            }
            //
            if (entry.typeName === 'LayerEntry') {
                //
                let layer_entry = new LayerEntry(this.sub_div_, this, null, entry.title);
                //
                layer_entry.setMap(this.map_);
                //
                layer_entry.setupFromObj(entry);
                //
                continue;
            }
        }
        //
        console.log('setupFromObj: obj.open =', obj.open)

        if (obj.open === 0)
            console.log('setupFromObj: obj.open === 0')

        if (obj.open === 0)
            this.collapse_(true);
    }

    // Get json object
    toJSON() {
        //
        var entry = super.toJSON();
        //
        entry.typeName = this.constructor.name;
        //
        var array_entry = [];
        //
        var array_div = this.sub_div_.childNodes;
        //
        for (let i = 0; i != array_div.length; i++) {
            //
            array_entry.push(array_div[i].entry.toJSON())
        }
        //
        entry.arrayEntry = array_entry;
        //
        entry.open = this.isExpanded() ? 1 : 0;
        //
        return entry;
    }

    // Insert group
    insertGroup(name) {
        //
        console.log('GroupEntry.insertGroup()...');
        //
        let group_entry = new GroupEntry(this.sub_div_, this, name ? name : DefaultGroupName());
        //
        group_entry.setMap(this.map_);
        //
        return group_entry;
    }

    // Insert layer
    insertLayer(layer, name) {
        //
        console.log('GroupEntry.insertLayer()...');
        //
        console.log('  name    = ' + name);
        console.log('  visible = ' + layer.getVisible());

        // Store visibility before creating LayerEntry
        let layer_visible = layer.getVisible();

        // Create LayerEntry
        let layer_entry = new LayerEntry(this.sub_div_, this, layer, name ? name : DefaultLayerName());

        // Update visibility
        if (layer_visible)
            layer_entry.show_();
        else
            layer_entry.hide_();

        // Set map
        layer_entry.setMap(this.map_);

        return layer_entry;
    }

    ___isVisible() {
        //
        const class_name = this.btn_show_hide_.className;
        //
        const str_show = class_name.substring(class_name.length - 5, class_name.length);
        //
        return str_show === '-show';


        if (this.btn_show_hide_.src === imageShow)
            console.log('GroupEntry.isVisible(): this.btn_show_hide_.src == imageShow');

        return this.btn_show_hide_.src == imageShow;
    }
    ___isHidden() {
        //
        const class_name = this.btn_show_hide_.className;
        //
        const str_show = class_name.substring(class_name.length - 5, class_name.length);
        //
        return str_show === '-hide';
    }
    //
    ___show_() {
        //
        console.log('GroupEntry.show_()...');
        //
        const class_name = 'layer-mngr-button-show-hide';
        //
        this.btn_show_hide_.className = class_name + ' ' + class_name + '-show';
        //
        this.btn_show_hide_.src = imageShow;
    }
    ___hide_() {
        //
        console.log('GroupEntry.hide_()...');
        //
        const class_name = 'layer-mngr-button-show-hide';
        //
        this.btn_show_hide_.className = class_name + ' ' + class_name + '-hide';
        //
        this.btn_show_hide_.src = imageHide;
    }

    __newShowHideButton_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button-show-hide';
        btn.title = 'Toggle layer(s) visibility';
        btn.type = 'image';
        //    btn.src = imageShow;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            if (mainGroup.isVisible())
                mainGroup.hide_();
            else
                mainGroup.show_();
        }
        //
        this.btn_show_hide_ = btn;
        //
        this.show_();
        //
        div.appendChild(btn);
    }

    ___newEditProperties_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Edit properties';
        btn.type = 'image';
        btn.src = imageEdit;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onEditProperties_();
        }
        //
        div.appendChild(btn);

    }
    ___newMoveUp_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Move up';
        btn.type = 'image';
        btn.src = imageMoveUp;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onMoveUp_();
        }
        //
        div.appendChild(btn);
    }
    ___newMoveDown_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Move down';
        btn.type = 'image';
        btn.src = imageMoveDown;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onMoveDown_();
        }
        //
        div.appendChild(btn);
    }
    ___newDeleteLayers_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Delete all layers and groups';
        btn.type = 'image';
        btn.src = imageDeleteTrash;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onDeleteLayers_();
        }
        //
        div.appendChild(btn);
    }

    // Button actions
    __onCollapse_() {
        //
        console.log('GroupEntry.onCollapse_()...');
        //
        console.log('this.sub_div_.style.display = ', this.sub_div_.style.display);
        //
        if (!this.sub_div_.style.display)
            console.log('!!!!!this.sub_div_.style.display')
        //
        if (!this.isCollapsed())
            console.log('!!!!!!!!!!!')
        //
        this.sub_div_.style.display = "none";
    }
    __onExpand_() {
        //
        console.log('GroupEntry.onExpand_()...');
        //
        if (!this.isExpanded())
            console.log('!!!!!!!!!!!')
        //
        this.sub_div_.style.display = "block";
    }
    onEdit_() {
        //
        console.log('GroupEntry.onEdit_()...');
        //

    }
    onInsertGroup_() {
        //
        console.log('GroupEntry.onInsertGroup_()...');
        //
        this.insertGroup();

        return;

        let group_entry = new GroupEntry(this.sub_div_, this, DefaultGroupName());
        //
        group_entry.setMap(this.map_);
    }
    onInsertLayer_() {
        //
        console.log('GroupEntry.onInsertLayer_()...');
        //
        this.insertLayer();

        return;

        let layer_entry = new LayerEntry(this.sub_div_, this, DefaultLayerName());
        //
        layer_entry.setMap(this.map_);

        return;

        this.onFileImport();
    }
    onDeleteLayer_() {
        //
        console.log('GroupEntry.onDeleteLayer_()...');

    }
    __onMoveUp_() {
        //
        console.log('GroupEntry.onMoveUp_()...');

    }
    __onMoveDown_() {
        //
        console.log('GroupEntry.onMoveDown_()...');

    }

    // Menu import/export
    newMenuFileImport__(div, pixel) {
        //
        //------------------------------------------------------------
        // Import pixel coordinates
        //------------------------------------------------------------
        var title = document.createElement("text");
        //
        title.className = 'menu-file-title';
        //
        if (pixel)
            title.innerHTML = "Import pixel coordinates";
        else
            title.innerHTML = "Import world coordinates";
        //
        div.appendChild(title);
        //------------------------------------------------------------
        // Import JSON
        //------------------------------------------------------------
        var input_json = document.createElement("input");
        //
        input_json.className = 'menu-file-button';
        //
        input_json.type = "button";
        input_json.value = "Import JSON...";
        //
        if (pixel)
            input_json.addEventListener('click', this.onMenuFileImport_JSON_pix_.bind(this), false);
        else
            input_json.addEventListener('click', this.onMenuFileImport_JSON_world_.bind(this), false);
        //
        div.appendChild(input_json);

        //------------------------------------------------------------
        // Pixel import/export for KML and GPX doesn't make sense...
        //------------------------------------------------------------
        if (pixel)
            return;
        //------------------------------------------------------------

        //------------------------------------------------------------
        // Import KML
        //------------------------------------------------------------
        var input_kml = document.createElement("input");
        //
        input_kml.className = 'menu-file-button';
        //
        input_kml.type = "button";
        input_kml.value = "Import KML...";
        //
        if (pixel)
            input_kml.addEventListener('click', this.onMenuFileImport_KML_pix_.bind(this), false);
        else
            input_kml.addEventListener('click', this.onMenuFileImport_KML_world_.bind(this), false);
        //
        div.appendChild(input_kml);
        //------------------------------------------------------------
        // Import GPX
        //------------------------------------------------------------
        var input_gpx = document.createElement("input");
        //
        input_gpx.className = 'menu-file-button';
        //
        input_gpx.type = "button";
        input_gpx.value = "Import GPX...";
        //
        if (pixel)
            input_gpx.addEventListener('click', this.onMenuFileImport_GPX_pix_.bind(this), false);
        else
            input_gpx.addEventListener('click', this.onMenuFileImport_GPX_world_.bind(this), false);
        //
        div.appendChild(input_gpx);
        //------------------------------------------------------------
    }
    newMenuFileExport__(div, pixel) {
        //
        //------------------------------------------------------------
        // Export pixel coordinates
        //------------------------------------------------------------
        var title = document.createElement("text");
        //
        title.className = 'menu-file-title';
        //
        if (pixel)
            title.innerHTML = "Export pixel coordinates";
        else
            title.innerHTML = "Export world coordinates";
        //
        div.appendChild(title);
        //------------------------------------------------------------
        // Export JSON
        //------------------------------------------------------------
        var input_json = document.createElement("input");
        //
        input_json.className = 'menu-file-button';
        //
        input_json.type = "button";
        input_json.value = "Export JSON...";
        //
        if (pixel)
            input_json.addEventListener('click', this.onMenuFileExport_JSON_pix_.bind(this), false);
        else
            input_json.addEventListener('click', this.onMenuFileExport_JSON_world_.bind(this), false);
        //
        div.appendChild(input_json);

        //------------------------------------------------------------
        // Pixel import/export for KML and GPX doesn't make sense...
        //------------------------------------------------------------
        if (pixel)
            return;
        //------------------------------------------------------------

        //------------------------------------------------------------
        // Import KML
        //------------------------------------------------------------
        var input_kml = document.createElement("input");
        //
        input_kml.className = 'menu-file-button';
        //
        input_kml.type = "button";
        input_kml.value = "Export KML...";
        //
        if (pixel)
            input_kml.addEventListener('click', this.onMenuFileExport_KML_pix_.bind(this), false);
        else
            input_kml.addEventListener('click', this.onMenuFileExport_KML_world_.bind(this), false);
        //
        div.appendChild(input_kml);
        //------------------------------------------------------------
        // Import GPX
        //------------------------------------------------------------
        var input_gpx = document.createElement("input");
        //
        input_gpx.className = 'menu-file-button';
        //
        input_gpx.type = "button";
        input_gpx.value = "Export GPX...";
        //
        if (pixel)
            input_gpx.addEventListener('click', this.onMenuFileExport_GPX_pix_.bind(this), false);
        else
            input_gpx.addEventListener('click', this.onMenuFileExport_GPX_world_.bind(this), false);
        //
        div.appendChild(input_gpx);
        //------------------------------------------------------------
    }

    newMenuFileImport_(pixel) {
        //
        let div = document.createElement("div");
        //
        div.className = 'menu-file menu-file-import';
        //
        this.newMenuFileImport__(div, true);
        this.newMenuFileImport__(div, false);
        //
        return div;
    }
    newMenuFileExport_(pixel) {
        //
        let div = document.createElement("div");
        //
        div.className = 'menu-file menu-file-export';
        //
        this.newMenuFileExport__(div, true);
        this.newMenuFileExport__(div, false);
        //
        return div;
    }

    // Import button
    onFileImport(e) {
        //
        console.log('GroupEntry.onFileImport');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.menuFileExport_.style.display = "none";
        this.menuFileImport_.style.display = "block";

        let menuFileImport = this.menuFileImport_;
        //
        let btnImport = this.btnImport_;
        //
        window.onclick = function (e) {
            //
            //*
            console.log('GroupEntry.onFileImport().window.onclick(): e = ', e);
            console.log('GroupEntry.onFileImport().window.onclick(): e.target = ', e.target);
            /**/
            //
            if (e.target !== btnImport) {
                //
                //e.preventDefault();
                //
                menuFileImport.style.display = "none";
            }
        }

        return;

        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = '.json;.geojson';
        //fileSelector.accept = '.json;.geojson;.kml;.gpx';
        //
        fileSelector.map = this.getMap();
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImport_;
        //
        fileSelector.click();
    };

    // Export button
    onFileExport_(e) {

        console.log('GroupEntry.onFileExport');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        var vector = this.getMap().getForeground();
        //
        var features = vector.getSource().getFeatures();
        //
        if (features.length === 0) {
            //
            alert('Warning: no features found!');
            //
            return;
        }

        this.menuFileImport_.style.display = "none";
        this.menuFileExport_.style.display = "block";

        let menuFileExport = this.menuFileExport_;
        //
        let btnExport = this.btnExport_;
        //
        window.onclick = function (e) {
            //
            //*
            console.log('GroupEntry.onFileExport().window.onclick(): e = ', e);
            console.log('GroupEntry.onFileExport().window.onclick(): e.target = ', e.target);
            /**/
            //
            if (e.target !== btnExport) {
                //
                //e.preventDefault();
                //
                menuFileExport.style.display = "none";
            }
        }

        return;

        var vector = this.getMap().getForeground();
        //
        var features = vector.getSource().getFeatures();

        if (features.length === 0) {
            alert('Warning: no features found!');
            return;
        }

        const version = 1.0;

        var geojson = new GeoJSON();
        //
        var data = geojson.writeFeatures(features);

        const action = 'transformFeatures_i2w';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        socket.socket.on(action, this.onSocketExportFeatures_);
        //
        const message = {
            version: version,
            uuid: socket.uuid,
            data: data,
        };
        //
        socket.socket.emit(action, message);
    };

    // Menu import callbacks
    onMenuFileImport_JSON_pix_() {
        //
        console.log('GroupEntry.onMenuFileImport_JSON_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new GeoJSON();
        //
        this.fileImportPixel_(reader, options, '.json,.geojson');
    }
    onMenuFileImport_JSON_world_() {
        //
        console.log('GroupEntry.onMenuFileImport_JSON_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new GeoJSON();
        //
        this.fileImportWorld_(reader, options, '.json,.geojson');
    }
    onMenuFileImport_KML_pix_() {
        //
        console.log('GroupEntry.onMenuFileImport_KML_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new KML();
        //
        this.fileImportPixel_(reader, options, '.kml');
    }
    onMenuFileImport_KML_world_() {
        //
        console.log('GroupEntry.onMenuFileImport_KML_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new KML();
        //
        this.fileImportWorld_(reader, options, '.kml');
    }
    onMenuFileImport_GPX_pix_() {
        //
        console.log('GroupEntry.onMenuFileImport_GPX_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new GPX();
        //
        this.fileImportPixel_(reader, options, '.gpx');
    }
    onMenuFileImport_GPX_world_() {
        //
        console.log('GroupEntry.onMenuFileImport_GPX_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new GPX();
        //
        this.fileImportWorld_(reader, options, '.gpx');
    }

    // Menu export callbacks
    onMenuFileExport_JSON_pix_() {
        //
        console.log('GroupEntry.onMenuFileExport_JSON_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new GeoJSON();
        //
        this.fileExportPixel_(writer, options, '.json');
    }
    onMenuFileExport_KML_pix_() {
        //
        console.log('GroupEntry.onMenuFileExport_KML_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new KML();
        //
        this.fileExportPixel_(writer, options, '.kml');
    }
    onMenuFileExport_GPX_pix_() {
        //
        console.log('GroupEntry.onMenuFileExport_GPX_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new GPX();
        //
        this.fileExportPixel_(writer, options, '.gpx');
    }
    onMenuFileExport_JSON_world_() {
        //
        console.log('GroupEntry.onMenuFileExport_JSON_world_()...');
        //
        this.fileExportWorld_('json');
    }
    onMenuFileExport_KML_world_() {
        //
        console.log('GroupEntry.onMenuFileExport_KML_world_()...');
        //
        this.fileExportWorld_('kml');
    }
    onMenuFileExport_GPX_world_() {
        //
        console.log('GroupEntry.onMenuFileExport_GPX_world_()...');
        //
        this.fileExportWorld_('gpx');
    }

    // Utilities: get file extension
    static getFileExtension_(filename) {
        //
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    // Pixel import
    fileImportPixel_(reader, options, file_ext) {
        //
        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = file_ext;
        //
        fileSelector.map = this.getMap();
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImportPixel_;
        //
        fileSelector.click();
    }
    handleFileImportPixel_(e) {
        //
        console.log('GroupEntry.handleFileImportPixel_()...');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }
        //
        console.log('  file.name = ' + file.name);

        const file_ext = GroupEntry.getFileExtension_(file.name);
        //
        var geo_reader = null;
        //
        if ((file_ext === 'json') || (file_ext === 'geojson'))
            geo_reader = new GeoJSON();
        else if (file_ext === 'kml')
            geo_reader = new KML();
        else if (file_ext === 'gpx')
            geo_reader = new GPX();
        else {
            //
            alert('Error reading from: ' + file.name + ': unknown file type!');
            //
            return;
        }

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;

        const file_reader = new FileReader();
        //
        file_reader.onload = function (e) {

            var data = file_reader.result;
            //
            var features = null;
            //
            try {
                features = geo_reader.readFeatures(data);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            var foreground = map.getLayers().item(1);
            //
            foreground.getSource().addFeatures(features);
        }

        if (file) {
            file_reader.readAsText(file);
        }
    };

    // World import
    fileImportWorld_(reader, options, file_ext) {
        //
        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = file_ext;
        //
        fileSelector.map = this.getMap();
        //
        fileSelector.reader = reader;
        fileSelector.options = options;
        //
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImportWorld_;
        //
        fileSelector.click();

    }
    handleFileImportWorld_(e) {
        //
        console.log('GroupEntry.handleFileImportWorld_()...');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }
        //
        console.log('  file.name = ' + file.name);

        /*
        const file_ext = GroupEntry.getFileExtension_(file.name);
        //
        var geo_reader = null;
        //
        switch (file_ext) {
            //
            case 'json':
            case 'geojson':
                geo_reader = new GeoJSON();
                break;
            case 'kml':
                geo_reader = new KML();
                break;
            case 'gpx':
                geo_reader = new GPX();
                break;
            default:
                //
                alert('Error reading from: ' + file.name + ': unknown file type!');
                //
                return;
        }
        */

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;
        //
        const control = fileSelector.control;
        //
        const reader = fileSelector.reader;
        const options = fileSelector.options;

        const file_reader = new FileReader();
        //
        file_reader.onload = function (e) {

            var data = file_reader.result;
            //
            var features = null;
            //
            try {
                features = reader.readFeatures(data, options);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            const version = 1.0;

            var geojson = new GeoJSON();
            //
            var data = geojson.writeFeatures(features);

            const action = 'transformFeatures_w2i';
            //
            var socket = map.getSocket(); // SocketConnection
            //
            socket.socket.on(action, control.onSocketFileImportWorld_);
            //
            const message = {
                version: version,
                uuid: socket.uuid,
                epsg: 4326, // default: WGS84
                data: data,
            };
            //
            socket.socket.emit(action, message);
        }

        if (file) {
            file_reader.readAsText(file);
        }
    }
    onSocketFileImportWorld_(message) {
        //
        console.log('GroupEntry.onSocketFileImportWorld_');

        // Remove callback
        this.off('transformFeatures_w2i');

        // Analyse message
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('GroupEntry.onSocketFileImport_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Could not import data: unknown error!';
            //
            console.log(msg);
            //
            alert(msg);
            //
            return;
        }

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        /*
        console.log('GroupEntry.onSocketFileImportWorld_:');
        console.log('      features = ', features);
        */

        // Check if it is a TOPO file 
        Attr_TOPO.setStyle(features);

        /*
        // TEST to check if it is a TOPO file
        if (Attr_TOPO.hasAttr(features)) {
            //
            console.log('GroupEntry.onSocketFileImportWorld_:');
            console.log('  is TOPO file!');
            //
            Attr_TOPO.setStyle(features);
        }
        /**/

        var foreground = this.map.getLayers().item(1);
        //
        foreground.getSource().addFeatures(features);
    }

    // Utilities: get features to export
    getSelect_() {
        //
        var select = null;
        //
        this.getMap().getInteractions().forEach(function (interaction) {

            if (interaction instanceof Select) {
                //
                select = interaction;
            }
        });
        //
        return select;
    }
    getFeaturesToExport_() {
        //
        var select = this.getSelect_();
        //
        if (!select)
            return null;

        const action_lower = 'export';
        const action_upper = 'Export';

        var features = select.getFeatures();
        //
        if (!features) {
            //
            alert('No features to ' + action_lower + ' check!');
            //
            return;
        }
        else if (features.getLength() == 0) {
            //
            var foreground = this.getMap().getForeground();
            //
            var source = foreground.getSource();
            //
            features = source.getFeatures();
            //
            if (features.length == 0) {
                //
                alert('No features to ' + action_lower + ' check!');
                //
                return;
            }

            var msg = '';
            //
            msg += 'No features selected: ' + action_lower + ' all features (';
            msg += features.length;
            msg += ')?';
            //
            if (!window.confirm(msg)) {
                //
                return;
            }
        }
        else {

            if (!window.confirm(action_upper + ' the ' + features.getLength() + ' selected feature(s)?')) {
                return null;
            }

            // Convert to array for GeoJSON.writeFeatures()
            var array = [];
            //
            features.forEach(function (feature) {
                //
                array.push(feature);
            });
            //
            features = array;
        }

        console.log('GroupEntry.getFeatures_: features = ', features);
        //
        return features;
    }

    // Pixel export
    fileExportPixel_(writer, options, file_ext) {
        //
        const features = this.getFeaturesToExport_();
        //
        if (!features)
            return;

        const data = writer.writeFeatures(features, options);
        //
        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data' + file_ext;
        //
        /*
        a.onclick = GroupEntry.handleFileExport_;
        a.onclose = GroupEntry.handleFileExport_;
        a.onchange = GroupEntry.handleFileExport_;
        a.oninput = GroupEntry.handleFileExport_;
        a.onsubmit = GroupEntry.handleFileExport_;
        a.oncancel = GroupEntry.handleFileExport_;
        */

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }

    // World export
    fileExportWorld_(file_type) {
        //
        const features = this.getFeaturesToExport_();
        //
        if (!features)
            return;

        const version = 1.0;

        var geojson = new GeoJSON();
        //
        var data = geojson.writeFeatures(features);

        const action = 'transformFeatures_i2w';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        switch (file_type) {
            //
            case 'json':
                socket.socket.on(action, this.onSocketFileExport_JSON_);
                break;
            case 'kml':
                socket.socket.on(action, this.onSocketFileExport_KML_);
                break;
            case 'gpx':
                socket.socket.on(action, this.onSocketFileExport_GPX_);
                break;
            default:
                alert('Error: ' + file_type + ': unknown file type!');
                return;
        }
        //
        const message = {
            version: version,
            uuid: socket.uuid,
            data: data,
        };
        //
        socket.socket.emit(action, message);
    }
    onSocketFileExport_JSON_(message) {
        //
        console.log('GroupEntry.onSocketFileExport_JSON_');

        // Remove callback
        this.off('transformFeatures_i2w');

        GroupEntry.onSocketFileExport_(message, 'json')
    }
    onSocketFileExport_KML_(message) {
        //
        console.log('GroupEntry.onSocketFileExport_KML_');

        // Remove callback
        this.off('transformFeatures_i2w');

        GroupEntry.onSocketFileExport_(message, 'kml')
    }
    onSocketFileExport_GPX_(message) {
        //
        console.log('GroupEntry.onSocketFileExport_GPX_');

        // Remove callback
        this.off('transformFeatures_i2w');

        GroupEntry.onSocketFileExport_(message, 'gpx')
    }
    static onSocketFileExport_(message, file_type) {
        //
        console.log('GroupEntry.onSocketFileExport_');

        // Analyse message
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('GroupEntry.onSocketExportFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!ok) {
            //
            var msg = 'Warning: some features could not be translated correctly!';
            //
            msg += '\n';
            msg += '\n';
            //
            msg += 'Export anyway?';
            //
            if (!window.confirm(msg))
                return;
        }

        // Register projection
        Projection.register(epsg, prj4);

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        console.log('GroupEntry.onSocketExportFeatures_:');
        console.log('      features = ' + features);

        // Write to file
        GroupEntry.doFileExport_(features, epsg, file_type);
    }
    static doFileExport_(features, epsg, file_type) {
        //
        var writer = null;
        //
        switch (file_type) {
            //
            case 'json':
                writer = new GeoJSON();
                break;
            case 'kml':
                writer = new KML();
                break;
            case 'gpx':
                writer = new GPX();
                break;
            default:
                alert('Error: ' + file_type + ': unknown file type!');
                return;
        }

        // Write data
        var options = {
            //
            //dataProjection: 'EPSG:' + epsg,
            featureProjection: 'EPSG:' + epsg,
        }
        //
        var data = writer.writeFeatures(features, options);

        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data.' + file_type;

        a.click();

        window.URL.revokeObjectURL(url);
    }
}

class MainGroup extends GroupEntry {
    //
    constructor(parent, title) {
        //
        super(parent, undefined, title);

        return;

        this.layerGroup_ = new LayerGroup();
        //
        this.main_div_ = null;
        this.sub_div_ = null;
        //
        this.newMainDiv_(parent, title);
    }

    clearLayers_(layer_group) {
        //
        console.log('MainGroup.clearLayers_()...');
        //
        while (layer_group.getLayers().getLength() > 1) {
            //
            let layer = layer_group.getLayers().pop();
            //
            console.log('  layer = ', layer)
        }
    }

    clearDiv_(div) {
        //
        //e.firstElementChild can be used. 
        var e = div.lastElementChild;
        //
        while (e) {
            div.removeChild(e);
            e = div.lastElementChild;
        }
    }

    reset() {
        //
        // Clear layer group
        this.clearLayers_(this.getLayerGroup());

        // Clear sub div
        this.clearDiv_(this.sub_div_);
    }

    __setupFromObj(obj) {

        // Clear layer group
        this.clearLayers_(this.getLayerGroup());

        // Clear sub div
        this.clearDiv_(this.sub_div_);

        //this.main_div_ = null;
        //this.sub_div_ = null;
        //
        //this.newMainDiv_(parentDiv, obj.title);

        for (let i = 0; i != obj.arrayEntry.length; i++) {
            //
            let entry = obj.arrayEntry[i];
            //
            if (entry.typeName === 'GroupEntry') {
                //
                let group_entry = new GroupEntry(this.sub_div_, this, entry.title);
                //
                group_entry.setMap(this.map_);
                //
                group_entry.setupFromObj(entry);
                //
                continue;
            }
            //
            if (entry.typeName === 'LayerEntry') {
                //
                let layer_entry = new LayerEntry(this.sub_div_, this, entry.title);
                //
                layer_entry.setMap(this.map_);
                //
                layer_entry.setupFromObj(entry);
                //
                continue;
            }
        }
    }

    __newMainDiv_(parent, title) {
        //
        var div = document.createElement("div");
        //
        div.className = 'layer-mngr-main-group';
        //
        this.newPlusMinusButton_(div);
        this.newTitleText_(div, title);
        this.newButtonBar_(div);
        //
        parent.appendChild(div);
        //
        return div;
    }
    __newMainDiv_(parent, title) {
        //
        var group_div = document.createElement("div");
        //
        group_div.className = 'layer-mngr-main-group';

        var main_div = document.createElement("div");
        //
        main_div.className = 'layer-mngr-main-div';
        //
        this.newPlusMinusButton_(main_div);
        this.newTitleText_(main_div, title);
        this.newButtonBar_(main_div);

        var sub_div = document.createElement("div");
        //
        sub_div.className = 'layer-mngr-sub-div';

        group_div.appendChild(main_div);
        group_div.appendChild(sub_div);
        //
        parent.appendChild(group_div);
        //
        this.main_div_ = main_div;
        this.sub_div_ = sub_div;
    }
    __newPlusMinusButton_(div) {
        //
        const class_name = 'layer-mngr-button-plus-minus';

        var btn = document.createElement("div");
        //
        btn.className = class_name + ' ' + class_name + '-open';
        //
        btn.innerHTML = "<";
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            let btn = e.target;

            console.log('  btn   = ', btn);
            console.log('  value = ', btn.value);
            console.log('  text  = ', btn.textContent);

            if (btn.textContent === "<") {
                //
                btn.className = class_name + ' ' + class_name + '-close';
                //
                btn.innerHTML = ">";
                //
                mainGroup.collapse_();
            }
            else {
                //
                btn.className = class_name + ' ' + class_name + '-open';
                //
                btn.innerHTML = "<";
                //
                mainGroup.expand_();
            }
        }
        //
        div.appendChild(btn);

    }
    __newTitleText_(div, title) {
        //
        var text = document.createElement("div");
        //
        text.className = 'layer-mngr-main-title';
        //
        text.innerHTML = title;
        //
        div.appendChild(text);
    }
    __newButtonBar_(div) {
        //
        var bar = document.createElement('div');
        //
        bar.className = 'layer-mngr-button-bar';
        //
        //this.newSpacer_(bar);
        this.newInsertLayer_(bar);
        this.newInsertGroup_(bar);
        //
        this.newDeleteLayers_(bar);
        //
        div.appendChild(bar);
    }
    ___newSpacer_(div) {
        //
        var spacer = document.createElement('div');
        //
        spacer.className = 'layer-mngr-spacer';
        //
        //btn.addEventListener('click', this.onFileImport.bind(this), false);
        //
        //this.btnImport_ = btn_import;
        //
        div.appendChild(spacer);
    }
    __newInsertLayer_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Insert new layer';
        btn.type = 'image';
        btn.src = imageLayer;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onInsertLayer_();
        }
        //
        div.appendChild(btn);
    }
    __newInsertGroup_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Insert new layer group';
        btn.type = 'image';
        btn.src = imageLayers;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onInsertGroup_();
        }
        //
        div.appendChild(btn);
    }
    __newDeleteLayers_(div) {
        //
        var btn = document.createElement('input');
        //
        btn.className = 'layer-mngr-button';
        btn.title = 'Delete all layers and groups';
        btn.type = 'image';
        btn.src = imageDeleteTrash;
        //
        var mainGroup = this;
        //
        btn.onclick = function (e) {
            //
            mainGroup.onDeleteLayers_();
        }
        //
        div.appendChild(btn);

    }

    __collapse_() {
        //
        console.log('mainGroup.collapse_()...');

    }
    __expand_() {
        //
        console.log('mainGroup.expand_()...');

    }

    __onInsertGroup_() {
        //
        console.log('mainGroup.onInsertGroup_()...');
        //
        new GroupEntry(this.sub_div_, "AAAAAA", this.layerGroup_);

    }
    __onInsertLayer_() {
        //
        console.log('mainGroup.onInsertLayer_()...');
    }
    __onDeleteLayers_() {
        //
        console.log('mainGroup.onDeleteLayers_()...');

    }

}


//export default class LayerMngr {
class ___LayerMngr {

    constructor(element) {

        this.mainLayerGroup_ = null;
        //
        this.map_ = null;
        //
        this.layerMngr_ = this.newLayerMngr_();
        //
        element.appendChild(this.layerMngr_);
    }

    newTitleBar_(main_div) {

        // Title bar
        var div_title = document.createElement("div");
        //
        div_title.className = 'layer-mngr-title-bar';

        // Title
        var text = document.createElement("div");
        //
        text.className = 'layer-mngr-title-bar-title';
        //
        text.innerHTML = 'Map layers';
        //
        div_title.appendChild(text);

        // Buttons
        let layerMngr = this;
        //
        var btn;

        // Test buttons
        //btn = document.createElement('button');


        btn = document.createElement('div');
        //
        btn.className = 'layer-mngr-title-bar-button';
        btn.title = 'Apply';
        btn.innerHTML = 'A';
        //
        btn.onclick = function (e) {
            //
            layerMngr.onTestApply_();
        }
        //
        div_title.appendChild(btn);

        btn = document.createElement('div');
        //
        btn.className = 'layer-mngr-title-bar-button';
        btn.title = 'Open';
        btn.innerHTML = 'O';
        //btn.type = 'image';
        //btn.src = imageEdit;
        //
        btn.onclick = function (e) {
            //
            layerMngr.onTestOpen_();
        }
        //
        div_title.appendChild(btn);

        btn = document.createElement('div');
        //
        btn.className = 'layer-mngr-title-bar-button';
        btn.title = 'Save';
        btn.innerHTML = 'S';
        //btn.type = 'image';
        //btn.src = imageEdit;
        //
        btn.onclick = function (e) {
            //
            layerMngr.onTestSave_();
        }
        //
        div_title.appendChild(btn);

        // Close button
        var span = document.createElement("span");
        //
        span.className = "layer-mngr-title-bar-close";
        span.innerHTML = "&times;";
        //
        span.onclick = function (e) {
            //
            /*
            console.log('span.onclick(): e = ', e);
            console.log('  this = ', this);
            console.log('  this.popup = ', this.popup);
            /**/
            layerMngr.hide();
        }
        //
        div_title.appendChild(span);

        main_div.appendChild(div_title);
    }
    newLayerMngr_() {
        //
        let main_div = document.createElement("div");
        //
        main_div.className = 'layer-mngr';

        // Title bar
        this.newTitleBar_(main_div);

        // Image layer
        this.backgroundLayer_ = new TileLayer();

        // Background/foreground layers
        this.mainGroupFgLayers_ = new MainGroup(main_div, "Foreground layers");
        this.mainGroupBgLayers_ = new MainGroup(main_div, "Background layers");

        // Main layer group
        this.mainLayerGroup_ = new LayerGroup();
        //
        this.mainLayerGroup_.getLayers().array_.push(this.backgroundLayer_);
        this.mainLayerGroup_.getLayers().array_.push(this.mainGroupBgLayers_.getLayerGroup());
        this.mainLayerGroup_.getLayers().array_.push(this.mainGroupFgLayers_.getLayerGroup());
        //
        //this.mainLayerGroup_.setLayers([this.mainGroupBgLayers_, this.mainGroupFgLayers_]);

        /*
        div.appendChild(this.mainGroupImage_);
        div.appendChild(this.mainGroupBgLayers_);
        div.appendChild(this.mainGroupFgLayers_);
        */

        //
        main_div.style.display = "none";
        //
        return main_div;
    }

    setup_() {
        //

    }

    setupFromObj_(obj) {
        //

        // Background/foreground layers
        let bgLayers = obj.bgLayers;
        //
        this.mainGroupFgLayers_.reset();
        this.mainGroupBgLayers_.reset();
        //
        this.mainGroupFgLayers_.setupFromObj(obj.fgLayers);
        this.mainGroupBgLayers_.setupFromObj(obj.bgLayers);



    }

    ___insertLayer_(div) {
        //

    }

    setMap(map) {
        //
        this.map_ = map;
        //
        this.mainGroupFgLayers_.setMap(map);
        this.mainGroupBgLayers_.setMap(map);
    }
    getMap(map) {
        //
        return this.map_;
    }

    ensureVisible_() {
        //
        console.log('LayerMngr.ensureVisible_()...');

        const rect_map = this.getMap().getTargetElement().getBoundingClientRect();
        //
        console.log('  rect_map ', rect_map);

        const rect_parent = this.layerMngr_.parentElement.getBoundingClientRect();
        //
        console.log('  rect_parent', rect_parent);

        const rect_this = this.layerMngr_.getBoundingClientRect();
        //
        console.log('  rect_this', rect_this);
        //
        console.log(' this.layerMngr_.style.left = ', this.layerMngr_.style.left);

        // Check x coordinate
        let new_x = 0;
        //
        console.log(' new_x = ' + new_x);
        //
        if ((rect_parent.x + rect_this.width) > rect_map.width)
            new_x = - (rect_parent.x + rect_this.width - rect_map.width);
        //
        console.log(' new_x = ' + new_x);

        if (rect_parent.x + new_x < 0)
            new_x = -(rect_parent.x);
        //
        console.log(' new_x = ' + new_x);

        this.layerMngr_.style.left = new_x + 'px';

        console.log('  this.layerMngr_.style.left = ' + this.layerMngr_.style.left);
    }

    isVisible() {
        //
        console.log('LayerMngr.isVisible()...');
        console.log('  style = ', this.layerMngr_.style.display);
        //
        return (this.layerMngr_.style.display === "block");
    }
    //
    show() {
        //
        console.log('LayerMngr.show()...');
        //
        this.layerMngr_.style.display = "block";
        //
        this.ensureVisible_();
    }
    hide() {
        //
        console.log('LayerMngr.hide()...');
        //
        this.layerMngr_.style.display = "none";
    }

    // Main layer groups
    mainGroupFgLayers() {
        //
        return this.mainGroupFgLayers_;
    }
    mainGroupBgLayers() {
        //
        return this.mainGroupBgLayers_;
    }

    // Open/save
    apply() {
        //
        this.map_.setLayerGroup(this.mainLayerGroup_);
    }

    open() {
        //
        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = '.m_layers';
        //
        fileSelector.layerMngr = this;
        //
        fileSelector.onchange = this.handleFileOpen_;
        //
        fileSelector.click();
    }
    save() {
        //
        var layer_mngr_json = new Object();
        //
        layer_mngr_json.fgLayers = this.mainGroupFgLayers_.toJSON();
        layer_mngr_json.bgLayers = this.mainGroupBgLayers_.toJSON();
        //

        console.log('layer_mngr_json = ', layer_mngr_json);

        const data = JSON.stringify(layer_mngr_json);
        //
        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'LayerMngr.m_layers';
        //
        /*
        a.onclick = FileControl.handleFileExport_;
        a.onclose = FileControl.handleFileExport_;
        a.onchange = FileControl.handleFileExport_;
        a.oninput = FileControl.handleFileExport_;
        a.onsubmit = FileControl.handleFileExport_;
        a.oncancel = FileControl.handleFileExport_;
        */

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }

    handleFileOpen_(e) {
        //
        console.log('LayerMngr.handleFileOpen_()...');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }
        //
        console.log('  file.name = ' + file.name);

        const fileSelector = e.srcElement;
        //
        const layer_mngr = fileSelector.layerMngr;

        const file_reader = new FileReader();
        //
        file_reader.onload = function (e) {

            var data = file_reader.result;
            //
            var layer_mngr_json = null;
            //
            try {
                layer_mngr_json = JSON.parse(data);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            console.log('layer_mngr_json = ', layer_mngr_json);

            var layer_mngr = fileSelector.layerMngr;

            try {
                //
                layer_mngr.setupFromObj_(layer_mngr_json);
            }
            catch (ex) {
                //
                alert('Error parsing from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            console.log('layer_mngr = ', layer_mngr);

        }

        if (file) {
            file_reader.readAsText(file);
        }
    };

    // Test
    onTestApply_() {
        //
        console.log('LayerMngr.onTestApply_()...');
        //
        this.apply();
    }
    onTestOpen_() {
        //
        console.log('LayerMngr.onTestOpen_()...');
        //
        this.open();
    }
    onTestSave_() {
        //
        console.log('LayerMngr.onTestSave_()...');
        //
        this.save();

        return;

        var layer_mngr = new Object();
        //
        layer_mngr.bgLayers = this.mainGroupBgLayers_.toJSON();
        layer_mngr.fgLayers = this.mainGroupFgLayers_.toJSON();
        //

        console.log('layer_mngr = ', layer_mngr);


    }
}

export default class LayerMngr {

    constructor() {

        this.map_ = null;
        //
        this.array_layers = null;
        this.array_visible = null;
        //
        this.setup_pending_ = false;

        /*
        let activationMode='click';
        let activationMode='mouseover';
        //
        let groupSelectStyle='children';
        let groupSelectStyle='group';
        let groupSelectStyle='none';
        */
        let activationMode = 'mouseover';
        let groupSelectStyle = 'group';

        this.layerSwitcher_ = new LayerSwitcher({
            activationMode: activationMode,
            startActive: false,
            groupSelectStyle: groupSelectStyle
        });

        console.log('AAAA LayerSwitcher = ', this.layerSwitcher_);

        let button = this.layerSwitcher_.element.firstChild;
        //
        if (false)
        if (button) {
            //
            // <button title="Legend" aria-label="Legend"></button>

            console.log('  AAAA button   = ', button);

            button.className = 'layer-switcher-button';
            button.title = 'Import data';
            button.type = 'image';
            button.src = imageLayers;

            console.log('  BBBB button    = ', button);
            console.log('  BBBB className = ', button.className);

            if (false) {

                let new_button = '';
                //
                new_button += '<button';
                new_button += ' class="layer-switcher button"';
                new_button += ' title="Layer manager"';
                new_button += ' type="image';
                new_button += '</button';
                //
                button.outerHTML = new_button;

                /*
                new_button+='<input';
                new_button+='</input';
                new_button+='<button';
                new_button+='</button';
    
                new_button+=' type="image';
                src="/static/media/Image-Open.43e4c0b7.svg"
                'icons/Layers.svg'
                */

                /*
                <input class="session-button-open session-button"
                 title="Open picture" type="image"
                  src="/static/media/Image-Open.43e4c0b7.svg">
    
                </input>
                */
                //element.outerHTML = element.outerHTML.replace(/wns/g,"lmn");

                console.log('  AAAA button   = ', button);
                console.log('  BBB className = ', button.className);
            }

        }

        return;

        //let button = this.layerSwitcher_.target_.getElementsByTagName('button');
        let buttons = this.layerSwitcher_.element.getElementsByTagName('button');
        //
        if (buttons && (buttons.length > 0)) {
            //
            let button = buttons[0];

            console.log('  AAAA button   = ', button);
            console.log('  BBB className = ', button.className);

        }


    }

    setMap(map) {
        //
        this.map_ = map;
        //
        this.layerSwitcher_.setMap(map);
    }
    getMap(map) {
        //
        return this.map_;
    }

    layers2url() {
        //
        let first_time = true;

        let str_layers = '';
        let str_visibility = '';
        //
        Utilities.forEachLayer(this.map_, function (layer) {
            //
            if (!layer.origin || (layer.origin != "system"))
                return;

            let title = layer.get('title');
            //
            if (!title || (title.length === 0))
                return;

            let visible = layer.getVisible();

            let str_layer = title;
            //
            if (!first_time)
                str_layer += ',';
            //
            str_layers = str_layer + str_layers;

            let str_visibile = visible;
            //
            if (!first_time)
                str_visibile += ',';
            //
            str_visibility = str_visibile + str_visibility;

            first_time = false;
        });

        /*
        console.log('LayerMngr.layers2url()...')
        console.log('  str_layers     = ', str_layers)
        console.log('  str_visibility = ', str_visibility)
        /**/

        let str = '';
        //
        str += '&layers=' + str_layers;
        str += '&layers_visibility=' + str_visibility;
        //
        return str;
    }
    url2layers(url) {
        //
        let str_layers = url.searchParams.get('layers');
        //
        if (str_layers === null)
            return;

        let str_visibility = url.searchParams.get('layers_visibility');
        //
        if (str_visibility === null)
            return;

        /*
        console.log('LayerMngr.url2layers()...');
        console.log('  str_layers     = ', str_layers);
        console.log('  str_visibility = ', str_visibility);
        /**/

        let array_layers = str_layers.split(',');
        let array_visible = str_visibility.split(',');

        if (array_layers.length !== array_visible.length) {
            //
            console.error('LayerMngr.url2layers(): url layer data inconsistent!');
            //
            return;
        }

        if (this.setup_pending_) {
            //
            //console.log('  setup_pending_ = ', this.setup_pending_);
            //
            this.array_layers_ = array_layers;
            this.array_visible_ = array_visible;
            //
            return;
        }

        Utilities.forEachLayer(this.map_, function (layer) {
            //
            //console.log('  layer = ', layer);

            if (!layer.origin || (layer.origin != "system"))
                return;

            let title = layer.get('title');
            //
            if (!title || (title.length === 0))
                return;

            //console.log('  layer = ', title);

            let idx = array_layers.findIndex(name => name === title);
            //
            if (idx < 0)
                return;

            layer.setVisible(array_visible[idx] === 'true');
        });

    }

    // show/hide seems not running correctly
    ___show() {
        //
        this.layerSwitcher_.showPanel();
    }
    ___hide() {
        //
        console.log('LayerMngr.hide()...')
        //
        this.layerSwitcher_.hidePanel();
    }

    static str_indent(indent) {
        //
        let str = "";
        //
        for (let i = 0; i != indent; i++) {
            //
            str += " ";
        }
        //
        return str;
    }
    //
    setupLayerArray_(parent, array, path, indent) {
        //
        let layer_mngr = this;
        //
        array.forEach(function (entry) {
            //
            if (entry.type === "group") {
                //
                let group = new LayerGroup({
                    title: entry.name,
                    fold: 'open',
                    layers: []
                });
                //
                parent.getLayers().insertAt(0, group);
                //
                /*
                let path_group = path + '/' + entry.path;
                //
                console.log(LayerMngr.str_indent(indent) + 'entry.type = ', entry.type);
                console.log(LayerMngr.str_indent(indent) + 'entry.name = ', entry.name);
                console.log(LayerMngr.str_indent(indent) + 'entry.path = ', entry.path);
                console.log(LayerMngr.str_indent(indent) + 'path_group = ', path_group);
                /**/
                //
                layer_mngr.setupLayerArray_(group, entry.layers, path + '/' + entry.path, indent + 2);
                //
                return;
            }

            if (entry.type === "layer") {
                //
                layer_mngr.setupLayer_(parent, entry, path, indent + 2);
                //
                return;
            }
        });
    }
    setupLayer_(parent, entry, path, indent) {
        //
        let layer_mngr = this;
        //
        let path_layer = path + '/' + entry.path;
        //
        let visibility = entry.visibility;
        //
        let coordinates = null;
        //
        switch (entry.coordinates) {
            //
            case "pixel":
                coordinates = "PIXELS";
                break;
        }
        //
        if (!coordinates) {
            //
            console.log("LayerMngr.setupLayer_(...): illegal coordinates for layer:" + entry.name + "!");
            //
            return;
        }

        /*
        console.log(LayerMngr.str_indent(indent) + 'entry.type        = ', entry.type);
        console.log(LayerMngr.str_indent(indent) + 'entry.name        = ', entry.name);
        console.log(LayerMngr.str_indent(indent) + 'entry.path        = ', entry.path);
        console.log(LayerMngr.str_indent(indent) + 'entry.modify      = ', entry.modify);
        console.log(LayerMngr.str_indent(indent) + 'entry.visible     = ', entry.visible);
        console.log(LayerMngr.str_indent(indent) + 'entry.visibility  = ', entry.visibility);
        console.log(LayerMngr.str_indent(indent) + 'entry.coordinates = ', entry.coordinates);
        console.log(LayerMngr.str_indent(indent) + 'path_layer = ', path_layer);
        /**/

        function onchange_(evt) {
            //
            var src = evt.target;
            //
            if (src.getState() === 'ready') {
                //
                // Remove callback
                source.un('change', onchange_);

                // Get features
                let features = src.getFeatures();

                // Check if it is a TOPO file 
                Attr_TOPO.setStyle(features, visibility);
            }
        }
        //
        var source = new VectorSource({
            url: path_layer,
            format: new GeoJSON({
                dataProjection: coordinates,
                featureProjection: coordinates,
            }),
        });
        //
        source.on('change', onchange_);

        let visible = true;
        //
        if (entry.visible != null)
            visible = entry.visible;

        // Check if visibility was set by url
        if (this.array_layers_ && (this.array_layers_.length !== 0)) {
            //
            let idx = this.array_layers_.findIndex(name => name === entry.name);
            //
            if (idx >= 0) {
                //
                visible = (this.array_visible_[idx] === 'true');
            }
        }

        //console.log(LayerMngr.str_indent(indent) + 'visible = ', visible);

        var layer = new VectorLayer({
            title: entry.name,
            visible: visible,
            declutter: true,
            source: source,
        });
        //
        layer.origin = "system";
        //
        if (entry.modify === true)
            layer.read_only = false;
        else
            layer.read_only = true;
        //
        let map = this.map_;
        //
        layer.on('change:visible', function (e) {
            //
            let layer = e.target;
            //
            //console.log('change:visible: layer = ', layer);
            //
            map.updateURL();
        });
        //
        parent.getLayers().insertAt(0, layer);
    }
    //
    setupLayers_(array, path) {
        //
        console.log('LayerMngr.setupLayers_()...')
        //
        /*
        console.log('  path       = ', path);
        console.log('  array      = ', array);
        /**/

        let group = this.map_.group_foreground_;
        //
        this.setupLayerArray_(group, array, path, 4);

        /*
        LayerSwitcher.forEachRecursive(this.map_.getLayerGroup(), function (layer, index) {
            //
            console.log('LLL layer = ', layer);
        });
        /**/
    }
    //
    setupLayers(uuid) {
        //
        console.log('LayerMngr.setupLayers()...')
        //
        console.log('  uuid     = ', uuid);

        this.setup_pending_ = true;

        // Hide panel: not running!!!
        //this.hide();

        let dir_data = '/app/data/' + uuid + '/data';
        //
        let file_data = 'layers.json';
        //
        let path_data = dir_data + '/' + file_data;
        //
        /*
        console.log('  DATA...');
        console.log('    dir_data  = ', dir_data);
        console.log('    file_data = ', file_data);
        console.log('    path_data = ', path_data);
        */

        let layer_mngr = this;

        async function loadLayers_() {
            //
            try {
                // Load file layers.json
                const response = await fetch(path_data);
                //
                const text = await response.text();
                //
                const data = JSON.parse(text);
                //
                /*
                console.log('loadLayers_()...');
                console.log('  data = ', data);
                /**/

                // Get path and layers
                let path = data.path;
                let layers = data.layers;

                // Setup layers
                layer_mngr.setupLayers_(layers, dir_data + "/" + path);

            } catch (err) {
                //
                console.error('LayerMngr.loadLayers_(): exception...');
                console.error('  error is: ', err);
            }

            layer_mngr.map_.updateURL();

            layer_mngr.array_layers_ = null;
            layer_mngr.array_visible_ = null;
            //
            layer_mngr.setup_pending_ = false;

            //console.log('LayerMngr.loadLayers_(): END.')
        }
        //
        loadLayers_();

        return;

        (async function () {
            //
            await loadLayers_();
        })();

        //loadLayers_();

        console.log('LayerMngr.setupLayers(): END.')

        return;

        if (false) {

            // Setup tmp layer to retrieve layers infos from local file
            var layer_tmp = new VectorLayer({
                title: "tmp",
                visible: true,
                source: new VectorSource({
                }),
            });

            function insert_tmp_layer() {
                //
                console.log('LayerMngr.insert_tmp_layer()...')
                //
                layer_mngr.map_.addLayer(layer_tmp);

                return;

                try {
                    //
                    layer_mngr.map_.addLayer(layer_tmp);

                }
                catch (err) {
                    //
                    console.log('LayerMngr.insert_tmp_layer()...');
                    console.log('  error is: ', err);
                }
                console.log('LayerMngr.insert_tmp_layer(): END')

                return;

                layer_mngr.map_.addLayer(layer_tmp);
                //layer_mngr.map_.getLayers().insertAt(0, layer_tmp);
            }
            function remove_tmp_layer() {
                //
                console.log('LayerMngr.remove_tmp_layer()...')
                //
                let layer = layer_mngr.map_.removeLayer(layer_tmp);
                //
                console.log('  removed layer: ', layer)

                //layer_mngr.map_.getLayers().remove(layer_tmp)
            }
            //
            function onchange(evt) {
                //
                console.log('LayerMngr.onchange()...');

                var src = evt.target;
                //
                if (src.getState() === 'ready') {
                    //
                    // Remove callback
                    source.un('change', onchange);

                    // Remove tmp layer
                    remove_tmp_layer();

                    // Get features: first feature contains layers definitions
                    try {
                        //
                        let features = src.getFeatures();
                        //
                        console.log('LayerMngr.onchange(): features = ', features);

                        // Get path and layers
                        let path = features[0].get('path');
                        let layers = features[0].get('layers');

                        // Setup layers
                        layer_mngr.setupLayers_(layers, dir_data + "/" + path);

                    }
                    catch (err) {
                        //
                        console.log('LayerMngr.onchange(): exception...');
                        console.log('  error is: ', err);
                    }

                    return;

                    // Get features: first feature contains layers definitions
                    let features = src.getFeatures();

                    // Get path and layers
                    let path = features[0].get('path');
                    let layers = features[0].get('layers');

                    // Setup layers
                    layer_mngr.setupLayers_(layers, dir_data + "/" + path);

                    return;
                }
            }
            function onerror(evt) {
                //
                console.log('LayerMngr.onerror()...');

            }
            //
            var source = new VectorSource({
                url: path_data,
                format: new GeoJSON({
                }),
            });
            //
            source.on('change', onchange);
            //source.on('error', onerror);
            //
            //layer_tmp.on('error', onerror);
            //
            layer_tmp.setSource(source);

            // Insert tmp layer to get source updated and onchange to be called
            insert_tmp_layer();
        }
    }
}