//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
// timer
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
function timeManager(delta) {

    this.time = Date.now();
    //
    this.delta = delta;
    //
    this.ok = function () {

        var now = Date.now();
        //
        if (now - this.time < delta)
            return false;

        this.time = now;
        //
        return true;
    }
};
//---------------------------------------------------------------------------
export { timeManager };
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
