import Geolocation from 'ol/Geolocation';

import TileLayer from 'ol/layer/Tile';

import TileJSON from 'ol/source/TileJSON';
import XYZ from 'ol/source/XYZ';

import TileGrid from 'ol/tilegrid/TileGrid';

import io from "socket.io-client";

import Projection from "./Projection";

import { coo2str } from '../../js/js.str/j_coo';
import { strSpace } from '../../js/js.str/j_str';
import { timeManager } from '../../js/js.time/j_time';
//import { truncateSync } from 'fs';
//import { View } from 'ol';

export default class SocketConnection {

    constructor(map, obj, fit_view) {

        this.map = map;

        this.socket_ = null;
        this.mouseTimer_ = null;

        this.uuid_ = null;

        this.imageName_ = null;

        this.geolocation_ = null;

        console.log('SocketConnection.constructor: map = ' + map);

        if (obj)
            this.init_(obj, fit_view);
        else
            this.init_startup_();
    }

    ok() {

        return ((this.socket_ !== null) && this.socket_.connected);
    }

    uuid() {
        //
        return this.uuid_;
    }

    getSocket() {
        //
        return this.socket_;
    }

    init_(obj, fit_view) {

        var map = this.map;

        console.log('SocketConnection.init_: map = ', map);
        console.log('SocketConnection.init_: obj = ', obj);

        const version = obj.version;
        //
        switch (version) {

            case 0.1:
                if (!this.init_version_0_1_(obj, fit_view))
                    return false;
                break;

            default:
                alert('Unsupported file version ' + version);
                //
                return false;
        }

        return true;
    }

    // Initialization without image to get info from server at startup
    init_startup_() {
        //
        console.log('SocketConnection.init_startup_()...');
        //
        const server = require('../../etc/server');
        //
        var host = server.serverHost();
        var port = server.serverPort();
        /*
        var host = server.host;
        var port = server.port;
        */
        //
        try {

            console.log('SocketConnection.init_startup_: ' + host + ':' + port);
            //
            this.socket_ = io.connect(host + ':' + port,
                {
                    secure: true,
                    reconnection: true,
                    rejectUnauthorized: false
                }
            );

            //const socket = io.connect("https://my.website.com:3002", { secure: true, reconnection: true, rejectUnauthorized: false });
        }
        catch (ex) {

            alert('ex when trying to create socket to ' + host + ':' + port + ': ' + ex);
            //
            return false;
        }

        var map = this.map;

        this.socket_.map = map;
        this.socket_.socket = this;

        this.socket_.on("connect_error", (err) => {
            //
            console.log(`SocketConnection: connect_error due to ${err.message}`);
            console.log('  err = ', err);
        });

        this.socket_.on('connect', this.onSocketConnectStartup_);
        this.socket_.on('disconnect', this.onSocketDisconnectStartup_);
        //
        this.socket_.on('initializeStartup', this.onSocketInitializeStartup_);

        this.map.setSocket(this);
        //
        return true;
    }

    // Socket events
    onSocketConnectStartup_() {
        //
        console.log('SocketConnection.onSocketConnectStartup_()...');
        //
        const socket = this.socket_; // SocketConnection
        //
        var message = {
            version: 1.0,
        };
        //
        console.log('SocketConnection.onSocketConnectStartup_(): message = ', message);

        this.emit('initializeStartup', message);
    }
    onSocketDisconnectStartup_() {
        //
        console.log('SocketConnection.onSocketDisconnectStartup_()...');
        //
        // Do nothing!
    }

    onSocketInitializeStartup_(message) {
        //
        console.log('SocketConnection.onSocketInitializeStartup_: message = ', message);
        //
        const version = message.version;
        //
        if (!message.ok) {
            //
            var msg = 'Server connection: ';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'unknown error!';
            //
            console.log(msg);
            //
            return;
        }

        const pictures = message.data.pictures;

        //*
        console.log('SocketConnection.onSocketInitializeStartup_:');
        console.log('      version  = ', version);
        console.log('      pictures = ', pictures);
        /**/

        // Set available pictures
        this.map.setAvailablePictures(pictures);

        // Remove callback
        this.off('initializeStartup', this.onSocketInitializeStartup_);
    }

    // Loading image of version 0.1 (from *.mpt file or local storage)
    setupBackground_0_1_MPT_(obj, fit_view) {

        var map = this.map;

        // Setup background
        var background = map.getBackground();
        //
        if (background == null) {
            //
            background = new TileLayer();
            //
            map.addLayer(background);
        }

        const uuid = obj.uuid;
        //
        const type = obj.image.type;
        //
        console.log('setupBackground_0_1_: uuid        = ' + uuid);
        console.log('setupBackground_0_1_: type        = ' + type);

        const title = obj.image.title;

        const width = obj.image.width;
        const height = obj.image.height;
        //
        const extent = [0.00000000, -height, width, 0.00000000];

        var source = null;
        //
        if (type === "TileJSON") {
            //
            const url = obj.image.source.url;
            const tileSize = obj.image.source.tileSize;
            const projection = obj.image.source.projection;
            const crossOrigin = obj.image.source.crossOrigin;
            //
            console.log('setupBackground_0_1_: url         = ' + url);
            console.log('setupBackground_0_1_: tileSize    = ' + tileSize);
            console.log('setupBackground_0_1_: projection  = ' + projection);
            console.log('setupBackground_0_1_: crossOrigin = ' + crossOrigin);
            //
            console.log('setupBackground_0_1_: projection  = ', projection);
            //
            source = new TileJSON({
                url: url,
                tileSize: tileSize,
                projection: projection,
                crossOrigin: crossOrigin,
            });
        }
        else if (type === "XYZ") {
            //
            const url = obj.image.source.url;
            const projection = obj.image.source.projection;
            const crossOrigin = obj.image.source.crossOrigin;
            //
            const minZoom = obj.image.source.minZoom;
            const maxZoom = obj.image.source.maxZoom;
            const maxResolution = obj.image.source.maxResolution;
            //
            console.log('setupBackground_0_1_: url           = ' + url);
            console.log('setupBackground_0_1_: projection    = ' + projection);
            console.log('setupBackground_0_1_: crossOrigin   = ' + crossOrigin);
            console.log('setupBackground_0_1_: minZoom       = ' + minZoom);
            console.log('setupBackground_0_1_: maxZoom       = ' + maxZoom);
            console.log('setupBackground_0_1_: maxResolution = ' + maxResolution);

            var resolutions = [];
            //
            for (var z = 0; z <= maxZoom; z++) {
                //
                resolutions.push(Math.pow(2, maxZoom - z) * maxResolution);
            }

            const tileGrid = new TileGrid({
                extent: extent,
                minZoom: minZoom,
                resolutions: resolutions,
            });

            source = new XYZ({
                url: url,
                tileGrid: tileGrid,
                projection: projection,
                crossOrigin: crossOrigin,
            });
        }
        else {

            alert('Unsupported file type ' + type);
            //
            return false;
        }

        background.session_data = obj;
        //
        background.set('title', title);
        //
        background.setSource(source);
        background.setExtent(extent);

        if (fit_view === true) {
            //
            map.getView().fit(extent, map.getSize());

        }
        //map.getView().fit(extent, map.getSize());
        //
        return true;
    }
    setupBackground_0_1_(obj, fit_view) {
        //
        console.log('SocketConnection.setupBackground_0_1_()...');

        var map = this.map;

        // Hide feature info Popup
        map.hideFeatureInfo();

        // Clear foreground
        this.map.resetLayers();
        /*
        var foreground = this.map.getLayers().item(1);
        //
        foreground.getSource().clear();
        */

        if (obj.type == 'mpt') {
            //
            return this.setupBackground_0_1_MPT_(obj, fit_view);
        }

        alert('Unsupported file type ' + obj.type);
        //
        return false;

        if (false)
            if (true) {
                var map = this.map;

                // Clear foreground
                var foreground = this.map.getLayers().item(1);
                //
                foreground.getSource().clear();

                // Setup background
                var background = map.getLayers().item(0);
                //
                if (background == null) {
                    //
                    background = new TileLayer();
                    //
                    map.addLayer(background);
                }

                const type = obj.image.type;

                const url = obj.image.source.url;
                const tileSize = obj.image.source.tileSize;
                const projection = obj.image.source.projection;
                const crossOrigin = obj.image.source.crossOrigin;

                console.log('setupBackground_0_1_: type        = ' + type);
                console.log('setupBackground_0_1_: url         = ' + url);
                console.log('setupBackground_0_1_: tileSize    = ' + tileSize);
                console.log('setupBackground_0_1_: projection  = ' + projection);
                console.log('setupBackground_0_1_: crossOrigin = ' + crossOrigin);

                var source = null;
                //
                if (type === "TileJSON") {

                    source = new TileJSON({
                        url: url,
                        tileSize: tileSize,
                        projection: projection,
                        crossOrigin: crossOrigin,
                    });
                }
                else {

                    alert('Unsupported file type ' + type);
                    //
                    return false;
                }

                const width = obj.image.width;
                const height = obj.image.height;
                //
                const extent = [0.00000000, -height, width, 0.00000000];

                background.session_data = obj;
                //
                background.setSource(source);
                background.setExtent(extent);

                map.getView().fit(extent, map.getSize());
                //
                return true;
            }
    }
    setupConnection_0_1_(obj) {

        const server = require('../../etc/server');
        //
        var host = server.serverHost();
        var port = server.serverPort();
        /*
        var host = server.host;
        var port = server.port;
        */
        //
        if (obj.image.serverHost) host = obj.image.serverHost;
        if (obj.image.serverPort) port = obj.image.serverPort;
        //
        try {

            console.log('SocketConnection.setupConnection_0_1_: ' + host + ':' + port);
            //
            this.socket_ = io.connect(host + ':' + port);
        }
        catch (ex) {

            alert('ex when trying to create socket to ' + host + ':' + port + ': ' + ex);
            //
            return false;
        }

        console.log('SocketConnection.setupConnection_0_1_: this.socket_.connected = ' + this.socket_.connected);

        this.mouseTimer_ = new timeManager(100);

        this.uuid_ = obj.uuid;

        this.imageName_ = obj.image.imageName;

        console.log('SocketConnection:connect: ');
        console.log('  host  ' + host);
        console.log('  port  ' + port);

        var map = this.map;

        this.socket_.map = map;
        this.socket_.socket = this;

        this.socket_.on('connect', this.onSocketConnect_);
        this.socket_.on('disconnect', this.onSocketDisconnect_);
        //
        this.socket_.on('initialize', this.onSocketInitialize_);
        //
        this.socket_.on('mousemove', this.onSocketMouseMove_);
        this.socket_.on('mouseclick', this.onSocketMouseClick_);

        console.log('SocketConnection.setupConnection_0_1_: map = ', this.map);

        this.map.setSocket(this);
        //
        return true;
    }
    setupMap_0_1_(obj) {

        var map = this.map;

        const title = obj.image.title;
        const attribution = obj.image.attribution;
        //
        map.setTitle(title);
        map.setAttribution(attribution);

        console.log('SocketConnection.setupMap_0_1_: map = ', map);

        //map.updateLayers();

        map.on('pointermove', this.onMapMouseMove_);
        map.on('dblclick', this.onMapMouseDblClick_);
        map.on('singleclick', this.onMapMouseSingleClick_);
        //
        map.getViewport().map = map;
        //
        map.getViewport().addEventListener('mouseout', this.onMapMouseLeave_);

        //map.getViewport().removeEventListener("mouseout", this.onMapMouseLeave_);

        map.updateLayers();

        return true;
    }
    //
    init_version_0_1_(obj, fit_view) {
        //
        if (!this.setupBackground_0_1_(obj, fit_view)) {

            console.log('SessionControl.setupBackground_0_1_: failure!');
            //
            return false;
        }

        if (!this.setupConnection_0_1_(obj)) {

            console.log('SessionControl.setupConnection_0_1_: failure!');
            //
            return false;
        }

        if (!this.setupMap_0_1_(obj)) {

            console.log('SessionControl.setupMap_0_1_: failure!');
            //
            return false;
        }

        return true;
    }

    // Utilities
    static coo2str_(coo_i, coo_w, coo_W) {
        //
        var str_i = '??';
        var str_w = '??';
        var str_W = '??';
        //
        if (coo_i)
            str_i = coo2str(coo_i, 2, false);
        //
        if (coo_w)
            str_w = coo2str(coo_w, 2, true).toLocaleString();
        //
        if (coo_W)
            str_W = coo2str([coo_W[1], coo_W[0]], 8, false);  // invert latitude and longitude

        var str = str_W;
        //
        str += strSpace(5) + '[' + strSpace(2) + str_w + strSpace(2) + ']';
        /*
        str += strSpace(8) + '/' + strSpace(8) + '[' + strSpace(2) + str_i + strSpace(2) + ']';
        /**/
        //
        return str;
    }

    // Socket events
    onSocketConnect_() {
        //
        console.log('SocketConnection.onSocketConnect_()...');
        //
        const socket = this.socket; // SocketConnection
        //
        console.log('SocketConnection.onSocketConnect_(): socket.uuid      = ' + socket.uuid_);
        console.log('SocketConnection.onSocketConnect_(): socket.imageName = ' + socket.imageName_);

        var message = {
            version: 1.0,
            uuid: socket.uuid_,
        };
        //
        console.log('SocketConnection.onSocketConnect_(): message = ', message);

        this.emit('initialize', message);
    }
    onSocketDisconnect_() {
        //
        // Do nothing!
    }

    onSocketInitialize_(message) {
        //
        console.log('SocketConnection.onSocketInitialize_: message = ', message);
        //
        const version = message.version;
        //
        if (!message.ok) {
            //
            var msg = 'Server connection: ';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'unknown error!';
            //
            console.log(msg);
            //
            return;
        }

        const epsg = message.epsg;
        const prj4 = message.prj4;
        const rect = message.rect;

        //*
        console.log('SocketConnection.onSocketInitialize_:');
        console.log('      version = ' + version);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        console.log('      rect    = ' + rect);
        /**/

        // Register projection
        Projection.register(epsg, prj4);

        // Set image rect
        this.map.setImageRect(rect);

        // Setup/update map layers
        //this.map.updateLayers();
    }

    onSocketMouseMove_(message) {
        //
        //console.log('onSocketMouseMove_: message = ', message);
        //
        var str = SocketConnection.coo2str_(message.coo_i, message.coo_w, message.coo_WGS84);
        //
        this.map.setMousePosition(str);

        return;

        var str_i = '??';
        var str_w = '??';
        var str_WGS84 = '??';

        if (message.ok) {
            //
            const coo_i = message.coo_i;
            const coo_w = message.coo_w;
            const coo_WGS84 = [
                message.coo_WGS84[1], // invert latitude and longitude
                message.coo_WGS84[0],
            ];

            str_i = coo2str(coo_i, 2, false);
            str_w = coo2str(coo_w, 2, true).toLocaleString();
            str_WGS84 = coo2str(coo_WGS84, 8, false);
        }

        var str = str_WGS84;
        //
        str += strSpace(5) + '[' + strSpace(2) + str_w + strSpace(2) + ']';
        //*
        str += strSpace(8) + '/' + strSpace(8) + '[' + strSpace(2) + str_i + strSpace(2) + ']';
        /**/

        this.map.setMousePosition(str);
    }
    onSocketMouseClick_(message) {
        //
        console.log('SocketConnection.onSocketMouseClick_: message = ', message);

        if (!message.ok) {
            //
            var msg = 'Mouse click: ';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'unknown error!';
            //
            console.log(msg);
            //
            return;
        }

        var msg = 'Mouse click on:\n';
        //
        msg += '   ' + 'PIXEL = ' + '(' + message.coo_i[0] + ', ' + message.coo_i[1] + ')';
        msg += '\n';
        msg += '   ' + 'EPSG ' + message.srs_w + ' = (' + message.coo_w[0] + ', ' + message.coo_w[1] + ', ' + message.coo_w[2] + ')';
        msg += '\n';
        msg += '   ' + 'EPSG ' + message.srs_WGS84 + ' = (' + message.coo_WGS84[0] + ', ' + message.coo_WGS84[1] + ')';
        //
        console.log(msg);
    }

    emitMessage_(cmd, coo_i) {
        //
        var message = {
            version: 1.0,
            uuid: this.uuid_,
            coo_i: coo_i,
        };
        //
        this.socket_.emit(cmd, message);
    }

    onMapMouseMove_(evt) {
        //
        //console.log('SocketConnection.onMapMouseMove: evt = ' + evt);
        //
        if (!this.getSocket().mouseTimer_.ok()) {
            //
            //console.log('map.on(\'pointermove\'): in TIMEOUT');
            //
            //this.event = null;
            //
            return;
        }

        this.getSocket().emitMessage_('mousemove', evt.coordinate); // this is a MyMap object

        //this.emitMessage_('mousemove', evt.coordinate);

        return;

        const coo = evt.coordinate;
        //
        const x = coo[0];
        const y = coo[1];
        //
        //this.emitMessage_('mousemove', x, y);
    }

    onMapMouseDblClick_(evt) {
        //
        console.log('SocketConnection:onMapMouseDblClick_: ', evt);
        //
    }

    onMapMouseSingleClick_(evt) {
        //
        console.log('SocketConnection:onMapMouseSingleClick_: ', evt);
        //
        var map = this.getSocket().map;

        var info = '';
        //
        map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            //
            console.log('  feature = ', feature);
            //
            if (feature.info)
                info += feature.info;
        });
        //
        //console.log('  info = ', info);
        //
        if (info.length > 0) {
            //
            //evt.preventDefault();
            //
            map.showFeatureInfo(evt.coordinate, info);
            //
            return;
        }
        else if (map.featureInfoOn()) {
            //
            evt.preventDefault();
            //
            map.hideFeatureInfo();
        }

        return;

        /*
        var info = '<p>You clicked here:<br><br>' + coordinate.toString() + '</p>';

        var features = [];
        //
        map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            //
            if (feature.info)
                features.push(feature);
        });

        var info = '<p>You clicked here:<br><br>' + coordinate.toString() + '</p>';
        //
        if (features.length > 0) {
            //
            info += '<p>';
            //
            for (var i = 0, len = features.length; i < len; ++i) {
                //
                info += '&nbsp;&nbsp;feature [' + i + ']: ID = ' + features[i].getId();
            }
            //
            info += '</p>';
        }

        this.getSocket().map.showFeatureInfo(coordinate, info);
        */

        return;


        console.log('SocketConnection:onMapMouseSingleClick_: ' + evt);
        //
        this.getSocket().emitMessage_('mouseclick', evt.coordinate); // this is a MyMap object

        return;

        this.emitMessage_('mouseclick', evt.coordinate);
    }
    onMapMouseLeave_(evt) {
        //
        //console.log('SocketConnection:onMapMouseLeave_: ' + evt);
        //
        this.map.setMousePosition(null);
    }

    // Public methods
    getMap() {
        //
        return this.map;
    }

    // Gps
    setGps(mode) {
        //
        console.log('SocketConnection.setGps(' + mode + ')...');
        //
        if (mode) {
            //
            const options = {
                //
                tracking: true,
                //
                trackingOptions: {
                    //
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    //timeout: 0,
                }
            };
            //
            this.geolocation_ = new Geolocation(options);
            //
            this.geolocation_.socket = this;
            //
            this.geolocation_.on('change', this.onGeolocationChange_);
            this.geolocation_.on('error', this.onGeolocationError_);
            //
            this.socket_.on('geolocation_w2i', this.onSocketGeolocationChange_);
        }
        else if (this.geolocation_) {
            //
            this.socket_.off('geolocation_w2i', this.onSocketGeolocationChange_);
            //
            this.geolocation_.un('error', this.onGeolocationError_);
            this.geolocation_.un('change', this.onGeolocationChange_);
            //
            this.geolocation_ = null;
        }
    }
    getGpsOn() {
        //
        return (this.geolocation_ !== null);
    }
    getGpsOff() {
        //
        return (this.geolocation_ == null);
    }

    onGeolocationChange_() {
        //
        //*
        console.log('SocketConnection.onGeolocationChange_()...');
        console.log('    position = ', this.getPosition());
        /**/
        //
        const position = this.getPosition();
        //
        const uuid = this.socket.uuid_;
        //
        const srs_gps = 4326; // WGS84
        const coo_gps = position;
        //
        const message = {
            version: 1.0,
            uuid: uuid,
            srs_gps: srs_gps,
            //*
            coo_gps: coo_gps,
            /*/
            coo_gps: [8.934663799704078, 46.44730060511071],
            /**/
        };
        //
        this.socket.socket_.emit('geolocation_w2i', message);
    }
    onGeolocationError_() {
        //
        console.log('SocketConnection.onGeolocationError_()...');
    }

    onSocketGeolocationChange_(message) {
        //
        /*
        console.log('SocketConnection.onSocketGeolocationChange_()...');
        console.log('   message = ', message);
        /**/

        if (!message.ok)
            return;

        const coo_i = message.coo_i;
        const coo_w = message.coo_w;
        const coo_gps = message.coo_gps;

        var str = SocketConnection.coo2str_(coo_i, coo_w, coo_gps);
        //
        this.map.setMousePosition(str);
        //
        this.map.setNavigationPosition(coo_i);
    }
}