//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
// String utilities: ccordinates -> string
//---------------------------------------------------------------------------
//export.coo2str = function (coo, fix, is_3d) {
//exports.coo2str = (coo, fix, is_3d) => {
//    export function coo2str(coo, fix, is_3d) {
function coo2str(coo, fix, is_3d) {

    var separator = ' '

    if (coo == null)
        if (is_3d)
            return '?, ?, ?';
        else
            return '?, ?';

    var str = '';
    //
    if ((coo[0] == null) || isNaN(coo[0])) str += '?'
    else {

        str += coo[0].toLocaleString(undefined, {
            useGrouping: true,
            minimumFractionDigits: fix,
            maximumFractionDigits: fix
        });
        //str += coo[0].toFixed(fix);
    }
    //
    str += separator;
    //
    if ((coo[1] == null) || isNaN(coo[1])) str += '?'
    else {
        str += coo[1].toLocaleString(undefined, {
            useGrouping: true,
            minimumFractionDigits: fix,
            maximumFractionDigits: fix
        });
        //str += coo[1].toFixed(fix);
    }

    if (!is_3d)
        return str;

    str += separator;
    //
    if ((coo[2] == null) || isNaN(coo[2])) str += '?'
    else {
        str += coo[2].toLocaleString(undefined, {
            useGrouping: true,
            minimumFractionDigits: fix,
            maximumFractionDigits: fix
        });
        //str += coo[2].toFixed(fix);
    }
    //
    return str;
}
//---------------------------------------------------------------------------
export { coo2str };
//---------------------------------------------------------------------------
//exports.coo2str = coo2str;
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
