import './EditControl.css';

import Collection from 'ol/Collection';

import GeoJSON from 'ol/format/GeoJSON';

import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';

import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';

import { Control } from 'ol/control';

import { DragBox, Draw, Modify, Select, Snap } from 'ol/interaction';
//import { altKeyOnly } from "ol/events/condition";
import { platformModifierKeyOnly } from 'ol/events/condition';

import Projection from "../utils/Projection";
import Utilities from "../utils/Utilities";

import imageSelect from './icons/Edit-Select.svg'
import imageTrash from './icons/Edit-Trash.svg'
import imagePoint from './icons/Edit-Point.svg'
import imageLine from './icons/Edit-Line.svg'
import imageCircle from './icons/Edit-Circle.svg'
import imagePolygon from './icons/Edit-Polygon.svg'
import imageCheckOn from './icons/Edit-Check-On.svg'
import imageCheckOff from './icons/Edit-Check-Off.svg'

/**
 * This is a workaround.
 * Returns the associated layer.
 * @param {ol.Map} map.
 * @return {ol.layer.Vector} Layer.
 */
//ol.Feature.prototype.getLayer = function(map) {
function getFeatureLayer(map, feature) {
    //
    var layer_, layersToLookFor = [];
    /**
     * Populates array layersToLookFor with only
     * layers that have features
     */
    var check = function (layer) {
        //
        if (layer instanceof LayerGroup) {
            //
            layer.getLayers().forEach(check);
        } else {
            //
            var source = layer.getSource();
            //
            if (source instanceof VectorSource) {
                //
                var features = source.getFeatures();
                //
                if (features.length > 0) {
                    //
                    layersToLookFor.push({
                        layer: layer,
                        features: features
                    });
                }
            }
        }
    }

    //loop through map layers
    map.getLayers().forEach(function (layer) {
        //
        if (layer instanceof LayerGroup) {
            layer.getLayers().forEach(check);
        } else {
            check(layer);
        }
    });

    //console.log('   aaa layersToLookFor = ', layersToLookFor);

    layersToLookFor.forEach(function (obj) {
        //
        var found = obj.features.some(function (f) {
            return (f === feature);
        });
        if (found) {
            //this is the layer we want
            layer_ = obj.layer;
        }
    });
    return layer_;
};

// Traverse all layers and call function on each layer
function forEachLayer(map, action) {
    //
    function check_(layer) {
        //
        if (layer instanceof LayerGroup) {
            //
            layer.getLayers().forEach(check_);
        } else {
            //
            action(layer);
        }
    }

    map.getLayers().forEach(function (layer) {
        //
        if (layer instanceof LayerGroup) {
            layer.getLayers().forEach(check_);
        } else {
            action(layer);
        }
    });

}

class ModifyTool {

    constructor(map) {

        this.select_ = new Select({
            //style: this.getStyle_
        });
        //
        map.addInteraction(this.select_);

        this.drag_box_ = new DragBox({
            condition: platformModifierKeyOnly,
        });
        //
        map.addInteraction(this.drag_box_);

        this.modify_ = null;
        //
        //this.selection_ = this.select_.getFeatures();

        /*
        this.modify_ = new Modify({
            features: this.select_.getFeatures()
        });
        //
        map.addInteraction(this.modify_);
        */

        this.setEvents();
    }

    _____getStyle_(feature, resolution) {
        //
        console.log('ModifyTool.getStyle_()...');
        //
        if (feature.info) {
            //
            console.log('  feature = ', feature);

            let style = feature.getStyle();
            //
            console.log('  style   = ', style);

            return feature.getStyle();
        }
    }

    setEvents() {

        /*
        var selectedFeatures = this.select_.getFeatures();

        this.select_.on('change:active', function () {
            selectedFeatures.forEach(function (each) {
                selectedFeatures.remove(each);
            });
        });
        */

        let this_ = this;
        //
        let modify = this.modify_;
        let select = this.select_;
        //
        let map = select.getMap();

        function updateSelect_(features) {
            //
            console.log('ModifyTool.updateSelect_())...');
            //
            console.log('    features.length = ', features.length);
            //
            if (features.length) {
                //
                features.forEach(function (feature) {
                    //
                    console.log('    feature.type    = ', feature.getGeometry().getType());
                    //
                    if (feature.setSelectStyle) {
                        //
                        feature.setSelectStyle(feature);
                    }
                });
            }
        }
        function updateUnselect_(features) {
            //
            console.log('ModifyTool.updateUnselect_())...');
            //
            console.log('    features.length = ', features.length);
            //
            if (features.length) {
                //
                features.forEach(function (feature) {
                    //
                    console.log('    feature.type    = ', feature.getGeometry().getType());
                    //
                    if (feature.setDefaultStyle) {
                        //
                        feature.setDefaultStyle(feature);
                    }
                });
            }
        }

        select.on('select', function (e) {
            //
            console.log('ModifyTool.on(\'select\')...');

            updateSelect_(e.selected);
            updateUnselect_(e.deselected);
            //
            this_.activateModify_(true);

            return;

            {
                var selected = e.selected;
                //
                console.log('    selected.length = ', selected.length);
                //
                if (selected.length) {
                    //
                    selected.forEach(function (feature) {
                        //
                        if (feature.setSelectStyle) {
                            //
                            feature.setSelectStyle(feature);
                        }
                    });
                }

                var deselected = e.deselected;
                //
                console.log('  deselected.length = ', deselected.length);
                //
                if (deselected.length) {
                    //
                    deselected.forEach(function (feature) {
                        //
                        if (feature.setDefaultStyle) {
                            //
                            feature.setDefaultStyle(feature);
                        }
                    });
                }

                this_.activateModify_();

                return;
            }

            {
                let feature_modify = new Collection();

                let feature_select = select.getFeatures();
                //
                feature_select.forEach(function (feature) {
                    //
                    let layer = getFeatureLayer(map, feature);
                    //
                    console.log('    feature = ', feature);
                    console.log('    layer   = ', layer);

                    if (!layer.read_only)
                        feature_modify.push(feature);
                });

                console.log('    feature_modify = ', feature_modify);

                map.removeInteraction(modify);
                //
                delete this_.modify;

                this_.modify = null;

                if (feature_modify.getLength() === 0) {
                    //
                    console.log('    modify setActive(false) !!!!!!');

                    modify.setActive(false);
                }
                else {
                    //
                    console.log('    updating modify!!!!!!');

                    //map.removeInteraction(this_.modify);

                    this_.modify = new Modify({
                        features: feature_modify,
                    });
                    //
                    map.addInteraction(this_.modify);
                    //
                    this_.modify.setActive(true);
                }

                /*
                var selected = evt.selected;
                var deselected = evt.deselected;
                
                if (selected.length) {
                    selected.forEach(function(feature){
                        console.info(feature);
                        feature.setStyle(style_modify);
                    });
                } else {
                    deselected.forEach(function(feature){
                        console.info(feature);
                        feature.setStyle(null);
                    });
                }
                */

                /*
                var extent = e.target.getFeatures().getArray()[0].getGeometry().getExtent();
                e.target.getFeatures().getArray()[0].setStyle(style_selected); //commenting out this line works but adds opacity to the layer
                map.getView().fit(extent, { duration: 590 }, map.getSize());
                */
            }
        });

        // Box selection management
        //   see: https://openlayers.org/en/latest/examples/box-selection.html

        // Clear selection when drawing a new box and when clicking on the map
        let dragBox = this.drag_box_;
        let selectedFeatures = select.getFeatures();
        //
        dragBox.on('boxstart', function () {
            //
            selectedFeatures.clear();
        });

        dragBox.on('boxend', function () {
            //
            // Features that intersect the box geometry are added to the
            // collection of selected features

            // If the view is not obliquely rotated the box geometry and
            // its extent are equalivalent so intersecting features can
            // be added directly to the collection
            var rotation = map.getView().getRotation();
            var oblique = rotation % (Math.PI / 2) !== 0;
            var candidateFeatures = oblique ? [] : selectedFeatures;
            var extent = dragBox.getGeometry().getExtent();
            //
            forEachLayer(map, function (layer) {
                //
                let source = layer.getSource();
                //
                if (source instanceof VectorSource) {
                    //
                    source.forEachFeatureIntersectingExtent(extent, function (feature) {
                        //
                        candidateFeatures.push(feature);
                    });
                }
            });

            // When the view is obliquely rotated the box extent will
            // exceed its geometry so both the box and the candidate
            // feature geometries are rotated around a common anchor
            // to confirm that, with the box geometry aligned with its
            // extent, the geometries intersect
            if (oblique) {
                var anchor = [0, 0];
                var geometry = dragBox.getGeometry().clone();
                geometry.rotate(-rotation, anchor);
                var extent$1 = geometry.getExtent();
                candidateFeatures.forEach(function (feature) {
                    var geometry = feature.getGeometry().clone();
                    geometry.rotate(-rotation, anchor);
                    if (geometry.intersectsExtent(extent$1)) {
                        selectedFeatures.push(feature);
                    }
                });
            }

            console.log('  selectedFeatures = ', selectedFeatures);

            updateSelect_(selectedFeatures.getArray());
            //
            this_.activateModify_(true);
        });
    }

    activateModify_(active) {
        //
        console.log('ModifyTool.activateModify_()...')
        //
        let select = this.select_;
        //
        let map = select.getMap();

        // Remove and delete current modify tool
        if (this.modify_) {
            //
            map.removeInteraction(this.modify_);
            //
            delete this.modify_;
            //
            this.modify_ = null;
        }

        if (!active)
            return;

        // Get modifiable features
        let features = new Collection();

        let selection = select.getFeatures();
        //
        selection.forEach(function (feature) {
            //
            let layer = getFeatureLayer(map, feature);
            //
            //*
            console.log('    feature = ', feature);
            console.log('    layer   = ', layer);
            /**/
            //
            if (!layer) {

                console.log('    AAAAAAAAAAAAAAAAAAAAAAAAAA');

                return;
            }

            if (!layer.read_only)
                features.push(feature);
        });

        //console.log('    features = ', features);

        if (features.getLength() === 0)
            return;

        // Insert new modify tool
        let insertVertexCondition = platformModifierKeyOnly;
        //
        let modify = new Modify({
            features: features,
            insertVertexCondition: insertVertexCondition
        });
        /*
        this.modify_ = new Modify({
            features: features,
        });
        */

        if (false) {
            function onModifyStart_() {
                //
                console.log('ModifyTool.onModifyStart_())...');
                //

            }
            function onModifyEnd_() {
                //
                console.log('ModifyTool.onModifyEnd_())...');
                //

            }
            //
            modify.on('modifystart', function () {
                //
                onModifyStart_();
            });
            modify.on('modifyend', function () {
                //
                onModifyEnd_();
            });
        }

        this.modify_ = modify;
        //
        map.addInteraction(this.modify_);
        //
        //this_.modify_.setActive(true);
    }

    setActive(active) {
        //
        console.log('ModifyTool.setActive(' + active + ')...');
        console.log('  this.select_.getFeatures() = ', this.select_.getFeatures());

        this.select_.setActive(active);
        //
        this.activateModify_(active);

        //this.modify_.setActive(active);
    }
}

class ToolType {

    static point = new ToolType('point');
    static line = new ToolType('line');
    static polygon = new ToolType('polygon');
    static circle = new ToolType('circle');

    constructor(name) {
        this.name = name;
    }

    toString() {
        return `ToolType.${this.name}`;
    }
}

class DrawTool {

    constructor(map, vector) {

        //var vector = map.getLayers().item(1);

        //this.point = new MyDraw({
        this.point = new Draw({
            source: vector.getSource(),
            type: 'Point'
        });

        this.line = new Draw({
            source: vector.getSource(),
            type: 'LineString'
        });

        this.polygon = new Draw({
            source: vector.getSource(),
            type: 'Polygon'
        });

        this.circle = new Draw({
            source: vector.getSource(),
            type: 'Circle'
        });

        this.activeType = null;

        map.addInteraction(this.point);
        this.point.setActive(false);
        this[ToolType.point] = this.point;

        map.addInteraction(this.line);
        this.line.setActive(false);
        this[ToolType.line] = this.line;

        map.addInteraction(this.polygon);
        this.polygon.setActive(false);
        this[ToolType.polygon] = this.polygon;

        map.addInteraction(this.circle);
        this.circle.setActive(false);
        this[ToolType.circle] = this.circle;
    }

    getActive() {

        return this.activeType ? this[this.activeType].getActive() : false;
    }

    ____getToolOf_(type) {

        switch (type) {
            case ToolType.point:
                return this.point;
            case ToolType.line:
                return this.line;
            case ToolType.polygon:
                return this.polygon;
            case ToolType.circle:
                return this.circle;
        }
        //
        return null;
    }

    setActive(active, type = null) {

        if (active) {
            this.activeType && this[this.activeType].setActive(false);
            this[type].setActive(true);
            this.activeType = type;
        } else {
            this.activeType && this[this.activeType].setActive(false);
            this.activeType = null;
        }
    }
}

export default class EditControl extends Control {

    constructor(opt_options) {

        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'edit ol-unselectable ol-control';

        super({ element: element, target: options.target });

        var btn_point = document.createElement('input');
        //
        btn_point.className = 'edit-button-point edit-button';
        btn_point.title = 'Insert point';
        btn_point.type = 'image';
        btn_point.src = imagePoint;
        //
        btn_point.addEventListener('click', this.handleEditPoint.bind(this), false);
        //
        element.appendChild(btn_point);

        var btn_line = document.createElement('input');
        //
        btn_line.className = 'edit-button-line edit-button';
        btn_line.title = 'Insert polyline';
        btn_line.type = 'image';
        btn_line.src = imageLine;
        //
        btn_line.addEventListener('click', this.handleEditLine.bind(this), false);
        //
        element.appendChild(btn_line);

        var btn_polygon = document.createElement('input');
        //
        btn_polygon.className = 'edit-button-polygon edit-button';
        btn_polygon.title = 'Insert polygon';
        btn_polygon.type = 'image';
        btn_polygon.src = imagePolygon;
        //
        btn_polygon.addEventListener('click', this.handleEditPolygon.bind(this), false);
        //
        element.appendChild(btn_polygon);

        /*
        var btn_circle = document.createElement('input');
        //
        btn_circle.className = 'edit-button-circle edit-button';
        btn_circle.title = 'Insert circle';
        btn_circle.type = 'image';
        btn_circle.src = imageCircle;
        //
        btn_circle.addEventListener('click', this.handleEditCircle.bind(this), false);
        //
        element.appendChild(btn_circle);
        */

        // Claudio 18.05.2021: check features buttons removed 
        if (false) {

            var btn_check_on = document.createElement('input');
            //
            btn_check_on.className = 'edit-button-check-on edit-button-check edit-button';
            btn_check_on.title = 'Check geometry';
            btn_check_on.type = 'image';
            btn_check_on.src = imageCheckOn;
            //
            btn_check_on.addEventListener('click', this.handleEditCheckOn.bind(this), false);
            //
            element.appendChild(btn_check_on);

            var btn_check_off = document.createElement('input');
            //
            btn_check_off.className = 'edit-button-check-off edit-button-check edit-button';
            btn_check_off.title = 'Remove check geometry';
            btn_check_off.type = 'image';
            btn_check_off.src = imageCheckOff;
            //
            btn_check_off.addEventListener('click', this.handleEditCheckOff.bind(this), false);
            //
            element.appendChild(btn_check_off);
        }

        var btn_modify = document.createElement('input');
        //
        btn_modify.className = 'edit-button-modify edit-button';
        btn_modify.title = 'Select object';
        btn_modify.type = 'image';
        btn_modify.src = imageSelect;
        //
        btn_modify.addEventListener('click', this.handleEditModify.bind(this), false);
        //
        element.appendChild(btn_modify);

        var btn_delete = document.createElement('input');
        //
        btn_delete.className = 'edit-button-delete edit-button';
        btn_delete.title = 'Delete object(s)';
        btn_delete.type = 'image';
        btn_delete.src = imageTrash;
        //
        btn_delete.addEventListener('click', this.handleEditDelete.bind(this), false);
        //
        element.appendChild(btn_delete);

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    getSelect_() {
        //
        var select = null;
        //
        this.getMap().getInteractions().forEach(function (interaction) {

            if (interaction instanceof Select) {
                //
                select = interaction;
            }
        });
        //
        return select;
    }

    static ____clearWorkLayer_(map) {
        //
        const work_layer = map.getWorkLayer();
        //
        var source = work_layer.getSource();
        //
        var features = source.getFeatures();
        //
        if (features.length === 0) {
            //
            return;
        }
        //
        source.clear();
    }
    static clearTmpLayer_(map) {
        //
        const tmp_layer = map.getTmpLayer();
        //
        var source = tmp_layer.getSource();
        //
        var features = source.getFeatures();
        //
        if (features.length === 0) {
            //
            return;
        }
        //
        source.clear();
    }

    handleEditPoint() {
        //
        console.log('EditControl.handleEditPoint');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.draw_.setActive(true, ToolType.point);
        this.modify_.setActive(false);
    };
    handleEditLine() {
        //
        console.log('EditControl.handleEditLine');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.draw_.setActive(true, ToolType.line);
        this.modify_.setActive(false);
    };
    handleEditPolygon() {
        //
        console.log('EditControl.handleEditPolygon');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.draw_.setActive(true, ToolType.polygon);
        this.modify_.setActive(false);
    };
    handleEditCircle() {
        //
        console.log('EditControl.handleEditCircle');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.draw_.setActive(true, ToolType.circle);
        this.modify_.setActive(false);
    };
    handleEditModify() {
        //
        console.log('EditControl.handleEditModify');
        //
        this.draw_.setActive(false);
        this.modify_.setActive(true);
    };
    handleEditDelete() {

        console.log('EditControl.handleEditDelete');
        //
        var select = this.getSelect_();
        //
        if (!select)
            return;

        var features = select.getFeatures();
        //
        if (!features) {
            //
            alert('No features to delete!');
            //
            return;
        }
        else if (features.getLength() == 0) {
            //
            //var foreground = this.getMap().getForeground();
            //
            var source = this.getMap().getWorkLayer().getSource();
            //
            var features = source.getFeatures();
            //
            if (features.length == 0) {
                //
                alert('No features to delete!');
                //
                return;
            }

            var msg = '';
            //
            msg += 'No features selected: remove all features (';
            msg += features.length;
            msg += ')?';
            //
            if (!window.confirm(msg)) {
                //
                return;
            }

            source.clear();
            //
            EditControl.clearTmpLayer_(this.getMap());
        }
        else {

            if (!window.confirm('Remove the ' + features.getLength() + ' selected feature(s)?')) {
                return;
            }

            let map = this.getMap();
            //
            features.forEach(function (feature) {

                //var layer = select.getLayer(feature);
                var layer = Utilities.getFeatureLayer(map, feature);
                //
                layer.getSource().removeFeature(feature);
            });
        }
    }

    handleEditCheckOn() {
        //
        console.log('EditControl.handleEditCheckOn');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        var select = this.getSelect_();
        //
        if (!select)
            return;

        var features = select.getFeatures();
        //
        if (!features) {
            //
            alert('No features to check!');
            //
            return;
        }
        else if (features.getLength() == 0) {
            //
            /*
            var foreground = this.getMap().getForeground();
            //
            var source = foreground.getSource();
            */
            var source = this.getMap().getWorkLayer().getSource();
            //
            features = source.getFeatures();
            //features = new Collection(source.getFeatures());
            //
            if (features.length == 0) {
                //
                alert('No features to check!');
                //
                return;
            }

            var msg = '';
            //
            msg += 'No features selected: check all features (';
            msg += features.length;
            msg += ')?';
            //
            if (!window.confirm(msg)) {
                //
                return;
            }
        }
        else {

            if (!window.confirm('Check the ' + features.getLength() + ' selected feature(s)?')) {
                return;
            }

            // Convert to array for GeoJSON.writeFeatures()
            var array = [];
            //
            features.forEach(function (feature) {
                //
                array.push(feature);
            });
            //
            features = array;
        }
        //
        console.log('EditControl.handleEditCheckOn: features = ', features);

        const version = 1.0;

        var geojson = new GeoJSON();
        //
        var data = geojson.writeFeatures(features);

        console.log('EditControl.handleEditCheckOn: data =', data);

        const action = 'checkFeatures';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        socket.getSocket().on(action, this.onSocketCheckFeatures_);
        //
        const message = {
            version: version,
            uuid: socket.uuid(),
            data: data,
        };
        //
        socket.getSocket().emit(action, message);
    }
    handleEditCheckOff() {
        //
        EditControl.clearTmpLayer_(this.getMap());
    }

    static getCheckStyle_() {
        //
        const red = [255, 0, 0, 1];
        const width = 3.5;
        //
        return new Style({
            image: new CircleStyle({
                radius: width * 2,
                fill: null,
                stroke: new Stroke({
                    color: red,
                    width: width / 2
                })
            }),
            zIndex: Infinity
        });
    }

    onSocketCheckFeatures_(message) {
        //
        // Remove callback
        this.off('checkFeatures');

        console.log('EditControl.onSocketCheckFeatures_: message = ' + message);
        //
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('EditControl.onSocketCheckFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Could not check feature(s): unknown error!';
            //
            console.log(msg);
            //
            alert(msg);
            //
            return;
        }

        // Register projection
        Projection.register(epsg, prj4);

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        /*
        console.log('EditControl.onSocketCheckFeatures_:');
        console.log('      features = ' + features);
        /**/

        if (features.length == 0) {
            //
            alert('All features are ok!')
            //
            return;
        }

        alert('Found ' + features.length + ' feature(s) not ok!')

        const tmp_layer = this.map.getTmpLayer();
        //
        if (features.length !== 0) {
            //
            const style = EditControl.getCheckStyle_();
            //
            for (let i = 0; i !== features.length; i++) {

                var feature = features[i];
                //
                feature.setStyle(style);
            }
        }

        tmp_layer.getSource().addFeatures(features);

        return;

        const work_layer = this.map.getWorkLayer();
        //
        if (features.length !== 0) {
            //
            const style = EditControl.getCheckStyle_();
            //
            for (let i = 0; i !== features.length; i++) {

                var feature = features[i];
                //
                feature.setStyle(style);
            }
        }

        work_layer.getSource().addFeatures(features);
    }

    /**
     * @inheritDoc
     * @api
     */
    setMap(map) {
        super.setMap(map);

        if (map) {

            if (false) {

                var layers = map.getLayers();

                var vector = map.getLayers().item(1);

                var Point = new Draw({
                    source: vector.getSource(),
                    type: 'Point'
                });
                map.addInteraction(Point);
                Point.setActive(true);

                return;
            }

            //var vector = map.getForeground();
            var vector = map.getWorkLayer();
            //
            this.modify_ = new ModifyTool(map, vector);
            this.draw_ = new DrawTool(map, vector);

            // The snap interaction must be added after the Modify and Draw interactions
            // in order for its map browser event handlers to be fired first. Its handlers
            // are responsible of doing the snapping.
            var snap = new Snap({
                source: vector.getSource()
            });
            //
            this.getMap().addInteraction(snap);
        }
    }
}