//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
/*
const host = 'https://vmi383902.contaboserver.net';
const host = 'https://5.189.190.49';   // Contabo server
const host = 'https://193.72.242.223'; // local server
const host = 'https://mptHR.online';   // Contabo server
*/

// Local server for debug
/*
const host_debug = 'https://193.72.242.223'; // LIUS-C
const host_debug = 'https://193.72.242.114'; // LIUS-B
const host_debug = 'https://193.72.242.116'; // LIUS-B
const host_debug = 'https://192.168.43.139'; // Hotspot AndroidAP
const host_debug = 'https://mptHR.online';   // Contabo server
*/
const host_debug = 'https://mptHR.online';   // Contabo server
const port_debug = 8080;

// Production server
const host_production = 'https://mptHR.online';
const port_production = 8080;

function serverHost() {
    //
    console.log('serverHost(): window.location.hostname = ' + window.location.hostname)
    //
    if (window.location.hostname === 'localhost')
        return host_debug;
    //
    return host_production;
}
function serverPort() {
    //
    console.log('serverPort(): window.location.hostname = ' + window.location.hostname)
    //
    if (window.location.hostname === 'localhost')
        return port_debug;
    //
    return port_production;
}
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
// EXPORTS
//---------------------------------------------------------------------------
export { serverHost, serverPort };
//---------------------------------------------------------------------------
//---------------------------------------------------------------------------
