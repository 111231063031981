import './GpsControl.css';

import { Control } from 'ol/control';

import imageGpsOn from './icons/Gps-On.svg'
import imageGpsOff from './icons/Gps-Off.svg'

export default class GpsControl extends Control {

    constructor(opt_options) {

        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'gps ol-unselectable ol-control';

        super({ element: element, target: options.target });

        var btn_gps_on = document.createElement('input');
        //
        btn_gps_on.className = 'gps-button-gps-on gps-button';
        btn_gps_on.title = 'Enable GPS navigation';
        btn_gps_on.type = 'image';
        btn_gps_on.src = imageGpsOn;
        //
        btn_gps_on.disabled = false;
        //
        btn_gps_on.addEventListener('click', this.onGpsOn.bind(this), false);

        var btn_gps_off = document.createElement('input');
        //
        btn_gps_off.className = 'gps-button-gps-on gps-button';
        btn_gps_off.title = 'Disable GPS navigation';
        btn_gps_off.type = 'image';
        btn_gps_off.src = imageGpsOff;
        //
        btn_gps_off.disabled = true;
        //
        btn_gps_off.addEventListener('click', this.onGpsOff.bind(this), false);

        element.appendChild(btn_gps_on);
        element.appendChild(btn_gps_off);
        //
        Control.call(this, {
            element: element,
            target: options.target
        });

        this.btn_gps_on = btn_gps_on;
        this.btn_gps_off = btn_gps_off;
    }

    // Enable GPS
    updateGpsButtons() {
        //
        console.log('GpsControl.updateGpsButtons_(): this.getMap().getNavigationMode() = ' + this.getMap().getNavigationMode())
        //
        if (this.getMap().getNavigationMode()) {
            //
            this.btn_gps_on.disabled = true;
            this.btn_gps_off.disabled = false;
        }
        else {
            //
            this.btn_gps_on.disabled = false;
            this.btn_gps_off.disabled = true;
        }
    }
    //
    onGpsOn(e) {
        //
        console.log('GpsControl.onGpsOn');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.getMap().setNavigationMode(true);
        //
        this.updateGpsButtons();
    }

    // Disable GPS
    onGpsOff(e) {
        //
        console.log('GpsControl.onGpsOff');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.getMap().setNavigationMode(false);
        //
        this.updateGpsButtons();
    }

    setMap(map) {
        //
        super.setMap(map);

        if (map) {

            if (map.getNavigationMode()) {
                //
                this.btn_gps_on.disabled = true;
                this.btn_gps_off.disabled = false;
            }
            else {
                //
                this.btn_gps_on.disabled = false;
                this.btn_gps_off.disabled = true;
            }
        }
    }

}