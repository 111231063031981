import Popup from '../utils/Popup'
//
import './PopupPictures.css';
//
import SocketConnection from '../utils/SocketConnection';

export default class PopupPictures extends Popup {

    constructor(map) {

        console.log('PopupPictures: constructor...');
        //
        super('popupPictures');
        //
        this.map = map;

        var div = null;
        var p = null;
        var b = null;

        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        super.setTitle("Open picture");

        /*
        div = document.createElement('div');
        //
        div.className = 'popupPicturesTitle';
        //
        p = document.createElement('p');
        //
        p.className = 'popupPicturesTitleText';
        //
        p.innerHTML = "Open picture";
        //
        div.appendChild(p);
        //
        super.addToContent(div);
        */
        //--------------------------------------------------------------
        // Switch GPS on
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPicturesHeaderGps';

        p = document.createElement('p');
        //
        p.className = 'popupPicturesHeaderGpsText';
        //
        p.innerHTML = "Switch on GPS and enter to the area you are walking...";

        div.appendChild(p);
        //
        super.addToHeader(div);
        //super.addToContent(div);
        //--------------------------------------------------------------
        // Select file
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPicturesHeaderSelectFile';
        //--------------------------------------------------------------

        p = document.createElement('p');
        //
        p.className = 'popupPicturesHeaderSelectFileText';
        //
        p.innerHTML = "Select one of the following pictures:";

        div.appendChild(p);
        //
        super.addToHeader(div);
        //--------------------------------------------------------------
        // Pictures
        //--------------------------------------------------------------
        this.div_pictures = document.createElement('div');
        //
        this.div_pictures.className = 'popupPicturesSelectFile';
        //
        super.addToContent(this.div_pictures);
        //--------------------------------------------------------------
        // Open local file
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPicturesOpenFile';
        //
        p = document.createElement('p');
        //
        p.className = 'popupPicturesOpenFileText';
        //
        p.innerHTML = "Or open a custom picture file";
        //
        b = document.createElement('button');
        //
        b.className = 'popupPicturesOpenFileButton';
        //
        b.innerHTML = "...";
        //
        b.title = "Select local file";
        //
        b.popup = this;
        //
        b.onclick = this.handleFileOpen_;

        div.appendChild(p);
        div.appendChild(b);
        //
        super.addToFooter(div);
        //super.addToContent(div);
        //--------------------------------------------------------------
    }

    ___setPictures(pictures) {
        //
        console.log('PopupPictures.setPictures():');
        //console.log('   pictures (1) = ', pictures);
        //
        pictures.sort(function (p1, p2) {
            //
            if (p1.menu < p2.menu) {
                return -1;
            }
            if (p1.menu > p2.menu) {
                return 1;
            }
            return 0;
        });
        //
        //console.log('   pictures (2) = ', pictures);
        //
        //--------------------------------------------------------------
        var div = null;
        var p = null;
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPicturesSelectFile';
        //--------------------------------------------------------------
        p = document.createElement('p');
        //
        p.className = 'popupPicturesSelectFileText';
        //
        p.innerHTML = "or select one of the following pictures:";
        //
        div.appendChild(p);
        //--------------------------------------------------------------
        p = document.createElement('p');
        //
        p.className = 'popupPicturesSelectFileTextGps';
        //
        p.innerHTML = "Switch on GPS and enter to the area you are walking...";
        //
        div.appendChild(p);
        //--------------------------------------------------------------

        var table = document.createElement('table');
        //
        table.className = 'popupPicturesTable';

        pictures.forEach(picture => {
            //
            const map = this.map;
            const popup = this;
            //
            const uuid = picture.uuid;
            const menu = picture.menu;
            const title = picture.title;
            const source = picture.source;
            const object = picture.object;
            //
            /*
            console.log('PopupPictures.setPictures():');
            console.log('   uuid   = ', uuid);
            console.log('   menu   = ', menu);
            console.log('   title  = ', title);
            console.log('   source = ', source);
            console.log('   object = ', object);
            */

            var tr = document.createElement('tr');

            var td_1 = document.createElement('td');
            //
            td_1.className = 'popupPicturesTableC1';
            //
            td_1.innerHTML = menu;
            //
            td_1.onclick = function () {
                //
                console.log('td_1.onclick(): uuid =', uuid);
                console.log('td_1.onclick(): map  =', map);
                //
                popup.hide();
                //
                PopupPictures.openFile_fromObj_(map, object, true);
            }
            //
            tr.appendChild(td_1);

            var td_2 = document.createElement('td');
            //
            td_2.className = 'popupPicturesTableC2';
            //
            td_2.innerHTML = 'webGIS';
            //
            td_2.onclick = function () {
                //
                console.log('td_1.onclick(): uuid =', uuid);
                console.log('td_1.onclick(): map  =', map);
                //
                popup.hide();
                //
                PopupPictures.openFile_fromObj_(map, object, false);
            }
            //
            tr.appendChild(td_2);

            table.appendChild(tr);
        });

        div.appendChild(table);
        //
        super.addToContent(div);
    }
    setPictures(pictures) {
        //
        console.log('PopupPictures.setPictures():');
        //console.log('   pictures (1) = ', pictures);
        //
        pictures.sort(function (p1, p2) {
            //
            if (p1.menu < p2.menu) {
                return -1;
            }
            if (p1.menu > p2.menu) {
                return 1;
            }
            return 0;
        });
        //
        //console.log('   pictures (2) = ', pictures);
        //
        var table = document.createElement('table');
        //
        table.className = 'popupPicturesTable';
        //
        pictures.forEach(picture => {
            //
            const map = this.map;
            const popup = this;
            //
            const uuid = picture.uuid;
            const menu = picture.menu;
            const title = picture.title;
            const source = picture.source;
            const access = picture.access;
            const object = picture.object;
            //
            /*
            console.log('PopupPictures.setPictures():');
            console.log('   uuid   = ', uuid);
            console.log('   menu   = ', menu);
            console.log('   title  = ', title);
            console.log('   source = ', source);
            console.log('   access = ', access);
            console.log('   object = ', object);
            */
            //
            if (access !== 'public')
                return;

            var tr = document.createElement('tr');

            var td_1 = document.createElement('td');
            //
            td_1.className = 'popupPicturesTableC1';
            //
            td_1.innerHTML = menu;
            //
            td_1.title = 'Open picture in navigation mode';
            //
            td_1.onclick = function () {
                //
                console.log('td_1.onclick(): uuid =', uuid);
                console.log('td_1.onclick(): map  =', map);
                //
                popup.hide();
                //
                PopupPictures.openFile_fromObj_(map, object, true);
            }
            //
            tr.appendChild(td_1);

            var td_2 = document.createElement('td');
            //
            td_2.className = 'popupPicturesTableC2';
            //
            td_2.innerHTML = 'webGIS';
            //
            td_2.title = 'Open picture in GIS mode';
            //
            td_2.onclick = function () {
                //
                console.log('td_1.onclick(): uuid =', uuid);
                console.log('td_1.onclick(): map  =', map);
                //
                popup.hide();
                //
                PopupPictures.openFile_fromObj_(map, object, false);
            }
            //
            tr.appendChild(td_2);

            table.appendChild(tr);
        });
        //
        this.div_pictures.appendChild(table);
    }

    handleFileOpen_(e) {
        //
        console.log('PopupPictures.handleFileOpen_()...');
        console.log('   this = ', this);
        console.log('   e = ', e);

        // Initialize and click file selector
        var fileSelector = document.createElement('input');
        //
        fileSelector.popup = this.popup;
        //
        fileSelector.type = 'file';
        fileSelector.accept = '.m_picture';
        fileSelector.onchange = this.popup.handleFileChange_;
        //
        fileSelector.click();
    }
    handleFileChange_(e) {

        console.log('handleFileChange: e = ', e);
        /*
        for (var property in e) {
          console.log(property + ': ' + e[property] + '; ');
        }
        /**/

        console.log('handleFileChange: e.srcElement = ' + e.srcElement);
        /*
        for (var property in e.srcElement) {
          console.log(property + ': ' + e.srcElement[property] + '; ');
        }
        /**/

        //return;

        const files = e.target.files;

        console.log('handleFileChange: files = ' + files);
        //console.log('handleFileChange: files.size = ' + files.getSize());

        const file = files[0]

        if (file == null) {
            alert("No file to load!");
            return;
        }

        console.log('handleFileChange: file = ' + file);
        console.log('handleFileChange: file.name = ' + file.name);

        if (file.name.toLowerCase().endsWith(".m_picture")) {
            //
            const fileSelector = e.srcElement;
            //
            const popup = fileSelector.popup;
            //
            const map = popup.map;
            //
            PopupPictures.openFile_fromFile_(file, map);
            //
            popup.hide();
            //
            return;
        }

        if (file.name.toLowerCase().endsWith(".png")) {
            //
            const fileSelector = e.srcElement;
            //
            const popup = fileSelector.popup;
            //
            const map = popup.map;
            //
            PopupPictures.openFile_PNG(file, map);
            //
            popup.hide();
            //
            return;
        }

        alert("Illegal file!");
    }

    // Open file MPT
    static openFile_fromObj_(map, obj, navigation_mode = false) {
        //
        console.log('PopupPictures.openFile_fromObj_():');
        console.log('    obj = ', obj);
        //
        new SocketConnection(map, obj, true);
        //
        map.setNavigationMode(navigation_mode);
    }
    static openFile_fromFile_(file, map) {
        //
        console.log('Loading MPT file: ' + file.name);
        //
        const reader = new FileReader();
        //
        reader.onload = function (e) {

            const text = reader.result;

            var obj = null;

            try {

                obj = JSON.parse(text);
            }
            catch (ex) {

                alert('Error parsing file ' + file.name + ' [' + ex + ']!');
                //
                return;
            }

            PopupPictures.openFile_fromObj_(map, obj);

            //new SocketConnection(map, obj);

            //map.socket = new SocketConnection(map, obj);
        }

        if (file) {
            reader.readAsText(file);
        }
    }
}