import proj4 from 'proj4';
//
import { get as projGetProjection } from 'ol/proj';
import { register as proj4Register } from 'ol/proj/proj4';

export default class Projection {

  // Projection registration
  static register(epsg, prj4) {
    //
    var projection = projGetProjection('EPSG:' + epsg);
    //
    if (!projection) {
      //
      console.log('MyMap.registerProjection: register prj EPSG:' + epsg);
      //
      proj4.defs('EPSG:' + epsg, prj4);
      //
      proj4Register(proj4);
    }
    else
      console.log('MyMap.registerProjection: prj EPSG:' + epsg + ' already registered');

    /*
    let prj_pixels = projGetProjection('PIXELS');
    //
    console.log('  prj_pixels = ', prj_pixels);
    /**/
  }





}