import './RotateControl.css';

import Rotate from 'ol/control/Rotate';

import imageRotate from './icons/Rotate.svg'

class RotateControl extends Rotate {

    constructor(opt_options) {

        const options = opt_options ? opt_options : {};
        //
        options.render = render;
        //
        super(options);

        var element = this.element;
        //
        element.className = 'rotate ol-unselectable ol-control';

        // Remove all children
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }

        // See Rotate.js
        var btn_rotate = document.createElement('input');

        btn_rotate.className = 'rotate-button';
        btn_rotate.title = 'Reset rotation';
        btn_rotate.type = 'image';
        btn_rotate.src = imageRotate;
        //
        btn_rotate.addEventListener('click', this.handleClick_.bind(this), false);
        //
        element.appendChild(btn_rotate);

        this.button_ = btn_rotate;
    }

    handleClick_(event) {
        //
        super.handleClick_(event);
    }
}

// See Rotate.js
export function render(mapEvent) {
    //
    //console.log('RotateControl.render()...');
    //
    const frameState = mapEvent.frameState;
    //
    if (!frameState)
        return;

    const rotation = frameState.viewState.rotation;
    //
    if (rotation !== this.rotation_) {
        //
        const transform = 'rotate(' + rotation + 'rad)';
        //
        if (this.autoHide_) {
            //
            if (rotation === 0)
                this.element.style.display = "none";
            else
                this.element.style.display = "block";
        }
        this.button_.style.transform = transform;
    }
    this.rotation_ = rotation;
}

export default RotateControl;