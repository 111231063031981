import './SessionControl.css';

import { Control } from 'ol/control';

import GeoJSON from 'ol/format/GeoJSON';

import SocketConnection from '../utils/SocketConnection';

import imageSessionOpen from './icons/Session-Open.svg'
import imageSessionSave from './icons/Session-Save.svg'

export default class SessionControl extends Control {

    constructor(opt_options) {

        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'session ol-unselectable ol-control';

        super({ element: element, target: options.target });

        var btn_open = document.createElement('input');
        //
        btn_open.className = 'session-button-open session-button';
        btn_open.title = 'Restore session';
        btn_open.type = 'image';
        btn_open.src = imageSessionOpen;
        //
        btn_open.addEventListener('click', this.onSessionOpen.bind(this), false);

        var btn_save = document.createElement('input');
        //
        btn_save.className = 'session-button-save session-button';
        btn_save.title = 'Save session';
        btn_save.type = 'image';
        btn_save.src = imageSessionSave;
        //
        btn_save.addEventListener('click', this.onSessionSave.bind(this), false);

        element.appendChild(btn_open);
        element.appendChild(btn_save);
        //
        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    // Save session
    onSessionSave(e) {

        console.log('SessionControl.onSessionSave');
        //
        if (typeof (Storage) == "undefined") {

            console.log('Sorry: no web storage support!');
            //
            alert('Sorry: no web storage support!');
            //
            return;
        }

        localStorage.setItem("version", "0.1");
        //
        this.saveSession_0_1_();

        return;

        if (false) {
            /*
            // Foreround data
            var foreground = map.getForeground();
            //
            var features = foreground.getSource().getFeatures();
            //
            var geo_data = new GeoJSON().writeFeatures(features);
            //
            var fg_data = {
                content: geo_data,
            }
            //
            console.log('SessionControl.onSessionSave: JSON.stringify(fg_data) = ' + JSON.stringify(fg_data));

            // Write to local storage
            localStorage.setItem("version", "0.1");
            localStorage.setItem("background", JSON.stringify(bg_data));
            localStorage.setItem("foreground", JSON.stringify(fg_data));
            */
        }
    };

    // Restore session
    onSessionOpen(e) {

        console.log('SessionControl.onSessionOpen');
        //
        if (typeof (Storage) == "undefined") {

            console.log('Sorry: no web storage support!');
            //
            alert('Sorry: no web storage support!');
            //
            return;
        }

        var version = localStorage.getItem('version');
        //
        if (!version) {

            console.log('Sorry: no data available');
            //
            alert('Sorry: no data available');
            //
            return;
        }

        console.log('SessionControl.onSessionOpen: version = ' + version);

        if (version === "0.1") {
            //
            this.openSession_0_1_();
            //
            return;
        }

        console.log('Sorry: illegal version [' + version + ']');
        //
        alert('Sorry: illegal version [' + version + ']');
    };

    // Save/open session version 0.1
    //
    //   Session structure:
    //
    //   session_<n>: {
    //       name: <session name>
    //       background: {
    //           content: background.session_data,
    //           view: {
    //               center: view.getCenter(),
    //               rotation: view.getRotation(),
    //               resolution: view.getResolution(),
    //           }
    //       }
    //       foreground: {
    //           content: map.getWorkLayer().getSource().getFeatures(),
    //       }
    //   }
    saveSession_0_1_() {
        //
        const map = this.getMap();
        //
        const view = map.getView();

        // Background data
        const background = map.getBackground();
        //
        if (!background.session_data) {

            console.log('Sorry: no background data available!');
            //
            alert('Sorry: no background data available!');
            //
            return;
        }
        //
        const bg_data = {
            content: background.session_data,
            view: {
                center: view.getCenter(),
                rotation: view.getRotation(),
                resolution: view.getResolution(),
            }
        }
        //
        //console.log('SessionControl.onSessionSave: JSON.stringify(bg_data) = ' + JSON.stringify(bg_data));
        console.log('SessionControl.saveSession_0_1_: bg_data = ' + bg_data);

        // Foreround data
        const work_layer = map.getWorkLayer();
        //
        const features = work_layer.getSource().getFeatures();
        //
        const geo_data = new GeoJSON().writeFeatures(features);
        //
        var work_data = {
            content: geo_data,
        }
        //
        console.log('SessionControl.saveSession_0_1_: work_data = ' + work_data);

        // Store session
        const session = {
            //
            name: 'NAME',
            background: bg_data,
            work: work_data,
        }

        console.log('SessionControl.saveSession_0_1_: session = ', session);
        console.log('SessionControl.saveSession_0_1_: session = ', JSON.stringify(session));
        //
        const session_key = 'session_1';
        //
        localStorage.setItem(session_key, JSON.stringify(session));
    }
    openSession_0_1_() {
        //
        const session_key = 'session_1';
        //
        const data = localStorage.getItem(session_key);
        //
        console.log('SessionControl.openSession_0_1_: session = ', data);
        //
        if (!data) {

            console.log('Sorry: no session data available');
            //
            alert('Sorry: no session data available');
            //
            return false;
        }

        var session = null;

        try {
            session = JSON.parse(data);
        }
        catch (ex) {

            alert('Sorry: cannot retrieve session data [' + ex + ']');
            //
            return false;
        }
        //
        console.log('SessionControl.openSession_0_1_(): session = ', session);
        //
        if (!session) {
            //
            console.log('Sorry: no session data available');
            //
            alert('Sorry: no session data available');
            //
            return;
        }

        var map = this.getMap();
        //
        var view = map.getView();

        // Reset map layers
        map.resetLayers();

        // Background
        const bg_data = session.background;
        //
        new SocketConnection(map, bg_data.content);
        //
        view.setCenter(bg_data.view.center);
        view.setRotation(bg_data.view.rotation);
        view.setResolution(bg_data.view.resolution);

        // Foreground
        const work_data = session.work;
        //
        const geo_data = work_data.content;
        //
        const features = new GeoJSON().readFeatures(geo_data);
        //
        var work_layer = map.getWorkLayer();
        //
        work_layer.getSource().clear();
        //
        work_layer.getSource().addFeatures(features);

        // Work layer
        /*
        var work_layer = map.getWorkLayer();
        //
        work_layer.getSource().clear();
        */
    }


    // Old implementation
    ____onSessionOpen(e) {

        console.log('SessionControl.onSessionOpen');
        //
        if (typeof (Storage) == "undefined") {

            console.log('Sorry: no web storage support!');
            //
            alert('Sorry: no web storage support!');
            //
            return;
        }

        var version = localStorage.getItem('version');
        //
        if (!version) {

            console.log('Sorry: no data available');
            //
            alert('Sorry: no data available');
            //
            return;
        }

        console.log('SessionControl.onSessionOpen: version = ' + version);

        if (version === "0.1") {

            if (!this.restoreBackground_0_1_()) {
                //
                return false;
            }
            if (!this.restoreForeground_0_1_()) {
                //
                return false;
            }
            //
            return;
        }

        console.log('Sorry: illegal version [' + version + ']');
        //
        alert('Sorry: illegal version [' + version + ']');
    };
    ___restoreBackground_0_1_() {

        console.log('SessionControl.restoreBackground_0_1_');

        var data = localStorage.getItem("background");
        //
        if (!data) {

            console.log('Sorry: no background data available');
            //
            alert('Sorry: no background data available');
            //
            return false;
        }

        var obj = null;

        try {
            obj = JSON.parse(data);
        }
        catch (ex) {

            alert('Sorry: cannot retrieve background data [' + ex + ']');
            //
            return false;
        }

        console.log('SessionControl.restoreBackground_0_1_: obj = ' + obj.content);

        var map = this.getMap();
        //
        var view = map.getView();

        new SocketConnection(map, obj.content);
        //
        view.setCenter(obj.view.center);
        view.setRotation(obj.view.rotation);
        view.setResolution(obj.view.resolution);

        return true;
    }
    ___restoreForeground_0_1_() {

        console.log('SessionControl.restoreForeground_0_1_');

        var data = localStorage.getItem("foreground");
        //
        if (!data) {

            console.log('Sorry: no foreground data available');
            //
            alert('Sorry: no foreground data available');
            //
            return false;
        }

        var obj = null;

        try {
            obj = JSON.parse(data);
        }
        catch (ex) {

            alert('Sorry: cannot retrieve foreground data [' + ex + ']');
            //
            return false;
        }

        var foreground = this.getMap().getLayers().item(1);
        //
        var features = new GeoJSON().readFeatures(obj.content);
        //
        foreground.getSource().clear();
        //
        foreground.getSource().addFeatures(features);

        return true;
    }
}