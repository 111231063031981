import './GoogleEarthControl.css';

import { Control } from 'ol/control';

import imageGoogleEarth from './icons/GoogleEarth.svg'

export default class GoogleEarthControl extends Control {

    constructor(opt_options) {

        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'ge ol-unselectable ol-control';

        super({ element: element, target: options.target });

        var btn_ge = document.createElement('input');
        //
        btn_ge.className = 'ge-button-ge ge-button';
        btn_ge.title = 'Open Google Earth from the same perspective';
        btn_ge.type = 'image';
        btn_ge.src = imageGoogleEarth;
        //
        btn_ge.disabled = false;
        //
        btn_ge.addEventListener('click', this.onGoogleEarthCamera.bind(this), false);

        element.appendChild(btn_ge);
        //
        Control.call(this, {
            element: element,
            target: options.target
        });

        this.btn_ge = btn_ge;
    }

    // Google Earth click
    onGoogleEarthCamera(e) {
        //
        console.log('GoogleEarthControl.onGoogleEarth()...');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        e.preventDefault();

        const version = 1.0;

        const action = 'googleEarthCamera';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        if (!socket || !socket.uuid()) {
            //
            alert('Error: no picture available!');
            //
            return;
        }

        socket.getSocket().on(action, this.onSocketGoogleEarthCamera_);
        //
        const message = {
            version: version,
            uuid: socket.uuid(),
            //data: data,
        };
        //
        console.log('  message = ', message);
        //
        socket.getSocket().emit(action, message);
    }

    onSocketGoogleEarthCamera_(message) {
        //
        // Remove callback
        this.off('onSocketGoogleEarthCamera_');

        console.log('GoogleEarthControl.onSocketGoogleEarthCamera_: message = ', message);
        //
        const version = message.version;
        //
        const ok = message.ok;
        //
        const url = message.url;

        //*
        console.log('EditControl.onSocketCheckFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      url     = ' + url);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Error: could not compute the Google Earth camera of the picture!';
            //
            console.log(msg);
            //
            alert(msg);
            //
            return;
        }

        console.log('GoogleEarthControl.onSocketGoogleEarthCamera_: message OK!!!');

        console.log('  -> open link: ' + url);

        var win = window.open(url, '_blank');
        //
        //win.focus();
    }
}