import React from 'react';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import piexif from 'piexifjs';

//piexifjs

import './Camera.css'

/*
function handleTakePhoto(dataUri) {

    function dataURItoBlob(dataURI) {
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    function getFileExtention(blobType) {
        // by default the extention is .png
        let extention = IMAGE_TYPES.PNG;

        if (blobType === 'image/jpeg') {
            extention = IMAGE_TYPES.JPG;
        }
        return extention;
    }

    function getFileName(imageNumber, blobType) {
        const prefix = 'photo';
        const photoNumber = padWithZeroNumber(imageNumber, 4);
        const extention = getFileExtention(blobType);

        return `${prefix}-${photoNumber}.${extention}`;
    }

    function downloadImageFileFomBlob(blob, imageNumber) {

        window.URL = window.webkitURL || window.URL;

        let anchor = document.createElement('a');
        anchor.download = getFileName(imageNumber, blob.type);
        anchor.href = window.URL.createObjectURL(blob);
        let mouseEvent = document.createEvent('MouseEvents');
        mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
        anchor.dispatchEvent(mouseEvent);
    }


    // Do stuff with the photo...
    console.log('takePhoto');

    let blob = dataURItoBlob(dataUri);
    //
    downloadImageFileFomBlob(blob, imageNumber);

}
*/

function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
}
function handleTakePhotoAnimationDone(dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto');
}
function handleCameraError(error) {
    console.log('handleCameraError', error);
}
function handleCameraStart(stream) {
    console.log('handleCameraStart');
}
function handleCameraStop() {
    console.log('handleCameraStop');
}

//*
class MyCamera extends Camera {
    //

}
/**/

class MyFullscreen {

    static isSupported() {
        //
        const body = document.body;
        //
        return !!(
            body.webkitRequestFullscreen ||
            (body.msRequestFullscreen && document.msFullscreenEnabled) ||
            (body.requestFullscreen && document.fullscreenEnabled)
        );
    }

    static isOn() {
        //
        return !!(
            document.webkitIsFullScreen || document.msFullscreenElement || document.fullscreenElement
        );
    }
    static isOff() {
        //
        return !this.isOn();
    }

    static request(element) {
        //
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
        }
    }
    static exit() {
        //
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    static onChangeSet(element, callback) {
        //
        element.addEventListener("fullscreenchange", callback);
        element.addEventListener("mozfullscreenchange", callback);
        element.addEventListener("webkitfullscreenchange", callback);
        element.addEventListener("msfullscreenchange", callback);

        return;


        element.addEventListener("fullscreenchange", function () {
            console.log("fullscreenchange event fired!");
        });
        element.addEventListener("mozfullscreenchange", function () {
            console.log("mozfullscreenchange event fired!");
        });
        element.addEventListener("webkitfullscreenchange", function () {
            console.log("webkitfullscreenchange event fired!");
        });
        element.addEventListener("msfullscreenchange", function () {
            console.log("msfullscreenchange event fired!");
        });

        return;



        document.addEventListener("fullscreenchange", function () {
            console.log("fullscreenchange event fired!");
        });
        document.addEventListener("mozfullscreenchange", function () {
            console.log("mozfullscreenchange event fired!");
        });
        document.addEventListener("webkitfullscreenchange", function () {
            console.log("webkitfullscreenchange event fired!");
        });
        document.addEventListener("msfullscreenchange", function () {
            console.log("msfullscreenchange event fired!");
        });

        return;


        document.addEventListener("fullscreenchange", callback);
        document.addEventListener("mozfullscreenchange", callback);
        document.addEventListener("webkitfullscreenchange", callback);
        document.addEventListener("msfullscreenchange", callback);
    }
    static onChangeRemove(element, callback) {
        //
        element.removeEventListener("fullscreenchange", callback);
        element.removeEventListener("mozfullscreenchange", callback);
        element.removeEventListener("webkitfullscreenchange", callback);
        element.removeEventListener("msfullscreenchange", callback);

        return;

        document.removeEventListener("fullscreenchange", callback);
        document.removeEventListener("mozfullscreenchange", callback);
        document.removeEventListener("webkitfullscreenchange", callback);
        document.removeEventListener("msfullscreenchange", callback);
    }

}

class MyReactCamera extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            //
            visible: false,
            //
            imageNumber: 1,
            //
            imageCompression: 0.5,
            isMaxResolution: true,
            isFullscreen: false,
            idealResolution: {
                width: 1920,
                height: 1080,
            },
            imageType: IMAGE_TYPES.JPG,
            idealFacingMode: FACING_MODES.ENVIRONMENT,
        };

        this.camera_ = null;

        /*
        let camera_props = {};
        //
        camera_props.onTakePhoto = this.handleTakePhoto_;

        this.camera_ = new Camera(camera_props);
        */

        /*
        onTakePhoto={(dataUri) => { this.handleTakePhoto_(dataUri); }}
        onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
        onCameraError={(error) => { handleCameraError(error); }}
        idealFacingMode={this.state.idealFacingMode}
        idealResolution={this.state.idealResolution}
        imageType={this.state.imageType}
        imageCompression={this.state.imageCompression}
        isMaxResolution={this.state.isMaxResolution}
        isImageMirror={false}
        isSilentMode={false}
        isDisplayStartCameraError={true}
        isFullscreen={this.state.isFullscreen}
        sizeFactor={1}
        onCameraStart={(stream) => { handleCameraStart(stream); }}
        onCameraStop={() => { handleCameraStop(); }}
        */

        this.myCamera_ = null;

        this.video_ = null;

        this.watchID_ = null;

        this.positionOk_ = null;
        this.orientationOk_ = null;

        this.currentScreen_ = null;
        this.currentPosition_ = null;
        this.currentOrientaton_ = null;

        this.showHeading_ = true;
        this.showAltitude_ = true;

        this.cameraText_ = null;
        this.cameraData_ = null;

        // GPS data
        this.gpsValueHeading = null;
        this.gpsValueAltitude = null;
        this.gpsValueLatitude = null;
        this.gpsValueLongitude = null;
        this.gpsValueAccuracy = null;
        this.gpsValueAltitudeAccuracy = null;

        // Camera orientation data
        this.cameraValueAbsolute = null;
        this.cameraValueApha = null;
        this.cameraValueBeta = null;
        this.cameraValueGamma = null;

        // Screen orientation data
        this.screenValueOrientation = null;

        this.handleDevicePosition_ = this.onDevicePosition_.bind(this);
        this.handleDeviceOrientation_ = this.onDeviceOrientation_.bind(this);
        this.handleScreenOrientation_ = this.onScreenOrientation_.bind(this);
    }

    setupPosition_() {
        //

        if (navigator.geolocation) {
            //
            this.positionOk_ = true;
        }
        else {
            //
            this.positionOk_ = false;
        }
    }
    setupOrientation_() {
        //
        if ('DeviceOrientationEvent' in window) {
            //
            this.orientationOk_ = true;
        }
        else {
            //
            this.orientationOk_ = false;
            //
            //this.buttonTakePhoto.disabled = true;
            //
            alert('Warning: Device Orientation not supported!')
        }
        //
        //alert('setupOrientation_: ' + this.orientationOk_);
    }

    updateCameraData_(div) {
        //
        //--------------------------------------------------------------
        // Camera text message
        //--------------------------------------------------------------
        var p = document.createElement('p');
        //
        p.className = 'cameraTextMessage';
        //
        p.innerHTML = "";
        //
        div.appendChild(p);
        //
        this.cameraText_ = p;
        //--------------------------------------------------------------
        // Camera data table
        //--------------------------------------------------------------
        var table = document.createElement('table');
        //
        table.className = 'cameraTableData';
        //--------------------------------------------------------------
        function new_td_(text, className) {
            //
            td = document.createElement('td');
            //
            td.className = className;
            //
            td.innerHTML = text;
            //
            return td;
        }
        //--------------------------------------------------------------
        // Camera data
        //--------------------------------------------------------------
        let tr;
        let td;
        //--------------------------------------------------------------
        // Camera position
        //--------------------------------------------------------------
        tr = document.createElement('tr');

        td = new_td_('Position', 'gpsTitle')
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Heading
        if (this.showHeading_) {
            //
            tr = document.createElement('tr');

            td = new_td_('Heading', 'gpsText')
            //
            tr.appendChild(td);

            td = new_td_('-', 'gpsValue')
            //
            this.gpsValueHeading = td;
            //
            tr.appendChild(td);

            table.appendChild(tr);
        }

        // Latitude
        tr = document.createElement('tr');

        td = new_td_('Latitude', 'gpsText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'gpsValue')
        //
        this.gpsValueLatitude = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Longitude
        tr = document.createElement('tr');
        //
        td = new_td_('Longitude', 'gpsText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'gpsValue')
        //
        this.gpsValueLongitude = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Position accuracy
        tr = document.createElement('tr');
        //
        td = new_td_('Position accuracy', 'gpsText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'gpsValue')
        //
        this.gpsValueAccuracy = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Altitude
        if (this.showAltitude_) {
            //
            tr = document.createElement('tr');

            td = new_td_('Altitude', 'gpsText')
            //
            tr.appendChild(td);

            td = new_td_('-', 'gpsValue')
            //
            this.gpsValueAltitude = td;
            //
            tr.appendChild(td);

            table.appendChild(tr);

            // Altitude accuracy
            tr = document.createElement('tr');
            //
            td = new_td_('Altitude accuracy', 'gpsText')
            //
            tr.appendChild(td);

            td = new_td_('-', 'gpsValue')
            //
            this.gpsValueAltitudeAccuracy = td;
            //
            tr.appendChild(td);

            table.appendChild(tr);
        }
        //--------------------------------------------------------------
        // Camera orientation
        //--------------------------------------------------------------
        tr = document.createElement('tr');

        td = new_td_('Orientation', 'cameraTitle')
        //
        tr.appendChild(td);

        td = new_td_('', 'cameraValue')
        //
        this.cameraValueAbsolute = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Alpha
        tr = document.createElement('tr');

        td = new_td_('Alpha', 'cameraText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'cameraValue')
        //
        this.cameraValueAlpha = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Beta
        tr = document.createElement('tr');
        //
        td = new_td_('Beta', 'cameraText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'cameraValue')
        //
        this.cameraValueBeta = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Gamma
        tr = document.createElement('tr');

        td = new_td_('Gamma', 'cameraText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'cameraValue')
        //
        this.cameraValueGamma = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);
        //--------------------------------------------------------------
        // Screen orientation
        //--------------------------------------------------------------
        tr = document.createElement('tr');

        td = new_td_('Screen', 'screenTitle')
        //
        tr.appendChild(td);

        table.appendChild(tr);

        // Orientation
        tr = document.createElement('tr');

        td = new_td_('Orientation', 'screenText')
        //
        tr.appendChild(td);

        td = new_td_('-', 'screenValue')
        //
        this.screenValueOrientation = td;
        //
        tr.appendChild(td);

        table.appendChild(tr);
        //--------------------------------------------------------------
        div.appendChild(table);
        //--------------------------------------------------------------
    }
    updateCloseButton_(div) {
        //
        var span = document.createElement("span");
        //
        span.className = "cameraCloseButton";
        span.innerHTML = "&times;";
        //
        const camera = this;
        //
        span.onclick = function (e) {
            //
            /*
            console.log('span.onclick(): e = ', e);
            console.log('  this = ', this);
            console.log('  this.popup = ', this.popup);
            /**/
            //
            camera.hide();
        }

        div.appendChild(span);
    }

    updateUI_() {
        //
        const { visible } = this.state;
        //
        console.log('   visible = ' + visible);
        //
        if (!visible)
            return;

        let myCamera = document.getElementById('my-camera');
        //
        let camera = myCamera.getElementsByClassName("react-html5-camera-photo")[0];
        //
        let video = camera.getElementsByTagName('video');
        //
        if (video.length == 1) {
            //
            this.video_ = video.item(0);
        }
        else {
            //
            alert("MyReactCamera.aaa(): mode video found!")
        }
        //
        this.myCamera_ = myCamera;
        //*
        console.log('  myCamera = ', this.myCamera_);
        console.log('  camera   = ', camera);
        console.log('  video    = ', video);
        /**/
        console.log('  video    = ', video);
        //
        this.setupPosition_();
        this.setupOrientation_();
        //
        console.log('  this.positionOk_    = ', this.positionOk_);
        console.log('  this.orientationOk_ = ', this.orientationOk_);
        //
        this.updateCameraData_(camera);
        this.updateCloseButton_(camera);
        //
        this.updateScreenOrientation_();
        //
        this.enterFullscreen_(myCamera);

        /*
        MyFullscreen.request(myCamera);
        //
        myCamera.object = this;
        //
        MyFullscreen.onChangeSet(myCamera, MyReactCamera.onChangeFullscreen_);
        */
    }

    enterFullscreen_(myCamera) {
        //
        MyFullscreen.request(myCamera);
        //
        myCamera.object = this;
        //
        MyFullscreen.onChangeSet(myCamera, MyReactCamera.onChangeFullscreen_);
    }
    static exitFullscreen_(myCamera) {
        //
        MyFullscreen.onChangeRemove(myCamera, MyReactCamera.onChangeFullscreen_);
        //
        let myReactCamera = myCamera.object;
        //
        myReactCamera.hide(false);
    }
    static onChangeFullscreen_(e) {
        //
        console.log('MyReactCamera.onChangeFullscreen_()...');
        console.log('  isFullscreen = ' + MyFullscreen.isOn());
        console.log('  e = ', e);
        console.log('  e.target = ', e.target);

        if (MyFullscreen.isOn()) // starting/showing cmera
            return;

        let myCamera = e.target;
        //
        MyReactCamera.exitFullscreen_(myCamera);

        /*
        let myReactCamera = e.target.object;

        let myCamera = e.target;
        //
        myCamera.object = null;
        //
        MyFullscreen.onChangeRemove(myCamera, MyReactCamera.onChangeFullscreen_);

        console.log('  myReactCamera = ', myReactCamera);

        myReactCamera.hide(false);
        */
    }

    componentDidMount() {
        //
        console.log('MyReactCamera.componentDidMount()...');
        //
        this.updateUI_();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //
        console.log('MyReactCamera.componentDidUpdate()...');
        //
        this.updateUI_();
    }
    __componentDidUnmount() {
        //
        console.log('MyReactCamera.componentDidUnmount()...');
        //
        const { visible } = this.state;
        //
        console.log('   visible = ' + visible);
        //
        if (!visible)
            return;
    }
    __componentWillUnmount() {
        //
        console.log('MyReactCamera.componentWillUnmount()...');
        //
        const { visible } = this.state;
        //
        console.log('   visible = ' + visible);
        //
        if (!visible)
            return;
    }

    render() {
        //
        console.log('MyReactCamera.render()...');

        const { visible } = this.state;
        //
        if (!visible)
            return null;

        console.log('   visible = ' + visible);
        console.log('   camera_ = ', this.camera_);

        return (
            <div id="my-camera" className="my-camera">

                <Camera
                    onTakePhoto={(dataUri) => { this.handleTakePhoto_(dataUri); }}
                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                    onCameraError={(error) => { handleCameraError(error); }}
                    idealFacingMode={this.state.idealFacingMode}
                    idealResolution={this.state.idealResolution}
                    imageType={this.state.imageType}
                    imageCompression={this.state.imageCompression}
                    isMaxResolution={this.state.isMaxResolution}
                    isImageMirror={false}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={this.state.isFullscreen}
                    sizeFactor={1}
                    onCameraStart={(stream) => { this.handleCameraStart_(stream); }}
                    onCameraStop={() => { this.handleCameraStop_(); }}
                />

            </div>
        );

        return (
            //<div id="my-camera" className="my-camera" style={{ display: "none" }}>
            <div id="my-camera" className="my-camera">

                <Camera
                    onTakePhoto={(dataUri) => { this.handleTakePhoto_(dataUri); }}
                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                    onCameraError={(error) => { handleCameraError(error); }}
                    idealFacingMode={this.state.idealFacingMode}
                    idealResolution={this.state.idealResolution}
                    imageType={this.state.imageType}
                    imageCompression={this.state.imageCompression}
                    isMaxResolution={this.state.isMaxResolution}
                    isImageMirror={false}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={this.state.isFullscreen}
                    sizeFactor={1}
                    onCameraStart={(stream) => { handleCameraStart(stream); }}
                    onCameraStop={() => { handleCameraStop(); }}
                />

            </div>
        );

        return (
            <div ref={this.main_} id="my-camera" className="my-camera">

                <Camera
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                    onCameraError={(error) => { handleCameraError(error); }}
                    idealFacingMode={this.state.idealFacingMode}
                    idealResolution={this.state.idealResolution}
                    imageType={this.state.imageType}
                    imageCompression={this.state.imageCompression}
                    isMaxResolution={this.state.isMaxResolution}
                    isImageMirror={false}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={this.state.isFullscreen}
                    sizeFactor={1}
                    onCameraStart={(stream) => { handleCameraStart(stream); }}
                    onCameraStop={() => { handleCameraStop(); }}
                />

                <table className="cameraTableData">
                    <tr>
                        <td className="cameraTextAngle">Alpha</td>
                        <td id="cameraValueAlpha" className="cameraValueAngle">-</td>
                    </tr>
                    <tr>
                        <td className="cameraTextAngle">Beta</td>
                        <td id="cameraValueBeta" className="cameraValueAngle">-</td>
                    </tr>
                    <tr>
                        <td className="cameraTextAngle">Alpha</td>
                        <td id="cameraValueGamma" className="cameraValueAngle">-</td>
                    </tr>
                </table>

            </div>
        );

        return (
            <Camera
                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                onTakePhotoAnimationDone={(dataUri) => { handleTakePhotoAnimationDone(dataUri); }}
                onCameraError={(error) => { handleCameraError(error); }}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                idealResolution={{ width: 640, height: 480 }}
                imageType={IMAGE_TYPES.JPG}
                imageCompression={0.97}
                isMaxResolution={true}
                isImageMirror={false}
                isSilentMode={false}
                isDisplayStartCameraError={true}
                isFullscreen={false}
                sizeFactor={1}
                onCameraStart={(stream) => { handleCameraStart(stream); }}
                onCameraStop={() => { handleCameraStop(); }}
            />
        );
    }

    static r2s_(num, fix) {
        //
        if (!num)
            return '';
        //
        var str = num.toLocaleString(undefined, {
            useGrouping: true,
            minimumFractionDigits: fix,
            maximumFractionDigits: fix
        });
        //
        return str;
    }

    getOrientationIdx_(orientation) {
        //
        if (orientation === "portrait-primary")
            return 1;

        if (orientation === "portrait-secondary")
            return 2;

        if (orientation === "landscape-primary")
            return 3;

        if (orientation === "landscape-secondary")
            return 4;

        return -1;
    }
    getOrientationStr_(orientation) {
        //
        if (orientation === "portrait-primary")
            return 'P-P';

        if (orientation === "portrait-secondary")
            return 'P-S';

        if (orientation === "landscape-primary")
            return 'L-P';

        if (orientation === "landscape-secondary")
            return 'L-S';

        return 'N/A';

        /*
        if (orientation === "landscape-primary") {
            console.log("That looks good.");
        } else if (orientation === "landscape-secondary") {
            console.log("Mmmh... the screen is upside down!");
        } else if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
            console.log("Mmmh... you should rotate your device to landscape");
        } else if (orientation === undefined) {
            console.log("The orientation API isn't supported in this browser :(");
        }
        */
    }

    updateScreenOrientation_() {
        //
        const screen = window.screen;
        //
        var orientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
        //
        this.currentScreen_ = {
            //
            orientation: this.getOrientationIdx_(orientation),
        };
        //
        this.screenValueOrientation.innerHTML = this.getOrientationStr_(orientation);
    }

    onDevicePosition_(pos) {
        //
        /*
        console.log('onDevicePosition_: this = ', this);
        console.log('onDevicePosition_: pos  = ', pos);
        /**/
        //
        if (this.gpsValueHeading) {
            //
            this.gpsValueHeading.innerHTML = MyReactCamera.r2s_(pos.coords.heading, 2);
        }
        //
        if (this.gpsValueAltitude) {
            //
            this.gpsValueAltitude.innerHTML = MyReactCamera.r2s_(pos.coords.altitude, 2);
            this.gpsValueAltitudeAccuracy.innerHTML = MyReactCamera.r2s_(pos.coords.altitudeAccuracy, 2);
        }
        //
        this.currentPosition_ = {
            //
            heading: pos.coords.heading,
            altitude: pos.coords.altitude,
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            accuracy: pos.coords.accuracy,
            altitudeAccuracy: pos.coords.altitudeAccuracy,
        };
        //
        this.gpsValueLatitude.innerHTML = MyReactCamera.r2s_(pos.coords.latitude, 5);
        this.gpsValueLongitude.innerHTML = MyReactCamera.r2s_(pos.coords.longitude, 5);
        this.gpsValueAccuracy.innerHTML = MyReactCamera.r2s_(pos.coords.accuracy, 2);
        //
        this.updateScreenOrientation_();
    }
    onDeviceOrientation_(e) {
        //
        //alert('onDeviceOrientation_: ' + this.orientationOk_);
        //
        /*
        console.log('onDeviceOrientation_: this = ', this);
        console.log('onDeviceOrientation_: e    = ', e);
        /**/
        //
        var absolute = e.absolute;
        var alpha = e.alpha;
        var beta = e.beta;
        var gamma = e.gamma;
        //
        if (!alpha || !beta | !gamma) {
            //
            //console.log('onDeviceOrientation_: ok = FALSE');
            //
            this.cameraValueAbsolute.innerHTML = '-';
            //
            this.cameraValueAlpha.innerHTML = '-';
            this.cameraValueBeta.innerHTML = '-';
            this.cameraValueGamma.innerHTML = '-';
        }
        else {
            //
            //console.log('onDeviceOrientation_: ok = TRUE');
            //
            this.currentOrientaton_ = {
                absolute: absolute,
                alpha: alpha,
                beta: beta,
                gamma: gamma,
            };
            //
            if (absolute)
                this.cameraValueAbsolute.innerHTML = '(absolute)';
            else
                this.cameraValueAbsolute.innerHTML = '(relative)';
            //
            this.cameraValueAlpha.innerHTML = alpha.toFixed(2);
            this.cameraValueBeta.innerHTML = beta.toFixed(2);
            this.cameraValueGamma.innerHTML = gamma.toFixed(2);
        }
        //
        this.updateScreenOrientation_();
    }
    onScreenOrientation_(e) {
        //
        //*
        console.log('MyReactCamera.onScreenOrientation_()...');
        console.log('   screen.orientation = ' + window.screen.orientation);
        /**/
        this.updateScreenOrientation_();
    }

    isVisible() {
        //
        const { visible } = this.state;
        //
        return visible;
    }

    show() {
        //
        console.log('MyReactCamera.show()...');
        //
        //alert('show: ' + this.orientationOk_);
        //
        //this.myCamera_.style.display = "block";
        //
        this.setState({
            visible: true
        });
        //
        if (this.positionOk_) {
            //
            const options = {
                enableHighAccuracy: true,
                maximumAge: 0,
                //timeout: 0,
            };
            //
            function onPositionError_(error) {
                //
                console.log('MyReactCamera.onPositionError_()...')
                //
                if (error.code == 1) {
                    alert("Error: Access is denied!");
                }
                else if (error.code == 2) {
                    alert("Error: Position is unavailable!");
                }
            }
            //
            this.watchID_ = navigator.geolocation.watchPosition(this.handleDevicePosition_, onPositionError_, options);
        }

        if (this.orientationOk_) {
            //
            window.addEventListener('deviceorientationabsolute', this.handleDeviceOrientation_);
            //window.addEventListener('deviceorientation', this.handleDeviceOrientation_);
        }

        //window.screen.addEventListener("orientationchange", this.handleScreenOrientation_);

        console.log('MyReactCamera.show(): this.video_ = ', this.video_)

        //this.video_.play();

        //this.camera_.setIsShowVideo(true); // start video
    }
    hide(doExitFullscreen = true) {
        //
        console.log('MyReactCamera.hide()...');
        //
        console.log('MyReactCamera.hide()...');
        console.log('  this.video_  = ', this.video_);
        console.log('  this.stream_ = ', this.stream_);
        //
        if (doExitFullscreen)
            MyFullscreen.exit();

        //this.video_.stop();

        //this.camera_.setIsShowVideo(false); // Stop video

        if (this.positionOk_) {
            //
            navigator.geolocation.clearWatch(this.watchID_);
        }

        if (this.orientationOk_) {
            //
            window.removeEventListener('deviceorientationabsolute', this.handleDeviceOrientation_);
            //window.removeEventListener('deviceorientation', this.handleDeviceOrientation_);
        }

        //window.screen.removeEventListener("orientationchange", this.handleScreenOrientation_);
        //
        this.setState({
            visible: false
        });

    }

    handleCameraStart_(stream) {
        //
        console.log('MyReactCamera.onCameraStart()...');
        console.log('  stream = ', stream);
        //
        this.stream_ = stream;

    }
    handleCameraStop_() {
        //
        console.log('MyReactCamera.handleCameraStop_()...');
        console.log('  this.stream_ = ', this.stream_);
        //
        this.stream_ = null;
    }

    async handleTakePhoto_(dataUri) {

        function dataURItoBlob(dataURI) {
            //
            let byteString = atob(dataURI.split(',')[1]);

            // separate out the mime component
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            let ab = new ArrayBuffer(byteString.length);
            let ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            let blob = new Blob([ab], { type: mimeString });
            //
            return blob;
        }

        function padWithZeroNumber(number, width) {
            number = number + '';
            return number.length >= width
                ? number
                : new Array(width - number.length + 1).join('0') + number;
        }

        function getFileExtention(blobType) {
            // by default the extention is .png
            let extention = IMAGE_TYPES.PNG;

            if (blobType === 'image/jpeg') {
                extention = IMAGE_TYPES.JPG;
            }
            return extention;
        }

        function ___getFileName(imageNumber, blobType) {
            const prefix = 'photo';
            const photoNumber = padWithZeroNumber(imageNumber, 4);
            const extention = getFileExtention(blobType);

            return `${prefix}-${photoNumber}.${extention}`;
        }

        function getDateTime() {
            //
            const zeroPad = (num, size) => String(num).padStart(size, '0')

            var now = new Date();
            //
            var str = '';
            //
            str += zeroPad(now.getFullYear(), 4);
            str += '.'
            str += zeroPad(now.getMonth() + 1, 2);
            str += '.'
            str += zeroPad(now.getDate(), 2);
            //
            str += '-'
            //
            str += zeroPad(now.getHours(), 2);
            str += '.'
            str += zeroPad(now.getMinutes(), 2);
            str += '.'
            str += zeroPad(now.getSeconds(), 2);
            //
            return str;
        }

        function getFileName(imageNumber, blobType) {
            const prefix = 'MPT';
            const date = getDateTime();
            const extension = getFileExtention(blobType);

            return `${prefix}-${date}.${extension}`;
        }

        function downloadImageFileFomBlob(blob, imageNumber) {

            window.URL = window.webkitURL || window.URL;

            let anchor = document.createElement('a');
            anchor.download = getFileName(imageNumber, blob.type);
            anchor.href = window.URL.createObjectURL(blob);
            let mouseEvent = document.createEvent('MouseEvents');
            mouseEvent.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
            anchor.dispatchEvent(mouseEvent);
        }

        function __insertExifData(blob) {
            //
            console.log('insertExifData(): blob = ', blob);

            var zeroth = {};
            var exif = {};
            var gps = {};
            //
            zeroth[piexif.ImageIFD.Make] = "Make";
            zeroth[piexif.ImageIFD.XResolution] = [777, 1];
            zeroth[piexif.ImageIFD.YResolution] = [777, 1];
            zeroth[piexif.ImageIFD.Software] = "Piexifjs";
            exif[piexif.ExifIFD.DateTimeOriginal] = "2010:10:10 10:10:10";
            exif[piexif.ExifIFD.LensMake] = "LensMake";
            exif[piexif.ExifIFD.Sharpness] = 777;
            exif[piexif.ExifIFD.LensSpecification] = [[1, 1], [1, 1], [1, 1], [1, 1]];
            gps[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
            gps[piexif.GPSIFD.GPSDateStamp] = "1999:99:99 99:99:99";
            //
            var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
            var exifStr = piexif.dump(exifObj);

            console.log('insertExifData(): exifStr = ', exifStr);

            let reader = new FileReader();

            let buffer = blob.ArrayBuffer;
            //
            //console.log('insertExifData(): buffer = ', buffer);

            var new_blob = null;

            var exifModified = piexif.insert(exifStr, blob);

            return new Blob();

            /*
            reader.onloadend = function (e) {
            reader.onload = function (e) {
            */
            reader.onloadend = function (e) {

                console.log('insertExifData(): AAAAAAAAAAAAAAA');

                var exifModified = piexif.insert(exifStr, e.target.result)

                var jpegBinary = atob(exifModified.split(",")[1]);

                var data = [];
                //
                for (var p = 0; p < jpegBinary.length; p++) {
                    data[p] = jpegBinary.charCodeAt(p);
                }
                //
                var ua = new Uint8Array(data);
                //
                new_blob = new Blob([ua], { type: "image/jpeg" });
                //
                console.log('insertExifData(): new_blob = ', new_blob);
                //
                return new_blob;
            }

            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
            //
            console.log('insertExifData(): new_blob = ', new_blob);
            //
            return new_blob;
        }

        function newExifString(currentPosition, currentOrientaton, currentScreen) {
            //
            var zeroth = {};
            var exif = {};
            var gps = {};
            //
            var user_comment = [];
            //
            user_comment.push("HR_Version=1.0");
            //
            if (currentPosition) {
                //
                if (currentPosition.heading)
                    user_comment.push("GpsHeading=" + currentPosition.heading);
                //
                if (currentPosition.altitude)
                    user_comment.push("GpsAltitude=" + currentPosition.altitude);
                //
                if (currentPosition.altitudeAccuracy)
                    user_comment.push("GpsAltitudeAccuracy=" + currentPosition.altitudeAccuracy);
                //
                user_comment.push("GpsLatitude=" + currentPosition.latitude);
                user_comment.push("GpsLongitude=" + currentPosition.longitude);
                user_comment.push("GpsAccuracy=" + currentPosition.accuracy);
            }
            //
            if (currentOrientaton) {
                //
                if (currentOrientaton.absolute)
                    user_comment.push("CameraAbsolute=1");
                else
                    user_comment.push("CameraAbsolute=0");
                //
                user_comment.push("CameraAlpha=" + currentOrientaton.alpha);
                user_comment.push("CameraBeta=" + currentOrientaton.beta);
                user_comment.push("CameraGamma=" + currentOrientaton.gamma);
            }
            //
            if (currentScreen){
                //
                user_comment.push("ScreenOrientation=" + currentScreen.orientation);
            }
            //
            exif[piexif.ExifIFD.UserComment] = user_comment;
            //

            // Don't add standard GPS data: to verify if this informations
            // are provided by the camera itself
            //
            let do_standard_GPS = false;
            //
            if (do_standard_GPS) {
                //
                // Standard GPS data
                const latitude = currentPosition.latitude;
                const longitude = currentPosition.longitude;
                //
                gps[piexif.GPSIFD.GPSLatitudeRef] = latitude < 0 ? 'S' : 'N';
                gps[piexif.GPSIFD.GPSLatitude] = piexif.GPSHelper.degToDmsRational(latitude);
                gps[piexif.GPSIFD.GPSLongitudeRef] = longitude < 0 ? 'W' : 'E';
                gps[piexif.GPSIFD.GPSLongitude] = piexif.GPSHelper.degToDmsRational(longitude);
            }

            /*
            exif[piexif.ExifIFD.UserComment] = [
                //
                "CameraAlpha=" + this.currentOrientaton_.alpha,
                "CameraBeta=" + this.currentOrientaton_.beta,
                "CameraGamma=" + this.currentOrientaton_.gamma,
                "CameraLatitude=" + this.currentPosition_.latitude,
                "CameraLongitude=" + this.currentPosition_.longitude,
            ];
            */
            //
            var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
            //
            var exifStr = piexif.dump(exifObj);
            //
            return exifStr;

            /*
            zeroth[piexif.ImageIFD.Make] = "Make";
            zeroth[piexif.ImageIFD.XResolution] = [777, 1];
            zeroth[piexif.ImageIFD.YResolution] = [777, 1];
            zeroth[piexif.ImageIFD.Software] = "Piexifjs";
            exif[piexif.ExifIFD.DateTimeOriginal] = "2010:10:10 10:10:10";
            exif[piexif.ExifIFD.LensMake] = "LensMake";
            exif[piexif.ExifIFD.Sharpness] = 777;
            exif[piexif.ExifIFD.LensSpecification] = [[1, 1], [1, 1], [1, 1], [1, 1]];
            //
            //
            gps[piexif.GPSIFD.GPSVersionID] = [7, 7, 7, 7];
            gps[piexif.GPSIFD.GPSDateStamp] = "1999:99:99 99:99:99";
            gps[piexif.GPSIFD.GPSImgDirectionRef] = 123.45678;
 
            //
            var exifObj = { "0th": zeroth, "Exif": exif, "GPS": gps };
            //
            var exifStr = piexif.dump(exifObj);
            //
            return exifStr;
            */
        }

        function insertExifData(blob, currentPosition, currentOrientaton, currentScreen) {
            //
            return new Promise((resolve, reject) => {

                let exifStr = newExifString(currentPosition, currentOrientaton, currentScreen);

                let reader = new FileReader();

                reader.onloadend = function (e) {

                    console.log('insertExifData()...');
                    console.log('  exifStr = ' + exifStr);

                    var exifModified = piexif.insert(exifStr, e.target.result)

                    var jpegBinary = atob(exifModified.split(",")[1]);

                    var data = [];
                    //
                    for (var p = 0; p < jpegBinary.length; p++) {
                        data[p] = jpegBinary.charCodeAt(p);
                    }
                    //
                    var ua = new Uint8Array(data);
                    //
                    let new_blob = new Blob([ua], { type: "image/jpeg" });
                    //
                    console.log('insertExifData(): new_blob = ', new_blob);
                    //
                    resolve(new_blob);
                }

                reader.onerror = reject;

                reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
            })
        }

        function showExifData(blob) {
            //
            console.log('showExifData(): blob = ', blob);
            //
            let reader = new FileReader();

            //reader.onloadend = function (e) {
            reader.onload = function (e) {

                console.log('showExifData().onloadend(): e.target.result = ', e.target.result);

                var exifObj = piexif.load(e.target.result);

                for (var ifd in exifObj) {
                    //
                    if (ifd === "thumbnail") {
                        continue;
                    }

                    console.log("-" + ifd);

                    for (var tag in exifObj[ifd]) {
                        console.log("  " + piexif.TAGS[ifd][tag]["name"] + ":" + exifObj[ifd][tag]);
                    }
                }
            }

            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
        }

        // Save photo...
        console.log('MyReactCamera.handleTakePhoto_()...');
        console.log('  dataUri = ', dataUri);

        let blob = dataURItoBlob(dataUri);
        //
        console.log('  blob = ', blob);
        //
        showExifData(blob);

        let new_blob = await insertExifData(blob, this.currentPosition_, this.currentOrientaton_, this.currentScreen_);
        //
        console.log('  new_blob = ', new_blob);
        //
        showExifData(new_blob);

        let number = this.state.imageNumber;
        //
        downloadImageFileFomBlob(new_blob, number);
        //
        this.setState({ imageNumber: number + 1 });
    }
}

export default MyReactCamera;