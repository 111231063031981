import './FileControl.css';

import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

////////////////////////////////////////
import { getWidth } from 'ol/extent';
import { Fill, Stroke, Style, Text } from 'ol/style';
import Point from 'ol/geom/Point';
import { fromExtent } from 'ol/geom/Polygon';
////////////////////////////////////////

import GeoJSON from 'ol/format/GeoJSON';
import KML from 'ol/format/KML';
import GPX from 'ol/format/GPX';

import { Control } from 'ol/control';
import { Select } from 'ol/interaction';

import Projection from "../utils/Projection";
//
import Attr_TOPO from "../utils/Attr_TOPO";

import imageExport from './icons/File-Export.svg'
import imageImport from './icons/File-Import.svg'
import { asArray } from 'ol/color';

export default class FileControl extends Control {

    constructor(opt_options) {

        var options = opt_options || {};

        var element = document.createElement('div');
        //
        element.className = 'file ol-unselectable ol-control';

        super({ element: element, target: options.target });

        var btn_import = document.createElement('input');
        //
        btn_import.className = 'file-button-import file-button';
        btn_import.title = 'Import data';
        btn_import.type = 'image';
        btn_import.src = imageImport;
        //
        btn_import.addEventListener('click', this.onFileImport.bind(this), false);
        //
        this.btnImport_ = btn_import;

        var btn_export = document.createElement('input');
        //
        btn_export.className = 'file-button-export file-button';
        btn_export.title = 'Export data';
        btn_export.type = 'image';
        btn_export.src = imageExport;
        //
        btn_export.addEventListener('click', this.onFileExport_.bind(this), false);
        //
        this.btnExport_ = btn_export;

        element.appendChild(btn_import);
        element.appendChild(btn_export);

        // Menu file import/export
        this.menuFileImport_ = this.newMenuFileImport_();
        this.menuFileExport_ = this.newMenuFileExport_();
        //
        this.menuFileImport_.style.display = "none";
        this.menuFileExport_.style.display = "none";
        //
        element.appendChild(this.menuFileImport_);
        element.appendChild(this.menuFileExport_);
    }

    newMenuFileImport__(div, pixel) {
        //
        //------------------------------------------------------------
        // Import pixel coordinates
        //------------------------------------------------------------
        var title = document.createElement("text");
        //
        title.className = 'menu-file-title';
        //
        if (pixel)
            title.innerHTML = "Import pixel coordinates";
        else
            title.innerHTML = "Import world coordinates";
        //
        div.appendChild(title);
        //------------------------------------------------------------
        // Import JSON
        //------------------------------------------------------------
        var input_json = document.createElement("input");
        //
        input_json.className = 'menu-file-button';
        //
        input_json.type = "button";
        input_json.value = "Import JSON...";
        //
        if (pixel)
            input_json.addEventListener('click', this.onMenuFileImport_JSON_pix_.bind(this), false);
        else
            input_json.addEventListener('click', this.onMenuFileImport_JSON_world_.bind(this), false);
        //
        div.appendChild(input_json);

        //------------------------------------------------------------
        // Pixel import/export for KML and GPX doesn't make sense...
        //------------------------------------------------------------
        if (pixel)
            return;
        //------------------------------------------------------------

        //------------------------------------------------------------
        // Import KML
        //------------------------------------------------------------
        var input_kml = document.createElement("input");
        //
        input_kml.className = 'menu-file-button';
        //
        input_kml.type = "button";
        input_kml.value = "Import KML...";
        //
        if (pixel)
            input_kml.addEventListener('click', this.onMenuFileImport_KML_pix_.bind(this), false);
        else
            input_kml.addEventListener('click', this.onMenuFileImport_KML_world_.bind(this), false);
        //
        div.appendChild(input_kml);
        //------------------------------------------------------------
        // Import GPX
        //------------------------------------------------------------
        var input_gpx = document.createElement("input");
        //
        input_gpx.className = 'menu-file-button';
        //
        input_gpx.type = "button";
        input_gpx.value = "Import GPX...";
        //
        if (pixel)
            input_gpx.addEventListener('click', this.onMenuFileImport_GPX_pix_.bind(this), false);
        else
            input_gpx.addEventListener('click', this.onMenuFileImport_GPX_world_.bind(this), false);
        //
        div.appendChild(input_gpx);
        //------------------------------------------------------------
    }
    newMenuFileExport__(div, pixel) {
        //
        //------------------------------------------------------------
        // Export pixel coordinates
        //------------------------------------------------------------
        var title = document.createElement("text");
        //
        title.className = 'menu-file-title';
        //
        if (pixel)
            title.innerHTML = "Export pixel coordinates";
        else
            title.innerHTML = "Export world coordinates";
        //
        div.appendChild(title);
        //------------------------------------------------------------
        // Export JSON
        //------------------------------------------------------------
        var input_json = document.createElement("input");
        //
        input_json.className = 'menu-file-button';
        //
        input_json.type = "button";
        input_json.value = "Export JSON...";
        //
        if (pixel)
            input_json.addEventListener('click', this.onMenuFileExport_JSON_pix_.bind(this), false);
        else
            input_json.addEventListener('click', this.onMenuFileExport_JSON_world_.bind(this), false);
        //
        div.appendChild(input_json);

        //------------------------------------------------------------
        // Pixel import/export for KML and GPX doesn't make sense...
        //------------------------------------------------------------
        if (pixel)
            return;
        //------------------------------------------------------------

        //------------------------------------------------------------
        // Import KML
        //------------------------------------------------------------
        var input_kml = document.createElement("input");
        //
        input_kml.className = 'menu-file-button';
        //
        input_kml.type = "button";
        input_kml.value = "Export KML...";
        //
        if (pixel)
            input_kml.addEventListener('click', this.onMenuFileExport_KML_pix_.bind(this), false);
        else
            input_kml.addEventListener('click', this.onMenuFileExport_KML_world_.bind(this), false);
        //
        div.appendChild(input_kml);
        //------------------------------------------------------------
        // Import GPX
        //------------------------------------------------------------
        var input_gpx = document.createElement("input");
        //
        input_gpx.className = 'menu-file-button';
        //
        input_gpx.type = "button";
        input_gpx.value = "Export GPX...";
        //
        if (pixel)
            input_gpx.addEventListener('click', this.onMenuFileExport_GPX_pix_.bind(this), false);
        else
            input_gpx.addEventListener('click', this.onMenuFileExport_GPX_world_.bind(this), false);
        //
        div.appendChild(input_gpx);
        //------------------------------------------------------------
    }

    newMenuFileImport_(pixel) {
        //
        let div = document.createElement("div");
        //
        div.className = 'menu-file menu-file-import';
        //
        this.newMenuFileImport__(div, true);
        this.newMenuFileImport__(div, false);
        //
        return div;
    }
    newMenuFileExport_(pixel) {
        //
        let div = document.createElement("div");
        //
        div.className = 'menu-file menu-file-export';
        //
        this.newMenuFileExport__(div, true);
        this.newMenuFileExport__(div, false);
        //
        return div;
    }

    // Import button
    onFileImport(e) {
        //
        console.log('FileControl.onFileImport');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        this.menuFileExport_.style.display = "none";
        this.menuFileImport_.style.display = "block";

        let menuFileImport = this.menuFileImport_;
        //
        let btnImport = this.btnImport_;
        //
        window.onclick = function (e) {
            //
            //*
            console.log('FileControl.onFileImport().window.onclick(): e = ', e);
            console.log('FileControl.onFileImport().window.onclick(): e.target = ', e.target);
            /**/
            //
            if (e.target !== btnImport) {
                //
                //e.preventDefault();
                //
                menuFileImport.style.display = "none";
            }
        }

        return;

        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = '.json;.geojson';
        //fileSelector.accept = '.json;.geojson;.kml;.gpx';
        //
        fileSelector.map = this.getMap();
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImport_;
        //
        fileSelector.click();
    };

    // Export button
    onFileExport_(e) {

        console.log('FileControl.onFileExport');
        //
        if (!this.getMap().ok(true)) {
            //
            return;
        }

        /*
        var vector = this.getMap().getForeground();
        //
        var features = vector.getSource().getFeatures();
        //
        if (features.length === 0) {
            //
            alert('Warning: no features found!');
            //
            return;
        }
        */

        this.menuFileImport_.style.display = "none";
        this.menuFileExport_.style.display = "block";

        let menuFileExport = this.menuFileExport_;
        //
        let btnExport = this.btnExport_;
        //
        window.onclick = function (e) {
            //
            //*
            console.log('FileControl.onFileExport().window.onclick(): e = ', e);
            console.log('FileControl.onFileExport().window.onclick(): e.target = ', e.target);
            /**/
            //
            if (e.target !== btnExport) {
                //
                //e.preventDefault();
                //
                menuFileExport.style.display = "none";
            }
        }

        return;

        var vector = this.getMap().getForeground();
        //
        var features = vector.getSource().getFeatures();

        if (features.length === 0) {
            alert('Warning: no features found!');
            return;
        }

        const version = 1.0;

        var geojson = new GeoJSON();
        //
        var data = geojson.writeFeatures(features);

        const action = 'transformFeatures_i2w';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        socket.socket.on(action, this.onSocketExportFeatures_);
        //
        const message = {
            version: version,
            uuid: socket.uuid(),
            data: data,
        };
        //
        socket.socket.emit(action, message);
    };

    // Menu import callbacks
    onMenuFileImport_JSON_pix_() {
        //
        console.log('FileControl.onMenuFileImport_JSON_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new GeoJSON();
        //
        this.fileImportPixel_(reader, options, '.json,.geojson');
    }
    onMenuFileImport_JSON_world_() {
        //
        console.log('FileControl.onMenuFileImport_JSON_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new GeoJSON();
        //
        this.fileImportWorld_(reader, options, '.json,.geojson');
    }
    onMenuFileImport_KML_pix_() {
        //
        console.log('FileControl.onMenuFileImport_KML_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new KML();
        //
        this.fileImportPixel_(reader, options, '.kml');
    }
    onMenuFileImport_KML_world_() {
        //
        console.log('FileControl.onMenuFileImport_KML_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new KML();
        //
        this.fileImportWorld_(reader, options, '.kml');
    }
    onMenuFileImport_GPX_pix_() {
        //
        console.log('FileControl.onMenuFileImport_GPX_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const reader = new GPX();
        //
        this.fileImportPixel_(reader, options, '.gpx');
    }
    onMenuFileImport_GPX_world_() {
        //
        console.log('FileControl.onMenuFileImport_GPX_world_()...');
        //
        var options = {
            //
            //dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326',
        }
        //
        const reader = new GPX();
        //
        this.fileImportWorld_(reader, options, '.gpx');
    }

    // Menu export callbacks
    onMenuFileExport_JSON_pix_() {
        //
        console.log('FileControl.onMenuFileExport_JSON_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new GeoJSON();
        //
        this.fileExportPixel_(writer, options, '.json');
    }
    onMenuFileExport_KML_pix_() {
        //
        console.log('FileControl.onMenuFileExport_KML_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new KML();
        //
        this.fileExportPixel_(writer, options, '.kml');
    }
    onMenuFileExport_GPX_pix_() {
        //
        console.log('FileControl.onMenuFileExport_GPX_pix_()...');
        //
        var options = {
            //
            //dataProjection: 'PIXEL',
            featureProjection: 'PIXEL',
        }
        //
        const writer = new GPX();
        //
        this.fileExportPixel_(writer, options, '.gpx');
    }
    onMenuFileExport_JSON_world_() {
        //
        console.log('FileControl.onMenuFileExport_JSON_world_()...');
        //
        this.fileExportWorld_('json');
    }
    onMenuFileExport_KML_world_() {
        //
        console.log('FileControl.onMenuFileExport_KML_world_()...');
        //
        this.fileExportWorld_('kml');
    }
    onMenuFileExport_GPX_world_() {
        //
        console.log('FileControl.onMenuFileExport_GPX_world_()...');
        //
        this.fileExportWorld_('gpx');
    }

    // Utilities: get file extension
    static getFileExtension_(filename) {
        //
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    // Utilities: get visibility from file
    static getVisibility_(data) {
        //
        let str = data.substr(0, 100);

        console.log('FFFF str = ', str);

        /*
        var _regex = new RegExp("([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/g);([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)", 'g');
        var _regex = /([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/g​;
        */

        let idx = str.search('"visibility"');
        //
        if (idx < 0)
            return undefined;
        //
        str = str.substr(idx + 1);

        idx = str.search(':');
        //
        if (idx < 0)
            return undefined;
        //
        str = str.substr(idx + 1);

        let visibility = parseFloat(str);
        //
        return visibility;
    }

    // Pixel import
    fileImportPixel_(reader, options, file_ext) {
        //
        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = file_ext;
        //
        fileSelector.map = this.getMap();
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImportPixel_;
        //
        fileSelector.click();
    }
    handleFileImportPixel_(e) {
        //
        console.log('FileControl.handleFileImportPixel_()...');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }
        //
        console.log('  file.name = ' + file.name);

        const file_ext = FileControl.getFileExtension_(file.name);
        //
        var geo_reader = null;
        //
        if ((file_ext === 'json') || (file_ext === 'geojson'))
            geo_reader = new GeoJSON();
        else if (file_ext === 'kml')
            geo_reader = new KML();
        else if (file_ext === 'gpx')
            geo_reader = new GPX();
        else {
            //
            alert('Error reading from: ' + file.name + ': unknown file type!');
            //
            return;
        }

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;

        const file_reader = new FileReader();
        //
        file_reader.onload = function (e) {

            var data = file_reader.result;
            //
            console.log('EEEE data = ', data);
            console.log('EEEE data.type = ', data.type);

            // Get features
            var features = null;
            //
            try {
                features = geo_reader.readFeatures(data);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            // Get visibility (not very elegant)
            function ___getVisibility_() {
                //
                let str = data.substr(0, 100);

                console.log('FFFF str = ', str);

                /*
                var _regex = new RegExp("([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/g);([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)", 'g');
                var _regex = /([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/g​;
                */

                let idx = str.search('"visibility"');
                //
                if (idx < 0)
                    return undefined;
                //
                str = str.substr(idx + 1);

                idx = str.search(':');
                //
                if (idx < 0)
                    return undefined;
                //
                str = str.substr(idx + 1);

                let visibility = parseFloat(str);
                //
                return visibility;
            }

            // Get visibility
            let visibility = FileControl.getVisibility_(data);

            // Check if it is a TOPO file
            Attr_TOPO.setStyle(features, visibility);

            // Source
            let source = new VectorSource({
                features: features
            });

            let ___style = function (feature, resolution) {
                //
                let style = feature.getStyle();
                //

                console.log('!!!!!!!!!!!!!!! STYLE = ', style);

                if (feature.getGeometry().getType() === 'MultiPolygon') {
                    //
                    function getGeometry_(polygons) {
                        //
                        var objects = [];

                        // Find which one is the greater and so label only this
                        for (let i = 0; i < polygons.length; i++) {
                            //
                            objects.push({
                                poly: polygons[i],
                                area: polygons[i].getArea()
                            });
                        }

                        objects.sort(function (p1, p2) {
                            //
                            return (p1.area - p2.area);
                        });

                        return objects[objects.length - 1].poly.getInteriorPoint();
                    }
                    //
                    //style.setg

                } else if (feature.getGeometry().getType() === 'MultiLineString') {
                    //

                }

                /*
                geometry: function(feature) {
                    var retPoint;
                    if (feature.getGeometry().getType() === 'MultiPolygon') {
                        retPoint = getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                    } else if (feature.getGeometry().getType() === 'Polygon') {
                        retPoint = feature.getGeometry().getInteriorPoint();
                */


                return style;
            }
            // Insert as new layer
            var layer = new VectorLayer({
                title: file.name,
                visible: true,
                declutter: true,
                source: source,
            });
            //
            map.insertWorkLayer(layer);
            //map.insertForegroungLayer(layer);

            if (false)
                if (true) {

                    ////////////////////////////////////////////////////////////
                    var labelStyle = new Style({
                        text: new Text({
                            font: '12px Calibri,sans-serif',
                            overflow: true,
                            fill: new Fill({
                                color: '#000'
                            }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 3
                            })
                        })
                    });
                    var countryStyle = new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.6)'
                        }),
                        stroke: new Stroke({
                            color: '#319FD3',
                            width: 1
                        })
                    });
                    var style = [countryStyle, labelStyle];

                    var vectorLayer = new VectorLayer({
                        source: source,
                        style: function (feature) {

                            var geometry = feature.getGeometry();
                            if (geometry.getType() == 'MultiPolygon') {
                                // Only render label for the widest polygon of a multipolygon
                                var polygons = geometry.getPolygons();
                                var widest = 0;
                                for (var i = 0, ii = polygons.length; i < ii; ++i) {
                                    var polygon = polygons[i];
                                    var width = getWidth(polygon.getExtent());
                                    if (width > widest) {
                                        widest = width;
                                        geometry = polygon;
                                    }
                                }
                            }

                            console.log('!!!!!!VectorLayer:style: geometry = ', geometry);

                            // Check if default label position fits in the view and move it inside if necessary
                            geometry = geometry.getInteriorPoint();
                            var size = map.getSize();
                            var extent = map.getView().calculateExtent([size[0] - 12, size[1] - 12]);
                            var textAlign = 'center';
                            var coordinates = geometry.getCoordinates();
                            if (!geometry.intersectsExtent(extent)) {
                                geometry = new Point(fromExtent(extent).getClosestPoint(coordinates));
                                // Align text if at either side
                                var x = geometry.getCoordinates()[0];
                                if (x > coordinates[0]) {
                                    textAlign = 'left';
                                }
                                if (x < coordinates[0]) {
                                    textAlign = 'right';
                                }
                            }

                            labelStyle.setGeometry(geometry);
                            //labelStyle.getText().setText(feature.get('name'));
                            labelStyle.getText().setText('AAAAA');
                            labelStyle.getText().setTextAlign(textAlign);
                            return style;
                        },
                        declutter: true,
                        renderBuffer: 1  // If left at default value labels will appear when countries not visible
                    });
                    //
                    map.insertForegroungLayer(vectorLayer);
                    ////////////////////////////////////////////////////////////
                }
            /*
            //var foreground = map.getLayers().item(1);
            var foreground = map.getForeground();
            //
            foreground.getSource().addFeatures(features);
            */
        }

        if (file) {
            file_reader.readAsText(file);
        }
    }

    // World import
    fileImportWorld_(reader, options, file_ext) {
        //
        var fileSelector = document.createElement('input');
        //
        fileSelector.type = 'file';
        fileSelector.accept = file_ext;
        //
        fileSelector.map = this.getMap();
        //
        fileSelector.reader = reader;
        fileSelector.options = options;
        //
        fileSelector.control = this;
        //
        fileSelector.onchange = this.handleFileImportWorld_;
        //
        fileSelector.click();

    }
    handleFileImportWorld_(e) {
        //
        console.log('FileControl.handleFileImportWorld_()...');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }
        //
        console.log('  file.name = ' + file.name);

        /*
        const file_ext = FileControl.getFileExtension_(file.name);
        //
        var geo_reader = null;
        //
        switch (file_ext) {
            //
            case 'json':
            case 'geojson':
                geo_reader = new GeoJSON();
                break;
            case 'kml':
                geo_reader = new KML();
                break;
            case 'gpx':
                geo_reader = new GPX();
                break;
            default:
                //
                alert('Error reading from: ' + file.name + ': unknown file type!');
                //
                return;
        }
        */

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;
        //
        const control = fileSelector.control;
        //
        const reader = fileSelector.reader;
        const options = fileSelector.options;

        const file_reader = new FileReader();
        //
        file_reader.onload = function (e) {
            //
            console.log('FileControl.handleFileImportWorld_().file_reader.onload...');
            console.log('  e = ', e);

            var data = file_reader.result;
            //
            var features = null;
            //
            try {
                features = reader.readFeatures(data, options);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            const version = 1.0;

            var geojson = new GeoJSON();
            //
            var data = geojson.writeFeatures(features);

            // Set file name
            control.fileName_ = 'AAABBBCCC';

            console.log('FileControl.handleFileImportWorld_().file_reader.onload...');
            console.log('  control    = ', control);

            const action = 'transformFeatures_w2i';
            //
            var socket = map.getSocket(); // SocketConnection
            //
            /*
            console.log('FileControl.handleFileImportWorld_().file_reader.onload...');
            console.log('  map    = ', map);
            console.log('  socket = ', socket);
            */
            socket.getSocket().on(action, control.onSocketFileImportWorld_);
            //
            socket.getSocket().fileName_ = file.name;
            //
            const message = {
                version: version,
                uuid: socket.uuid(),
                epsg: 4326, // default: WGS84
                data: data,
            };
            //
            socket.getSocket().emit(action, message);
        }

        if (file) {
            file_reader.readAsText(file);
        }
    }
    onSocketFileImportWorld_(message) {
        //
        console.log('FileControl.onSocketFileImportWorld_');
        console.log('   this = ', this);

        // Remove callback
        this.off('transformFeatures_w2i');

        // Get file name
        let file_name = this.fileName_;
        //
        this.fileName_ = null;

        // Analyse message
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('FileControl.onSocketFileImport_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Could not import data: unknown error!';
            //
            console.log(msg);
            //
            alert(msg);
            //
            return;
        }

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        /*
        console.log('FileControl.onSocketFileImportWorld_:');
        console.log('      features = ', features);
        */

        // Get visibility
        let visibility = FileControl.getVisibility_(data);

        // Check if it is a TOPO file
        Attr_TOPO.setStyle(features, visibility);

        // Source
        let source = new VectorSource({
            features: features
        });

        // Insert as new layer
        var layer = new VectorLayer({
            //title: file.name,
            title: file_name,
            visible: true,
            declutter: true,
            source: source,
        });
        //
        this.map.insertWorkLayer(layer);

        return;

        {
            // Get visibility
            let visibility = FileControl.getVisibility_(data);

            // Check if it is a TOPO file
            Attr_TOPO.setStyle(features, visibility);

            this.map.insertWorkLayer(layer);

            // Check if it is a TOPO file 
            //Attr_TOPO.setStyle(features);
        }

        /*
        // TEST to check if it is a TOPO file
        if (Attr_TOPO.hasAttr(features)) {
            //
            console.log('FileControl.onSocketFileImportWorld_:');
            console.log('  is TOPO file!');
            //
            Attr_TOPO.setStyle(features);
        }
        /**/

        var foreground = this.map.getLayers().item(1);
        //
        foreground.getSource().addFeatures(features);
    }

    // Utilities: get features to export
    getSelect_() {
        //
        var select = null;
        //
        this.getMap().getInteractions().forEach(function (interaction) {

            if (interaction instanceof Select) {
                //
                select = interaction;
            }
        });
        //
        return select;
    }
    getFeaturesToExport_() {
        //
        console.log('FileControl.getFeaturesToExport_()...');
        //
        var select = this.getSelect_();
        //
        if (!select)
            return null;

        const action_lower = 'export';
        const action_upper = 'Export';

        var features = select.getFeatures();
        //
        console.log('  features = ', features);
        //
        if (!features) {
            //
            alert('No features to ' + action_lower + '!');
            //
            return;
        }
        else if (features.getLength() === 0) {
            //
            let work_layer = this.getMap().getWorkLayer();
            //
            var source = work_layer.getSource();
            /*
            var foreground = this.getMap().getForeground();
            //
            var source = foreground.getSource();
            */
            //
            features = source.getFeatures();
            //
            if (features.length == 0) {
                //
                alert('No features to ' + action_lower + '!');
                //
                return;
            }

            var msg = '';
            //
            msg += 'No features selected: ' + action_lower + ' all features (';
            msg += features.length;
            msg += ')?';
            //
            if (!window.confirm(msg)) {
                //
                return;
            }
        }
        else {

            if (!window.confirm(action_upper + ' the ' + features.getLength() + ' selected feature(s)?')) {
                return null;
            }

            // Convert to array for GeoJSON.writeFeatures()
            var array = [];
            //
            features.forEach(function (feature) {
                //
                array.push(feature);
            });
            //
            features = array;
        }

        console.log('FileControl.getFeatures_: features = ', features);
        //
        return features;
    }

    // Pixel export
    fileExportPixel_(writer, options, file_ext) {
        //
        const features = this.getFeaturesToExport_();
        //
        if (!features)
            return;

        const data = writer.writeFeatures(features, options);
        //
        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data' + file_ext;
        //
        /*
        a.onclick = FileControl.handleFileExport_;
        a.onclose = FileControl.handleFileExport_;
        a.onchange = FileControl.handleFileExport_;
        a.oninput = FileControl.handleFileExport_;
        a.onsubmit = FileControl.handleFileExport_;
        a.oncancel = FileControl.handleFileExport_;
        */

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }
    static ___doFileExport_(features, epsg) {
        //
        // Write data
        var geojson = new GeoJSON();
        //
        var options = {
            //
            //dataProjection: 'EPSG:' + epsg,
            featureProjection: 'EPSG:' + epsg,
        }
        //
        var data = geojson.writeFeatures(features, options);

        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data.json';
        a.onclick = FileControl.handleFileExport_;
        a.onclose = FileControl.handleFileExport_;
        a.onchange = FileControl.handleFileExport_;
        a.oninput = FileControl.handleFileExport_;
        a.onsubmit = FileControl.handleFileExport_;
        a.oncancel = FileControl.handleFileExport_;

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }

    // World export
    fileExportWorld_(file_type) {
        //
        const features = this.getFeaturesToExport_();
        //
        if (!features)
            return;

        const version = 1.0;

        var geojson = new GeoJSON();
        //
        var data = geojson.writeFeatures(features);

        const action = 'transformFeatures_i2w';
        //
        var socket = this.getMap().getSocket(); // SocketConnection
        //
        switch (file_type) {
            //
            case 'json':
                socket.getSocket().on(action, this.onSocketFileExport_JSON_);
                break;
            case 'kml':
                socket.getSocket().on(action, this.onSocketFileExport_KML_);
                break;
            case 'gpx':
                socket.getSocket().on(action, this.onSocketFileExport_GPX_);
                break;
            default:
                alert('Error: ' + file_type + ': unknown file type!');
                return;
        }
        //
        const message = {
            version: version,
            uuid: socket.uuid(),
            data: data,
        };
        //
        socket.getSocket().emit(action, message);
    }
    onSocketFileExport_JSON_(message) {
        //
        console.log('FileControl.onSocketFileExport_JSON_');

        // Remove callback
        this.off('transformFeatures_i2w');

        FileControl.onSocketFileExport_(message, 'json')
    }
    onSocketFileExport_KML_(message) {
        //
        console.log('FileControl.onSocketFileExport_KML_');

        // Remove callback
        this.off('transformFeatures_i2w');

        FileControl.onSocketFileExport_(message, 'kml')
    }
    onSocketFileExport_GPX_(message) {
        //
        console.log('FileControl.onSocketFileExport_GPX_');

        // Remove callback
        this.off('transformFeatures_i2w');

        FileControl.onSocketFileExport_(message, 'gpx')
    }
    static onSocketFileExport_(message, file_type) {
        //
        console.log('FileControl.onSocketFileExport_');

        // Analyse message
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('FileControl.onSocketExportFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!ok) {
            //
            var msg = 'Warning: some features could not be translated correctly!';
            //
            msg += '\n';
            msg += '\n';
            //
            msg += 'Export anyway?';
            //
            if (!window.confirm(msg))
                return;
        }

        // Register projection
        Projection.register(epsg, prj4);

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        /*
        console.log('FileControl.onSocketExportFeatures_:');
        console.log('      features = ', features);
        /**/

        {
            /*
            function transformCoordinates_(coo_in, coo_out, number) {
                //
                //@typedef 
                //{function(Array<number>, Array<number>=, number=):
                // Array<number>} TransformFunction
                //
                console.log('transformCoordinates_()...');
                console.log('  coo_in  = ', coo_in);
                console.log('  coo_out = ', coo_out);
                console.log('  number = ', number);
    
    
                coo_out = [];
    
                let idx = 0;
                //
                for (let i = 0; i < coo_in.length; i += number) {
                    //
                    coo_out[idx++] = coo_in[i + 0];
                    coo_out[idx++] = coo_in[i + 1];
                }
    
                console.log('  coo_out = ', coo_out);
    
                return coo_out;
            }
    
            features.forEach(function (feature) {
                //
                console.log('TTTT transform feature', feature);
                //
                //feature.getGeometry().applyTransform(transformCoordinates_);
    
            });
            */
        }

        // Write to file
        FileControl.doFileExport_(features, epsg, file_type);
    }
    static doFileExport_(features, epsg, file_type) {
        //
        var writer = null;
        //
        switch (file_type) {
            //
            case 'json':
                writer = new GeoJSON();
                break;
            case 'kml':
                writer = new KML();
                break;
            case 'gpx':
                writer = new GPX();
                break;
            default:
                alert('Error: ' + file_type + ': unknown file type!');
                return;
        }

        // Write data
        var options = {
            //
            //dataProjection: 'EPSG:' + epsg,
            featureProjection: 'EPSG:' + epsg,
        }
        //
        var data = writer.writeFeatures(features, options);

        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data.' + file_type;

        a.click();

        window.URL.revokeObjectURL(url);
    }

    //-----------------------------------------------------
    //-----------------------------------------------------
    handleFileImport_(e) {
        //
        console.log('FileControl.handleFileImport_');
        //
        const files = e.target.files;

        const file = files[0]
        //
        if (file == null) {
            //
            alert("No file to load!");
            //
            return;
        }

        const fileSelector = e.srcElement;
        //
        const map = fileSelector.map;
        //
        const control = fileSelector.control;

        console.log('handleFileChange: map = ' + map);

        const reader = new FileReader();

        reader.onload = function (e) {

            var data = reader.result;
            //
            var geojson = new GeoJSON();
            //
            var features = null;
            //
            try {
                features = geojson.readFeatures(data);
                /*
                var vector = map.getLayers().item(1);
                //
                vector.getSource().addFeatures(features);
                */
            }
            catch (ex) {
                //
                alert('Error reading from: ' + file.name + ' (' + ex + ')');
                //
                return;
            }

            const version = 1.0;

            var geojson = new GeoJSON();
            //
            var data = geojson.writeFeatures(features);

            const action = 'transformFeatures_w2i';
            //
            var socket = map.getSocket(); // SocketConnection
            //
            socket.socket.on(action, control.onSocketImportFeatures_);
            //
            const message = {
                version: version,
                uuid: socket.uuid(),
                epsg: 4326, // default: WGS84
                data: data,
            };
            //
            socket.socket.emit(action, message);
        }

        if (file) {
            reader.readAsText(file);
        }
    };
    static ____handleFileExport_(e) {
        //
        console.log('FileControl.handleFileExport_: e = ' + e);

    }

    static doFileExport_OK_(features, epsg) {
        //
        // Write data
        var geojson = new GeoJSON();
        //
        var options = {
            featureProjection: 'EPSG:' + epsg,
        }
        //
        var data = geojson.writeFeatures(features, options);

        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data.json';

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }

    static __doFileExport_(features, epsg) {
        //
        // Write data
        var geojson = new GeoJSON();
        //
        var options = {
            //
            //dataProjection: 'EPSG:' + epsg,
            featureProjection: 'EPSG:' + epsg,
        }
        //
        var data = geojson.writeFeatures(features, options);

        var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
        var url = window.URL.createObjectURL(blob);

        var a = document.createElement("a");

        a.style = "display: none";

        a.href = url;
        a.download = 'data.json';
        a.onclick = FileControl.handleFileExport_;
        a.onclose = FileControl.handleFileExport_;
        a.onchange = FileControl.handleFileExport_;
        a.oninput = FileControl.handleFileExport_;
        a.onsubmit = FileControl.handleFileExport_;
        a.oncancel = FileControl.handleFileExport_;

        //newlink.setAttribute('onclick', 'myHandle(this)');

        a.click();

        window.URL.revokeObjectURL(url);
    }

    onSocketTransformFeatures_i2w_OK_(message) {
        //
        // Remove callback
        this.off('transformFeatures_i2w');

        var object = this;
        //
        //console.log('onSocketTransformFeatures_: object (' + typeof object + ') = ' + object);
        /*
        for (var property in object) {
          console.log(property + ': ' + object[property] + '; ');
        }
        /**/

        //var socket = this.getMap().socket;
        //
        //      socket.socket.off('transform', this.onSocketTransform_);

        //console.log('FileControl.onSocketTransform_: message = ' + message);

        const token = message.split("&");

        let idx = 0;
        //
        const version = token[idx++];
        //
        const ok = token[idx++];
        //
        const epsg = token[idx++];
        const prj4 = token[idx++];
        const data = token[idx++];

        /*
        const query = url.parse(message, true).query;
    
        //console.log('onSocketTransform_: query (' + typeof query + ') = ' + query);
    
        const version = query.ver;
        //
        const ok = query.ok;
        //
        const epsg = query.epsg;
        const prj4 = query.prj4;
        const data = query.data;
        */

        //*
        console.log('FileControl.onSocketTransformFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!ok) {
            //
            var msg = 'Warning: some features could not be translated correctly!';
            //
            msg += '\n';
            msg += '\n';
            //
            msg += 'Export anyway?';
            //
            if (!window.confirm(msg))
                return;
        }

        // Register projection
        //var projection = proj.get('EPSG:' + epsg);
        //import { get as projGetProjection } from 'ol/proj';
        //import { register as proj4Register } from 'ol/proj/proj4';

        // Register projection
        Projection.register(epsg, prj4);

        /*
        var projection = projGetProjection('EPSG:' + epsg);
        //
        if (!projection) {
            //
            console.log('FileControl.onSocketTransformFeatures_: register prj EPSG:' + epsg);
            //
            proj4.defs('EPSG:' + epsg, prj4);
            //
            proj4Register(proj4);
    
            //proj.addProjection(projection);
    
            //proj4.defs('EPSG:' + epsg, prj4);
            //
            //proj4.register(proj4);
        }
        else
            console.log('FileControl.onSocketTransformFeatures_: prj EPSG:' + epsg + ' already registered');
    
        */

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        console.log('FileControl.onSocketTransformFeatures_:');
        console.log('      features = ' + features);

        // Write to file
        FileControl.doFileExport_(features, epsg);
    }
    onSocketExportFeatures_(message) {
        //
        // Remove callback
        this.off('transformFeatures_i2w');

        var object = this;
        //
        //console.log('onSocketTransformFeatures_: object (' + typeof object + ') = ' + object);
        /*
        for (var property in object) {
          console.log(property + ': ' + object[property] + '; ');
        }
        /**/

        //var socket = this.getMap().socket;
        //
        //      socket.socket.off('transform', this.onSocketTransform_);

        //console.log('FileControl.onSocketTransform_: message = ' + message);

        //const result = message;
        //
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('FileControl.onSocketExportFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!ok) {
            //
            var msg = 'Warning: some features could not be translated correctly!';
            //
            msg += '\n';
            msg += '\n';
            //
            msg += 'Export anyway?';
            //
            if (!window.confirm(msg))
                return;
        }

        // Register projection
        Projection.register(epsg, prj4);
        /*
        var projection = projGetProjection('EPSG:' + epsg);
        //
        if (!projection) {
            //
            console.log('FileControl.onSocketExportFeatures_: register prj EPSG:' + epsg);
            //
            proj4.defs('EPSG:' + epsg, prj4);
            //
            proj4Register(proj4);
        }
        else
            console.log('FileControl.onSocketExportFeatures_: prj EPSG:' + epsg + ' already registered');
        */

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        console.log('FileControl.onSocketExportFeatures_:');
        console.log('      features = ' + features);

        // Write to file
        FileControl.__doFileExport_(features, epsg);
    }
    onSocketImportFeatures_(message) {
        //
        // Remove callback
        this.off('FileControl.onSocketImportFeatures_');

        var object = this;
        //
        //console.log('onSocketTransformFeatures_: object (' + typeof object + ') = ' + object);
        /*
        for (var property in object) {
          console.log(property + ': ' + object[property] + '; ');
        }
        /**/

        console.log('FileControl.onSocketImportFeatures_: message = ', message);
        //
        const version = message.version;
        //
        const ok = message.ok;
        //
        const epsg = message.epsg;
        const prj4 = message.prj4;
        const data = message.data;

        //*
        console.log('FileControl.onSocketImportFeatures_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        console.log('      epsg    = ' + epsg);
        console.log('      prj4    = ' + prj4);
        //console.log('      data    = ' + data);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Could not import data: unknown error!';
            //
            console.log(msg);
            //
            alert(msg);
            //
            return;
        }

        // Get features
        var geojson = new GeoJSON();
        //
        var features = geojson.readFeatures(data);
        //
        console.log('FileControl.onSocketImportFeatures_:');
        console.log('      features = ' + features);

        var foreground = this.map.getLayers().item(1);
        //
        //foreground.getSource().clear();
        //
        foreground.getSource().addFeatures(features);
    }
}