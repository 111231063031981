import Popup from '../utils/Popup'
//
import './PopupPhoto.css';

export default class PopupPhoto extends Popup {

    constructor(map) {

        console.log('PopupPictures: constructor...');
        //
        super('PopupPhoto');
        //
        this.map = map;
        //
        this.video = null;
        //
        this.windowAlpha = null;
        this.windowBeta = null;
        this.windowGamma = null;
        //
        this.textTakePhoto = null;
        this.buttonTakePhoto = null;
        //
        /*
        this.divPhotoTitle = null;
        this.divPhotoVideo = null;
        this.divPhotoTakePhoto = null;
        this.divPhotoCameraData = null;
        */
        //
        this.deviceOrientationOk = false;
        //
        this.initContent_();
        this.setupVideo_();
        this.setupSensor_();

        console.log('this.windowAlpha = ', this.windowAlpha)

        this.handleDeviceOrientation_ = this.onDeviceOrientation_.bind(this);
    }

    initContent_1_() {
        //
        var div = null;
        var p = null;
        var b = null;
        var v = null;

        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTitle';
        //
        this.divPhotoTitle = div;

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTitleText';
        //
        p.innerHTML = "Take a photo";
        //
        div.appendChild(p);
        //
        super.addContent(div);
        //--------------------------------------------------------------
        // Video
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoVideo';
        //
        this.divPhotoVideo = div;

        v = document.createElement('video');
        //
        v.className = 'popupPhotoVideoImage';
        //
        div.appendChild(v);
        //
        this.video = v;
        //
        super.addContent(div);
        //--------------------------------------------------------------
        // Button
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTakePhoto';
        //
        this.divPhotoTakePhoto = div;

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTakePhotoText';
        //
        p.innerHTML = "";
        //
        div.appendChild(p);
        //
        this.textTakePhoto = p;

        b = document.createElement('button');
        //
        b.className = 'popupPhotoTakePhotoButton';
        //
        b.innerHTML = "click";
        //
        b.title = "Click to take a photo";
        //
        b.popup = this;
        //
        b.onclick = this.handleTakePhoto_;
        //
        div.appendChild(b);
        //
        this.buttonTakePhoto = b;

        super.addContent(div);

        return;

        //--------------------------------------------------------------
        // Camera data
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoCameraData';
        //
        this.divPhotoCameraData = div;

        var table = document.createElement('table');
        //
        table.className = 'popupPhotoCameraTable';

        let tr;
        let td;

        // Alpha
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Direction [alpha]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowAlpha = td;

        // Beta
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Tilt Front/Back [beta]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowBeta = td;

        // Gamma
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Tilt Left/Right [gamma]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowGamma = td;

        div.appendChild(table);
        //
        super.addContent(div);
        //--------------------------------------------------------------
    }

    initTitle_() {
        //
        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        var div = document.createElement('div');
        //
        div.className = 'popupPhotoTitle';
        //
        this.divPhotoTitle = div;

        var p = document.createElement('p');
        //
        p.className = 'popupPhotoTitleText';
        //
        p.innerHTML = "Take a photo";
        //
        div.appendChild(p);

        super.addContent(div);
        //--------------------------------------------------------------
    }
    initVideo_() {
        //
        //--------------------------------------------------------------
        // Video
        //--------------------------------------------------------------
        var div = document.createElement('div');
        //
        div.className = 'popupPhotoVideo';

        var v = document.createElement('video');
        //
        v.className = 'popupPhotoVideoImage';
        //
        div.appendChild(v);
        //
        this.video = v;

        //this.initCamera_2_(div);

        super.addContent(div);
    }
    initButton_() {
        //
        //--------------------------------------------------------------
        // Button
        //--------------------------------------------------------------
        var div = document.createElement('div');
        //
        div.className = 'popupPhotoTakePhoto';

        var p = document.createElement('p');
        //
        p.className = 'popupPhotoTakePhotoText';
        //
        p.innerHTML = "";
        //
        div.appendChild(p);
        //
        this.textTakePhoto = p;

        var b = document.createElement('button');
        //
        b.className = 'popupPhotoTakePhotoButton';
        //
        b.innerHTML = "click";
        //
        b.title = "Click to take a photo";
        //
        b.popup = this;
        //
        b.onclick = this.handleTakePhoto_;
        //
        div.appendChild(b);
        //
        this.buttonTakePhoto = b;

        super.addContent(div);
        //--------------------------------------------------------------
    }
    initCamera_() {
        //
        //--------------------------------------------------------------
        // Camera data
        //--------------------------------------------------------------
        var div = document.createElement('div');
        //
        div.className = 'popupPhotoCameraData';

        var table = document.createElement('table');
        //
        table.className = 'popupPhotoCameraTable';

        let tr;
        let td;

        // Alpha
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Alpha';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowAlpha = td;

        // Beta
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Beta';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowBeta = td;

        // Gamma
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Gamma';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowGamma = td;

        div.appendChild(table);
        //
        super.addContent(div);
        //--------------------------------------------------------------
    }

    initCamera_2_(parent) {
        //
        console.log('initCamera_(): parent = ', parent);

        //--------------------------------------------------------------
        // Camera data
        //--------------------------------------------------------------
        var div = document.createElement('div');
        //
        div.className = 'popupPhotoCameraData';

        var table = document.createElement('table');
        //
        table.className = 'popupPhotoCameraTable';

        let tr;
        let td;

        // Alpha
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Alpha';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowAlpha = td;

        // Beta
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Beta';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowBeta = td;

        // Gamma
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Gamma';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowGamma = td;

        div.appendChild(table);
        //
        parent.appendChild(div);
        //--------------------------------------------------------------
    }
    initContent_() {
        //
        this.initTitle_();
        this.initVideo_();
        this.initButton_();
        this.initCamera_();

        return;


        this.initTitle_();
        this.initVideo_();
        this.initButton_();

        return;

        var div = null;
        var p = null;
        var b = null;
        var v = null;

        //--------------------------------------------------------------
        // Main div
        //--------------------------------------------------------------
        //div = document.createElement('div');
        //
        //div.className = 'popupPhotoMain';
        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTitle';
        //
        this.divPhotoTitle = div;

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTitleText';
        //
        p.innerHTML = "Take a photo";
        //
        div.appendChild(p);

        super.addContent(div);
        //--------------------------------------------------------------
        // Video
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoVideo';

        v = document.createElement('video');
        //
        v.className = 'popupPhotoVideoImage';
        //
        div.appendChild(v);
        //
        this.video = v;

        super.addContent(div);
        //--------------------------------------------------------------
        // Button
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTakePhoto';

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTakePhotoText';
        //
        p.innerHTML = "";
        //
        div.appendChild(p);

        b = document.createElement('button');
        //
        b.className = 'popupPhotoTakePhotoButton';
        //
        b.innerHTML = "click";
        //
        b.title = "Click to take a photo";
        //
        b.popup = this;
        //
        b.onclick = this.handleTakePhoto_;
        //
        div.appendChild(b);
        //
        this.buttonTakePhoto = b;

        super.addContent(div);
        //--------------------------------------------------------------

        return;


        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTitle';
        //
        this.divPhotoTitle = div;

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTitleText';
        //
        p.innerHTML = "Take a photo";
        //
        div.appendChild(p);
        //
        super.addContent(div);
        //--------------------------------------------------------------
        // Video
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoVideo';
        //
        this.divPhotoVideo = div;

        v = document.createElement('video');
        //
        v.className = 'popupPhotoVideoImage';
        //
        //div.appendChild(v);
        //
        this.video = v;
        //
        super.addContent(div);
        //--------------------------------------------------------------
        // Button
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoTakePhoto';
        //
        this.divPhotoTakePhoto = div;

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTakePhotoText';
        //
        p.innerHTML = "";
        //
        div.appendChild(p);
        //
        this.textTakePhoto = p;

        b = document.createElement('button');
        //
        b.className = 'popupPhotoTakePhotoButton';
        //
        b.innerHTML = "click";
        //
        b.title = "Click to take a photo";
        //
        b.popup = this;
        //
        b.onclick = this.handleTakePhoto_;
        //
        div.appendChild(b);
        //
        this.buttonTakePhoto = b;

        super.addContent(div);


        //--------------------------------------------------------------
        // Footer
        //--------------------------------------------------------------
        let footer = document.createElement('footer');
        //
        div.className = 'popupPhotoFoter';
        //

        p = document.createElement('p');
        //
        p.className = 'popupPhotoTakePhotoText';
        //
        p.innerHTML = "";
        //
        footer.appendChild(p);
        //
        this.textTakePhoto = p;

        b = document.createElement('button');
        //
        b.className = 'popupPhotoTakePhotoButton';
        //
        b.innerHTML = "click";
        //
        b.title = "Click to take a photo";
        //
        b.popup = this;
        //
        b.onclick = this.handleTakePhoto_;
        //
        footer.appendChild(b);
        //
        this.buttonTakePhoto = b;

        super.addContent(footer);

        return;

        //--------------------------------------------------------------
        // Camera data
        //--------------------------------------------------------------
        div = document.createElement('div');
        //
        div.className = 'popupPhotoCameraData';
        //
        this.divPhotoCameraData = div;

        var table = document.createElement('table');
        //
        table.className = 'popupPhotoCameraTable';

        let tr;
        let td;

        // Alpha
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Direction [alpha]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowAlpha = td;

        // Beta
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Tilt Front/Back [beta]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowBeta = td;

        // Gamma
        tr = document.createElement('tr');
        //
        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableText';
        //
        td.innerHTML = 'Tilt Left/Right [gamma]';
        //
        td.title = '';
        //
        tr.appendChild(td);

        td = document.createElement('td');
        //
        td.className = 'popupPhotoCameraTableValue';
        //
        td.innerHTML = '';
        //
        td.title = '';
        //
        tr.appendChild(td);
        //
        table.appendChild(tr);
        //
        this.windowGamma = td;

        div.appendChild(table);
        //
        super.addContent(div);
        //--------------------------------------------------------------
    }

    setupVideo_() {
        //
        let video = this.video;
        //
        var mediaConfig = {
            video: true,
            facingMode: { exact: "environment" }
            /*
            facingMode: { exact: "environment" }
            facingMode: "user"
            */
         };
        //
        var errBack = function (e) {
            console.log('An error has occurred!', e)
        };

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(mediaConfig).then(function (stream) {
                //video.src = window.URL.createObjectURL(stream);
                video.srcObject = stream;
                //video.play();
            });
        }

        /* Legacy code below! */
        else if (navigator.getUserMedia) { // Standard
            navigator.getUserMedia(mediaConfig, function (stream) {
                video.src = stream;
                //video.play();
            }, errBack);
        } else if (navigator.webkitGetUserMedia) { // WebKit-prefixed
            navigator.webkitGetUserMedia(mediaConfig, function (stream) {
                video.src = window.webkitURL.createObjectURL(stream);
                //video.play();
            }, errBack);
        } else if (navigator.mozGetUserMedia) { // Mozilla-prefixed
            navigator.mozGetUserMedia(mediaConfig, function (stream) {
                video.src = window.URL.createObjectURL(stream);
                //video.play();
            }, errBack);
        }
    }
    setupSensor_() {
        //
        if ('DeviceOrientationEvent' in window) {
            //
            this.deviceOrientationOk = true;
        }
        else {
            //
            this.deviceOrientationOk = false;
            //
            this.buttonTakePhoto.disabled = true;
            //
            alert('Warning: Device Orientation not supported!')
        }
    }

    handleFileOpen_(e) {
        //
        console.log('PopupPictures.handleFileOpen_()...');
        console.log('   this = ', this);
        console.log('   e    = ', e);

        // Initialize and click file selector
        var fileSelector = document.createElement('input');
        //
        fileSelector.popup = this.popup;
        //
        fileSelector.type = 'file';
        fileSelector.accept = '.m_picture';
        fileSelector.onchange = this.popup.handleFileChange_;
        //
        fileSelector.click();
    }
    handleTakePhoto_(e) {

        console.log('handleTakePhoto_: e = ', e);
        /*
        for (var property in e) {
          console.log(property + ': ' + e[property] + '; ');
        }
        /**/

        console.log('handleTakePhoto_: e.srcElement = ' + e.srcElement);
        /*
        for (var property in e.srcElement) {
          console.log(property + ': ' + e.srcElement[property] + '; ');
        }
        /**/

        return;
    }

    deviceOrientationHandler_(e) {
        //
        console.log('deviceOrientationHandler_: e = ', e);
        //
        console.log('   alpha = ', Math.round(e.alpha));
        console.log('   beta  = ', Math.round(e.beta));
        console.log('   gamma = ', Math.round(e.gamma));


        let popup;

        console.log('  target        = ', e.target.windowAlpha);
        console.log('  currentTarget = ', e.currentTarget.windowAlpha);
        console.log('  currentTarget = ', e.currentTarget.windowAlpha);




        popup = e.srcElement;
        console.log('deviceOrientationHandler_: popup.windowAlpha = ', popup.windowAlpha);

        popup = e.windowAlpha;
        console.log('deviceOrientationHandler_: popup.windowAlpha = ', popup);

        popup = e.srcObject;
        console.log('deviceOrientationHandler_: popup.windowAlpha = ', popup.windowAlpha);


        var alpha = e.alpha;
        var beta = e.beta;
        var gamma = e.gamma;
        //
        this.windowAlpha.innerHTML = alpha.toFixed(2);
        this.windowBeta.innerHTML = beta.toFixed(2);
        this.windowGamma.innerHTML = gamma.toFixed(2);
    }

    onDeviceOrientation_(e) {
        //
        console.log('onDeviceOrientation_: this = ', this);
        console.log('onDeviceOrientation_: e    = ', e);
        //
        var alpha = e.alpha;
        var beta = e.beta;
        var gamma = e.gamma;
        //
        if (!alpha || !beta | !gamma) {
            //
            console.log('onDeviceOrientation_: ok = FALSE');
            //
            this.windowAlpha.innerHTML = '-';
            this.windowBeta.innerHTML = '-';
            this.windowGamma.innerHTML = '-';
            //
            this.buttonTakePhoto.disabled = true;
            //
            this.textTakePhoto.innerHTML = 'Warning: device orientation not available!';
        }
        else {
            //
            console.log('onDeviceOrientation_: ok = TRUE');
            //
            this.windowAlpha.innerHTML = alpha.toFixed(2);
            this.windowBeta.innerHTML = beta.toFixed(2);
            this.windowGamma.innerHTML = gamma.toFixed(2);
            //
            this.buttonTakePhoto.disabled = false;
        }
    }

    // Overwrite show/hide methods to start/stop video
    show() {
        //
        super.show();
        //

        /*
        //this.divPhotoVideoImage


        let height = 0;
        //
        height += this.divPhotoTitle.offsetHeight;
        height += this.divPhotoTakePhoto.offsetHeight;
        height += this.divPhotoCameraData.offsetHeight;
        //
        console.log('HEIGHT = ' + height);
        console.log('PARENT HEIGHT = ' + this.divPhotoVideo.parentElement.offsetHeight);

        height = 0;
        //
        height += this.divPhotoTitle.getBoundingClientRect().height;
        height += this.divPhotoTakePhoto.getBoundingClientRect().height;
        height += this.divPhotoCameraData.getBoundingClientRect().height;

        console.log('HEIGHT = ' + height);
        console.log('PARENT HEIGHT = ' + this.divPhotoVideo.parentElement.offsetHeight);

        let parentHeight = this.divPhotoVideo.parentElement.offsetHeight;

        this.divPhotoVideo.style.height = parentHeight - height + "px";
        */

        /*
        this.divPhotoTitle=null;
        this.divPhotoVideo=null;
        this.divPhotoTakePhoto=null;
        this.divPhotoCameraData=null;
        */

        this.video.play();
        //
        if (this.deviceOrientationOk) {
            //
            window.addEventListener('deviceorientation', this.handleDeviceOrientation_);

            //window.addEventListener('deviceorientation', this.deviceOrientationHandler_, false);

        }

    }
    hide() {
        //
        this.video.stop();
        //
        super.hide();
        //
        if (this.deviceOrientationOk) {
            //
            window.removeEventListener('deviceorientation', this.handleDeviceOrientation_);

            //window.removeEventListener('deviceorientation', this.deviceOrientationHandler_, false);
        }
    }
}