import Popup from '../utils/Popup'
//
import './PopupInfo.css';

export default class PopupInfo extends Popup {

    constructor() {

        console.log('PopupInfo: constructor...');
        //
        super('popupInfo');

        var p = null;

        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        super.setTitle("About Historical Reality");
        //----------------------------------------------------------

        /*
        // Title
        p = document.createElement("p");
        //
        p.className = 'info-title';
        p.innerHTML = "About Historical Reality";
        //
        super.addToContent(p);
        */

       p = document.createElement("p");
       //
       p.className = 'info-content';
       //
       p.innerHTML = "<i>Historical Reality</i> is an online digital platform";
       p.innerHTML += " that offers the instruments to use oblique images to";
       p.innerHTML += " extract 3D quantitative information from images, or to";
       p.innerHTML += " represent on the images itself available information";
       p.innerHTML += " such as streets and trails, place names and POI";
       p.innerHTML += " (augmented reality).";
       //
       super.addToContent(p);

       p = document.createElement("p");
       //
       p.className = 'info-content';
       //
       p.innerHTML = "The platform can be used in two modes: <i>navigator</i> mode";
       p.innerHTML += " and <i>webGIS</i> mode.";
       //
       super.addToContent(p);

       p = document.createElement("p");
       //
       p.className = 'info-content';
       //
       p.innerHTML = "In <i>navigator</i> mode, if you are in the photo area";
       p.innerHTML += " and you activate the GPS, you can view your";
       p.innerHTML += " position on a smart device, and thus plunge";
       p.innerHTML += " into the past and compare the former";
       p.innerHTML += " landscape with the current.";
       //
       super.addToContent(p);

       p = document.createElement("p");
       //
       p.className = 'info-content';
       //
       p.innerHTML = "The <i>webGIS</i> mode allows you to obtain 3D information";
       p.innerHTML += " from the photograph, defining landscape elements with the";
       p.innerHTML += " standard GIS features (points, lines and polygons).";
       //
       super.addToContent(p);

       return;


        p = document.createElement("p");
        //
        p.className = 'info-content';
        //
        p.innerHTML = "Historical Reality 1.0: to be completed...";
        //
        super.addToContent(p);

        return;



        /*
        Historical Reality is an online digital platform that offers the instruments to use oblique images to extract 3D quantitative information (webGIS) from images, or to represent on the images itself available information such as streets and trails, place names and POI (augmented reality).

        The platform can be used in two modes: navigator mode and webGIS mode.
        
        In navigator mode, if you are in the photo area and you activate the GPS, you can view your position on a smart device, and thus plunge into the past and compare the former landscape with the current.
        
        The webGIS mode allows to obtain 3D information from the photograph, defining landscape elements with the standard GIS features (points, lines and polygons).
        */


    }
}