import React from 'react';

import './CameraControl.css';

import { Control } from 'ol/control';

import imageCamera from './icons/Camera.svg'

export default class CameraControl extends Control {

  constructor(opt_options) {

    console.log('CameraControl: constructor...');

    var options = opt_options || {};

    var element = document.createElement('div');
    //
    element.className = 'camera ol-unselectable ol-control';

    super({ element: element, target: options.target });

    var btn_camera = document.createElement('input');
    //
    btn_camera.className = 'camera-button';
    btn_camera.title = 'Take photo saving camera data';
    btn_camera.type = 'image';
    btn_camera.src = imageCamera;
    //
    btn_camera.disabled = false;
    //
    btn_camera.addEventListener('click', this.onCameraPhoto.bind(this), false);

    element.appendChild(btn_camera);
    //
    Control.call(this, {
      element: element,
      target: options.target
    });

    this.btn_camera = btn_camera;
  }

  onCameraPhoto(e) {
    //
    console.log('CameraControl::onCameraPhoto');
    //
    const camera = this.getMap().getCamera();
    //
    console.log('  camera = ', camera);

    if (camera.isVisible())
      camera.hide();
    else
      camera.show();
  }
}