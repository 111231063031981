//import 'ol-layerswitcher/dist/ol-layerswitcher.css'
//import 'ol-layerswitcher/dist/ol-layerswitcher.js'

//import './CameraControl'

import './LayerSwitcher.css'

import LayerSwitcher from 'ol-layerswitcher';

export default LayerSwitcher;

//export default LayerSwitcher;

//export LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher.js';
