import GeometryType from 'ol/geom/GeometryType';
import LineString from 'ol/geom/LineString';
//
import { getWidth } from 'ol/extent';
import { Fill, Stroke, Style, Text } from 'ol/style';
//
import Point from 'ol/geom/Point';
import { Circle } from 'ol/style';
//
import './Attr_TOPO.css'

export default class Attr_TOPO {

    static __hasAttr_(feature, name) {
        //
        const attr = feature.get(name);
        //
        if (!attr)
            return false;
        //
        return true;
    }
    //
    static __hasAttr(features) {
        //
        //console.log('Attr_TOPO.hasAttr()...');
        //
        if (features.length == 0)
            return false;

        const feature = features[0];
        /*
        console.log('      Article = ', feature.get('Article'));
        console.log('      Name    = ', feature.get('Name'));
        console.log('      Type    = ', feature.get('Type'));
        console.log('      Area    = ', feature.get('Area'));
        console.log('      Index   = ', feature.get('Index'));
        /**/

        if (!Attr_TOPO.hasAttr_(feature, 'Article'))
            return false;

        if (!Attr_TOPO.hasAttr_(feature, 'Name'))
            return false;

        if (!Attr_TOPO.hasAttr_(feature, 'Type'))
            return false;

        if (!Attr_TOPO.hasAttr_(feature, 'Area'))
            return false;

        if (!Attr_TOPO.hasAttr_(feature, 'Index'))
            return false;

        return true;
    }

    static __newTextOf_(feature) {
        //
        let name = feature.get('Name');
        let type = feature.get('Type');
        //
        let font_size = 8;
        //
        let font_style = 'normal';
        let font_color = 'red';
        let font_weight = 'normal';
        let font_family = 'Calibri,sans-serif';
        //
        let stroke_color = '#fff';
        let stroke_width = 2;

        let topoClass = 'attr-topo';
        //
        let className = topoClass;

        switch (type) {
            //
            // Inhabited places
            case 100: font_size = 24; font_style = 'italic'; font_color = 'red'; break;
            case 101: font_size = 18; font_style = 'italic'; font_color = 'red'; break;
            case 102: font_size = 16; font_style = 'italic'; font_color = 'red'; break;
            case 103: font_size = 12; font_style = 'italic'; font_color = 'red'; break;
            case 104: font_size = 10; font_style = 'italic'; font_color = 'red'; break;
            //
            // Religious places
            case 200: font_size = 24; font_style = 'normal'; font_color = 'purple'; break;
            case 201: font_size = 12; font_style = 'normal'; font_color = 'purple'; break;

            case 202: className += ' ' + topoClass + '-' + type;
            //case 202: font_size = 11; font_style = 'normal'; font_color = 'purple'; break;
            case 202: font_size = 11; font_style = 'normal'; font_color = 'purple'; break;

            case 203: font_size = 10; font_style = 'normal'; font_color = 'purple'; break;
            case 204: font_size = 9; font_style = 'normal'; font_color = 'purple'; break;
            //
            // Mountains and alps
            case 300: font_size = 24; font_style = 'normal'; font_color = 'red'; break;
            case 301: font_size = 18; font_style = 'normal'; font_color = 'red'; break;
            case 302: font_size = 16; font_style = 'normal'; font_color = 'red'; break;
            case 303: font_size = 12; font_style = 'normal'; font_color = 'red'; break;
            case 304: font_size = 10; font_style = 'normal'; font_color = 'black'; break;
            //
            // Hydrology
            case 400: font_size = 24; font_style = 'normal'; font_color = 'blue'; break;
            case 401: font_size = 14; font_style = 'normal'; font_color = 'blue'; break;
            case 402: font_size = 12; font_style = 'normal'; font_color = 'blue'; break;
            case 403: font_size = 10; font_style = 'normal'; font_color = 'blue'; break;
            case 404: font_size = 9; font_style = 'normal'; font_color = 'blue'; break;
            //
            // Buildings
            case 500: font_size = 24; font_style = 'normal'; font_color = 'blue'; break;
            case 501: font_size = 14; font_style = 'normal'; font_color = 'blue'; break;
            case 502: font_size = 12; font_style = 'normal'; font_color = 'blue'; break;
            case 503: font_size = 10; font_style = 'normal'; font_color = 'blue'; break;
            case 504: font_size = 9; font_style = 'normal'; font_color = 'blue'; break;

        }

        let font = font_style + ' ' + font_weight + ' ' + font_size + ' ' + font_family;

        let style = new Text({
            text: name,
            font: font,
            overflow: true,
            textAlign: 'center',
            textBaseline: 'middle',
            fill: new Fill({
                color: font_color,
            }),
            stroke: new Stroke({
                color: stroke_color,
                width: stroke_width,
            }),
        });

        feature.setStyle(style);
        //
        feature.className = className;
    }

    static __setText_(feature) {
        //
        //*
        console.log('  name = ' + feature.get('topo_name'));
        console.log('  type = ' + feature.get('topo_type'));
        /**/
        //
        let type = feature.get('topo_type');
        //
        if (!type)
            return;

        let name = feature.get('topo_name');
        //
        let font_size = 8;
        //
        let font_style = 'normal';
        let font_color = 'red';
        let font_weight = 'normal';
        let font_family = 'Calibri,sans-serif';
        //
        let stroke_color = '#fff';
        let stroke_width = 2;

        const color_100 = 'red'
        const color_200 = 'rgb(255, 0, 255)'
        const color_300 = 'red'
        const color_304 = 'black'
        const color_400 = 'blue'
        const color_500 = 'rgb(204, 102, 0)'
        const color_600 = 'rgb(138, 0, 0)'
        const color_700 = 'black'

        switch (type) {
            //
            // Inhabited places
            case 100: font_size = 24; font_style = 'italic'; font_color = color_100; break;
            case 101: font_size = 18; font_style = 'italic'; font_color = color_100; break;
            case 102: font_size = 16; font_style = 'italic'; font_color = color_100; break;
            case 103: font_size = 12; font_style = 'italic'; font_color = color_100; break;
            case 104: font_size = 10; font_style = 'italic'; font_color = color_100; break;
            //
            // Religious places
            case 200: font_size = 24; font_style = 'normal'; font_color = color_200; break;
            case 201: font_size = 12; font_style = 'normal'; font_color = color_200; break;
            case 202: font_size = 11; font_style = 'normal'; font_color = color_200; break;
            case 203: font_size = 10; font_style = 'normal'; font_color = color_200; break;
            case 204: font_size = 9; font_style = 'normal'; font_color = color_200; break;
            //
            // Mountains and alps
            case 300: font_size = 24; font_style = 'normal'; font_color = color_300; break;
            case 301: font_size = 18; font_style = 'normal'; font_color = color_300; break;
            case 302: font_size = 16; font_style = 'normal'; font_color = color_300; break;
            case 303: font_size = 12; font_style = 'normal'; font_color = color_300; break;
            case 304: font_size = 10; font_style = 'normal'; font_color = color_304; break;
            //
            // Hydrology
            case 400: font_size = 24; font_style = 'italic'; font_color = color_400; break;
            case 401: font_size = 14; font_style = 'italic'; font_color = color_400; break;
            case 402: font_size = 12; font_style = 'italic'; font_color = color_400; break;
            case 403: font_size = 10; font_style = 'italic'; font_color = color_400; break;
            case 404: font_size = 9; font_style = 'italic'; font_color = color_400; break;
            //
            // Buildings
            case 500: font_size = 24; font_style = 'italic'; font_color = color_500; break;
            case 501: font_size = 12; font_style = 'italic'; font_color = color_500; break;
            case 502: font_size = 11; font_style = 'italic'; font_color = color_500; break;
            case 503: font_size = 10; font_style = 'italic'; font_color = color_500; break;
            case 504: font_size = 9; font_style = 'italic'; font_color = color_500; break;
            //
            // Ways
            case 600: font_size = 24; font_style = 'normal'; font_color = color_600; break;
            case 601: font_size = 12; font_style = 'normal'; font_color = color_600; break;
            case 602: font_size = 10; font_style = 'normal'; font_color = color_600; break;
            case 603: font_size = 9; font_style = 'normal'; font_color = color_600; break;
            case 604: font_size = 8; font_style = 'normal'; font_color = color_600; break;
            //
            // Other places
            case 700: font_size = 24; font_style = 'normal'; font_color = color_700; break;
            case 701: font_size = 11; font_style = 'normal'; font_color = color_700; break;
            case 702: font_size = 10; font_style = 'normal'; font_color = color_700; break;
            case 703: font_size = 9; font_style = 'normal'; font_color = color_700; break;
            case 704: font_size = 8; font_style = 'normal'; font_color = color_700; break;
            //
            default:
                type = 0;
                break;
        }

        let font = font_style + ' ' + font_weight + ' ' + font_size + 'px ' + font_family;

        console.log('  font = ' + font);

        let text = new Text({
            text: name,
            font: font,
            overflow: true,
            textAlign: 'center',
            textBaseline: 'middle',
            fill: new Fill({
                color: font_color,
            }),
            stroke: new Stroke({
                color: stroke_color,
                width: stroke_width,
            }),
        });

        let zIndex = 1 - parseInt(type, 10) / 1000;
        //
        var style = new Style({
            //
            text: text,
            zIndex: zIndex,
        });

        feature.setStyle(style);
    }

    static selectionColor_(alpha) {
        //
        if (alpha)
            return [255, 0, 0, alpha];
        else
            return [255, 0, 0];

        if (alpha)
            return 'rgba(255, 0, 0, ' + alpha + ')';
        else
            return 'rgb(255, 0, 0)';
    }

    static newStyle_(feature, is_selected) {
        //
        /*
        console.log('  name = ' + feature.get('topo_name'));
        console.log('  type = ' + feature.get('topo_type'));
        /**/
        //
        let type = feature.get('topo_type');
        //
        if (!type)
            return null;

        let name = feature.get('topo_name');
        //
        let font_size = 9;
        //
        let font_style = 'normal';
        let font_color = [255, 0, 0];
        let font_weight = 'normal';
        let font_family = 'Calibri,sans-serif';
        //
        let stroke_color = '#fff';
        let stroke_width = 2;

        /*
        const color_100 = 'red'
        const color_200 = 'rgb(255, 0, 255)'
        const color_300 = 'red'
        const color_304 = 'black'
        const color_400 = 'blue'
        const color_500 = 'rgb(204, 102, 0)'
        const color_600 = 'rgb(138, 0, 0)'
        const color_700 = 'black'
        */

        const color_100 = [255, 0, 0];   // red
        const color_200 = [255, 0, 255];
        const color_300 = [255, 0, 0];   // red
        const color_304 = [0, 0, 0];     // black
        const color_400 = [0, 0, 255];   // blue
        const color_500 = [204, 102, 0];
        const color_600 = [138, 0, 0];
        const color_700 = [0, 0, 0];     // black

        switch (type) {
            //
            // Inhabited places
            case 100: font_size = 24; font_style = 'italic'; font_color = color_100; break;
            case 101: font_size = 18; font_style = 'italic'; font_color = color_100; break;
            case 102: font_size = 16; font_style = 'italic'; font_color = color_100; break;
            case 103: font_size = 12; font_style = 'italic'; font_color = color_100; break;
            case 104: font_size = 10; font_style = 'italic'; font_color = color_100; break;
            //
            // Religious places
            case 200: font_size = 24; font_style = 'normal'; font_color = color_200; break;
            case 201: font_size = 12; font_style = 'normal'; font_color = color_200; break;
            case 202: font_size = 11; font_style = 'normal'; font_color = color_200; break;
            case 203: font_size = 10; font_style = 'normal'; font_color = color_200; break;
            case 204: font_size = 9; font_style = 'normal'; font_color = color_200; break;
            //
            // Mountains and alps
            case 300: font_size = 24; font_style = 'normal'; font_color = color_300; break;
            case 301: font_size = 18; font_style = 'normal'; font_color = color_300; break;
            case 302: font_size = 16; font_style = 'normal'; font_color = color_300; break;
            case 303: font_size = 12; font_style = 'normal'; font_color = color_300; break;
            case 304: font_size = 10; font_style = 'normal'; font_color = color_304; break;
            //
            // Hydrology
            case 400: font_size = 24; font_style = 'italic'; font_color = color_400; break;
            case 401: font_size = 14; font_style = 'italic'; font_color = color_400; break;
            case 402: font_size = 12; font_style = 'italic'; font_color = color_400; break;
            case 403: font_size = 10; font_style = 'italic'; font_color = color_400; break;
            case 404: font_size = 9; font_style = 'italic'; font_color = color_400; break;
            //
            // Buildings
            case 500: font_size = 24; font_style = 'italic'; font_color = color_500; break;
            case 501: font_size = 12; font_style = 'italic'; font_color = color_500; break;
            case 502: font_size = 11; font_style = 'italic'; font_color = color_500; break;
            case 503: font_size = 10; font_style = 'italic'; font_color = color_500; break;
            case 504: font_size = 9; font_style = 'italic'; font_color = color_500; break;
            //
            // Ways
            case 600: font_size = 24; font_style = 'normal'; font_color = color_600; break;
            case 601: font_size = 12; font_style = 'normal'; font_color = color_600; break;
            case 602: font_size = 10; font_style = 'normal'; font_color = color_600; break;
            case 603: font_size = 9; font_style = 'normal'; font_color = color_600; break;
            case 604: font_size = 8; font_style = 'normal'; font_color = color_600; break;
            //
            // Other places
            case 700: font_size = 24; font_style = 'normal'; font_color = color_700; break;
            case 701: font_size = 11; font_style = 'normal'; font_color = color_700; break;
            case 702: font_size = 10; font_style = 'normal'; font_color = color_700; break;
            case 703: font_size = 9; font_style = 'normal'; font_color = color_700; break;
            case 704: font_size = 8; font_style = 'normal'; font_color = color_700; break;
            //
            default:
                type = 0;
                break;
        }

        let font = font_style + ' ' + font_weight + ' ' + font_size + 'px ' + font_family;

        //console.log('  font = ' + font);

        let text = new Text({
            text: name,
            font: font,
            overflow: true,
            textAlign: 'center',
            textBaseline: 'middle',
            fill: new Fill({
                color: font_color,
            }),
            stroke: new Stroke({
                color: stroke_color,
                width: stroke_width,
            }),
        });

        if (is_selected) {
            //
            text.setBackgroundFill(new Fill({
                //color: 'rgba(255, 0, 0, 0.1)',
                color: this.selectionColor_(0.1),
            }));
            text.setBackgroundStroke(new Stroke({
                //color: 'rgba(255, 0, 0, 0.6)',
                color: this.selectionColor_(0.6),
                width: 1.5,
            }));
        }

        let zIndex = 1 - parseInt(type, 10) / 1000;
        //
        var style = new Style({
            //
            text: text,
            zIndex: zIndex,
        });

        return style;
    }

    static setPointStyle_(feature, is_selected) {
        //
        let style = this.newStyle_(feature, is_selected);
        //
        if (!style)
            return;

        feature.setStyle(style);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }
    static ___setPolygonStyle_(feature, is_selected) {
        //
        let style = this.newStyle_(feature, is_selected);
        //
        if (!style)
            return;

        let color = is_selected ? this.selectionColor_()
            : style.getText().getFill().getColor();

        var fill_color = [];
        //
        for (let i = 0; i < color.length; i++) {
            fill_color[i] = color[i];
        }
        //
        fill_color.push(is_selected ? 0.4 : 0.1);

        let stroke = new Stroke({
            color: color,
            width: is_selected ? 3.0 : 1.5
        });
        //
        style.setStroke(stroke);

        let fill = new Fill({
            color: fill_color,
        });
        //
        style.setFill(fill);

        feature.setStyle(style);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }
    static setPolygonStyle_(feature, is_selected) {
        //
        console.log('Attr_TOPO.setPolygonStyle_()...');

        // Get visibility tolerance from feature
        let visibility = feature.visibility;

        // Initialize main style
        let main_style = this.newStyle_(feature, is_selected);
        //
        if (!main_style)
            return;

        let main_color = is_selected ? this.selectionColor_()
            : main_style.getText().getFill().getColor();

        let type = feature.get('topo_type');
        //
        var fill_color = [];
        //
        if (type === 401) { // Glaciers
            //
            fill_color = [255, 255, 255];
            //
        } else {
            //
            for (let i = 0; i < main_color.length; i++)
                fill_color[i] = main_color[i];
            //
        }
        //
        fill_color.push(is_selected ? 0.4 : 0.1);

        let fill = new Fill({
            color: fill_color,
        });
        //
        main_style.setFill(fill);

        // Apply style depending of visibility
        if (!visibility) {
            //
            let stroke = new Stroke({
                color: main_color,
                width: is_selected ? 3.0 : 1.5
            });
            //
            main_style.setStroke(stroke);

            feature.setStyle(main_style);
            //
        } else {
            //
            let stroke = new Stroke({
                color: 'transparent',
                width: 0
            });
            //
            main_style.setStroke(stroke);

            var styleFunction = function (feature, resolution) {
                //
                var styles = [
                    main_style
                ];

                let feature_visibility_array = feature.get('gis_visibility');
                //
                if (feature_visibility_array) {
                    //
                    let feature_geometry = feature.getGeometry();
                    //
                    for (let n = 0; n < feature_geometry.getLinearRingCount(); n++) {
                        //
                        let feature_visibility = feature_visibility_array[n];
                        //
                        let coordinates = feature_geometry.getLinearRing(n).getCoordinates();

                        let visibility_on = null;
                        //
                        let line_string = null;

                        if (feature_visibility.length === coordinates.length) {
                            //
                            function feature_visible(idx_start, idx_end) {
                                //
                                if (feature_visibility[idx_start] >= visibility)
                                    return false;
                                //
                                if (feature_visibility[idx_end] >= visibility)
                                    return false;
                                //
                                return true;
                            }
                            //
                            function new_style(geometry, is_visible) {
                                //
                                let stroke = new Stroke({
                                    color: main_color,
                                    width: is_selected ? 3.0 : 1.5
                                });
                                //
                                if (!is_visible) {
                                    //
                                    /*
                                    stroke.setLineDash([4, 8]);
                                    stroke.setLineDash([3, 6]);
                                    */
                                    stroke.setLineDash([4, 8]);
                                }
                                //

                                let style = new Style({
                                    geometry: geometry,
                                    stroke: stroke,
                                });
                                //
                                return style;
                            }
                            //
                            for (let i = 1; i <= coordinates.length; i++) {
                                //
                                let idx_start = i - 1;
                                let idx_end = (i === coordinates.length) ? 0 : i;

                                let start = coordinates[idx_start];
                                let end = coordinates[idx_end];

                                if (i === 1) {
                                    //
                                    line_string = new LineString([start, end]);
                                    //
                                    visibility_on = feature_visible(i);
                                    //
                                    continue;
                                }

                                let is_visible = feature_visible(idx_start, idx_end);
                                //
                                if (is_visible === visibility_on) {
                                    //
                                    line_string.appendCoordinate(end);
                                    //
                                } else {
                                    //
                                    let style = new_style(line_string, visibility_on);
                                    //
                                    styles.push(style);

                                    line_string = new LineString([start, end]);
                                    //
                                    visibility_on = is_visible;
                                }

                                if (i === coordinates.length) {
                                    //
                                    let style = new_style(line_string, visibility_on);
                                    //
                                    styles.push(style);
                                }
                            }
                        }
                    }
                }

                return styles;
            }
            //
            feature.setStyle(styleFunction);
        }
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }
    static setPolylineStyle_(feature, is_selected) {
        //
        console.log('Attr_TOPO.setPolylineStyle_()...');

        // Get visibility tolerance from feature
        let visibility = feature.visibility;

        // Initialize main style
        let main_style = this.newStyle_(feature, is_selected);
        //
        if (!main_style)
            return;

        let main_color = is_selected ? this.selectionColor_()
            : main_style.getText().getFill().getColor();

        // Apply style depending of visibility
        if (!visibility) {
            //
            let stroke = new Stroke({
                color: main_color,
                width: is_selected ? 3.0 : 1.5
            });
            //
            main_style.setStroke(stroke);

            feature.setStyle(main_style);
            //
        } else {
            //
            let stroke = new Stroke({
                color: 'transparent',
                width: 0
            });
            //
            main_style.setStroke(stroke);

            var styleFunction = function (feature, resolution) {
                //

                var geometry = feature.getGeometry();

                //console.log('styleFunction: feature  = ', feature)
                //console.log('styleFunction: geometry = ', geometry);

                var styles = [
                    main_style
                ];

                let feature_visibility = feature.get('gis_visibility');
                //
                if (feature_visibility) {
                    //
                    let coordinates = geometry.getCoordinates();
                    //
                    let visibility_on = null;
                    //
                    let line_string = null;

                    if (feature_visibility.length === coordinates.length) {
                        //
                        function feature_visible(idx) {
                            //
                            if (feature_visibility[idx - 1] >= visibility)
                                return false;

                            if (feature_visibility[idx] >= visibility)
                                return false;

                            return true;
                        }
                        //
                        function new_style(geometry, is_visible) {
                            //
                            let stroke = new Stroke({
                                color: main_color,
                                width: is_selected ? 3.0 : 1.5
                            });
                            //
                            if (!is_visible) {
                                //
                                /*
                                stroke.setLineDash([4, 8]);
                                stroke.setLineDash([3, 6]);
                                */
                                stroke.setLineDash([4, 8]);
                            }
                            //
                            let style = new Style({
                                geometry: geometry,
                                stroke: stroke,
                            });
                            //
                            return style;
                        }
                        //
                        for (let i = 1; i < coordinates.length; i++) {
                            //
                            let start = coordinates[i - 1];
                            let end = coordinates[i];

                            if (i === 1) {
                                //
                                line_string = new LineString([start, end]);
                                //
                                visibility_on = feature_visible(i);
                                //
                                continue;
                            }

                            let is_visible = feature_visible(i);
                            //
                            if (is_visible === visibility_on) {
                                //
                                line_string.appendCoordinate(end);
                                //
                            } else {
                                //
                                let style = new_style(line_string, visibility_on);
                                //
                                styles.push(style);

                                line_string = new LineString([start, end]);
                                //
                                visibility_on = is_visible;
                            }

                            if (i === coordinates.length - 1) {
                                //
                                let style = new_style(line_string, visibility_on);
                                //
                                styles.push(style);
                            }
                        }
                    }
                }

                if (false)
                    geometry.forEachSegment(function (start, end) {

                        var dx = end[0] - start[0];
                        var dy = end[1] - start[1];
                        var rotation = Math.atan2(dy, dx);

                        // arrows

                        var white = [255, 255, 255, 1];
                        var blue = [0, 153, 255, 1];
                        var width = 3;

                        styles.push(
                            new Style({
                                geometry: new Point(end),

                                image: new Circle({
                                    radius: width * 2,
                                    fill: new Fill({
                                        color: blue
                                    }),
                                    stroke: new Stroke({
                                        color: white,
                                        width: width / 2
                                    })
                                }),
                                /*
                                image: new Icon({
                                    src: 'data/arrow.png',
                                    anchor: [0.75, 0.5],
                                    rotateWithView: true,
                                    rotation: -rotation,
                                }),
                                */
                            })
                        );
                    });

                return styles;
            }
            //
            feature.setStyle(styleFunction);
        }
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }
    static ____setMultiPolygonStyle_(feature, is_selected) {
        //
        function getMaxPoly(polys) {
            var polyObj = [];
            //now need to find which one is the greater and so label only this
            for (var b = 0; b < polys.length; b++) {
                polyObj.push({ poly: polys[b], area: polys[b].getArea() });
            }
            polyObj.sort(function (a, b) { return a.area - b.area });

            return polyObj[polyObj.length - 1].poly;
        }

        let style_func = function (feature, resolution) {
            //
            var polyStyleConfig = {
                stroke: new Stroke({
                    color: 'rgba(255, 255, 255, 1)',
                    width: 1
                }),
                fill: new Fill({
                    color: 'rgba(255, 0, 0,0.3)'
                })
            }
            var textStyleConfig = {
                text: new Text({
                    text: resolution < 100000 ? feature.get('name') : '',
                    fill: new Fill({ color: "#000000" }),
                    stroke: new Stroke({ color: "#FFFFFF", width: 2 })
                }),
                geometry: function (feature) {

                    let retPoint = getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                    /*
                    var retPoint;
                    if (feature.getGeometry().getType() === 'MultiPolygon') {
                        retPoint = getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                    } else if (feature.getGeometry().getType() === 'Polygon') {
                        retPoint = feature.getGeometry().getInteriorPoint();
                    }
                    */

                    console.log('AAAAAAAAAAAAAAAAAAAA: polygons = ', feature.getGeometry().getPolygons())
                    console.log('AAAAAAAAAAAAAAAAAAAA: feature = ', feature)
                    console.log('AAAAAAAAAAAAAAAAAAAA: retPoint = ', retPoint)

                    return retPoint;
                },
                /////////////////////////////////////////
                stroke: new Stroke({
                    color: 'rgba(255, 255, 255, 1)',
                    width: 1
                }),
                fill: new Fill({
                    color: 'rgba(255, 0, 0,0.3)'
                })

            }
            var textStyle = new Style(textStyleConfig);
            var style = new Style(polyStyleConfig);

            return [style, textStyle];
        }

        /*
        feature.setStyle(style_func);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;

        return;
        /**/

        /*
        style: function (feature, resolution) {
            var polyStyleConfig = {
                            stroke: new ol.style.Stroke({
                        color: 'rgba(255, 255, 255, 1)',
                        width: 1
                        }),
                    fill: new ol.style.Fill({
                        color: 'rgba(255, 0, 0,0.3)'
                    })
            }
            var textStyleConfig = {
            text:new ol.style.Text({
            text:resolution < 100000 ? feature.get('name') : '' ,
            fill: new ol.style.Fill({ color: "#000000" }),
            stroke: new ol.style.Stroke({ color: "#FFFFFF", width: 2 })
            }),
            geometry: function(feature){   
            var retPoint;
                if (feature.getGeometry().getType() === 'MultiPolygon') {
              retPoint =  getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
              } else if (feature.getGeometry().getType() === 'Polygon') {
              retPoint = feature.getGeometry().getInteriorPoint();
              }
              console.log(retPoint)
            return retPoint;
            }
            }
            var textStyle = new ol.style.Style(textStyleConfig);
            var style = new ol.style.Style(polyStyleConfig);
            return [style,textStyle];
        }
        */

        let style = this.newStyle_(feature, is_selected);
        //
        if (!style)
            return;

        let color = is_selected ? this.selectionColor_()
            : style.getText().getFill().getColor();

        var fill_color = [];
        //
        for (let i = 0; i < color.length; i++) {
            fill_color[i] = color[i];
        }
        //
        fill_color.push(is_selected ? 0.4 : 0.1);

        // Stroke
        let stroke = new Stroke({
            color: color,
            width: is_selected ? 3.0 : 1.5
        });
        //
        style.setStroke(stroke);

        // Fill
        let fill = new Fill({
            color: fill_color,
        });
        //
        style.setFill(fill);

        feature.setStyle(style);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;

        return;

        // Geometry
        function __geometryOf_(feature, resolution) {
            //
            let polygons = feature.getGeometry().getPolygons();
            //
            let objects = [];

            console.log('!!!!! geometryOf_: polygons = ', polygons);

            // Find which one is the greater and so label only this
            for (let i = 0; i < polygons.length; i++) {
                //
                objects.push({
                    poly: polygons[i],
                    area: polygons[i].getArea()
                });
            }

            objects.sort(function (p1, p2) {
                //
                return (p1.area - p2.area);
            });

            return objects[objects.length - 1].poly.getInteriorPoint();
        }
        //

        function geometryOf_(feature) {
            //
            let polygons = feature.getGeometry().getPolygons();

            var geometry = null;

            var widest = 0;

            for (var i = 0, ii = polygons.length; i < ii; ++i) {
                var polygon = polygons[i];
                var width = getWidth(polygon.getExtent());
                if (width > widest) {
                    widest = width;
                    geometry = polygon;
                }
            }

            console.log('!!!!!!geometryOf_:geometry = ', geometry);

            // Check if default label position fits in the view and move it inside if necessary
            geometry = geometry.getInteriorPoint();

            /*
            var size = map.getSize();
            var extent = map.getView().calculateExtent([size[0] - 12, size[1] - 12]);
            var textAlign = 'center';
            var coordinates = geometry.getCoordinates();
            if (!geometry.intersectsExtent(extent)) {
                geometry = new Point(fromExtent(extent).getClosestPoint(coordinates));
                // Align text if at either side
                var x = geometry.getCoordinates()[0];
                if (x > coordinates[0]) {
                    textAlign = 'left';
                }
                if (x < coordinates[0]) {
                    textAlign = 'right';
                }
            }
            */

            return geometry;
        }

        let style_2 = this.newStyle_(feature, is_selected);
        //
        style_2.setStroke(stroke);
        style_2.setFill(fill);
        //
        style_2.setGeometry(geometryOf_(feature));

        console.log('!!!!! feature.NAME = ', feature.get('topo_name'));
        console.log('!!!!! feature      = ', feature);
        console.log('!!!!! feature.getStyle(): (1) = ', feature.getStyle());
        //feature.setStyle([style_2, style_2]);
        //feature.setStyle([style, style_2]);
        //feature.setStyle(style_2);
        feature.setStyle(style);
        console.log('!!!!! feature      = ', feature);
        console.log('!!!!! feature.getStyle(): (2) = ', feature.getStyle());
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;

        //console.log('!!!!! geometryOf_: style (1) = ', style);
        //style.setGeometry(geometryOf_);
        //console.log('!!!!! geometryOf_: style (2) = ', style);

        /*
        function getGeometry_(polygons) {
            //
            var objects = [];
        
            // Find which one is the greater and so label only this
            for (let i = 0; i < polygons.length; i++) {
                //
                objects.push({
                    poly: polygons[i],
                    area: polygons[i].getArea()
                });
            }
        
            objects.sort(function (p1, p2) {
                //
                return (p1.area - p2.area);
            });
        
            return objects[objects.length - 1].poly.getInteriorPoint();
        }
                        //
                        //style.setg
        */

        feature.setStyle(style);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }
    static setMultiPolygonStyle_(feature, is_selected) {
        //
        //console.log('Attr_TOPO.setMultiPolygonStyle_()...');

        // Get visibility tolerance from feature
        let visibility = feature.visibility;

        // Initialize main style
        let main_style = this.newStyle_(feature, is_selected);
        //
        if (!main_style)
            return;

        let main_color = is_selected ? this.selectionColor_()
            : main_style.getText().getFill().getColor();

        let type = feature.get('topo_type');
        //
        var fill_color = [];
        //
        if (type === 401) { // Glaciers
            //
            fill_color = [255, 255, 255];
            //
        } else {
            //
            for (let i = 0; i < main_color.length; i++)
                fill_color[i] = main_color[i];
            //
        }
        //
        fill_color.push(is_selected ? 0.4 : 0.1);

        let fill = new Fill({
            color: fill_color,
        });
        //
        main_style.setFill(fill);

        // Apply style depending of visibility
        if (!visibility) {
            //
            let stroke = new Stroke({
                color: main_color,
                width: is_selected ? 3.0 : 1.5
            });
            //
            main_style.setStroke(stroke);

            feature.setStyle(main_style);
            //
        } else {
            //
            let stroke = new Stroke({
                color: 'transparent',
                width: 0
            });
            //
            main_style.setStroke(stroke);

            var styleFunction = function (feature, resolution) {
                //
                var styles = [
                    main_style
                ];

                let feature_visibility_array = feature.get('gis_visibility');
                //
                if (feature_visibility_array) {
                    //
                    let array_polygon = feature.getGeometry().getPolygons();
                    //
                    for (let p = 0; p < array_polygon.length; p++) {
                        //
                        let polygon = array_polygon[p];
                        let array_visibility = feature_visibility_array[p];

                        let feature_geometry = polygon;
                        //
                        for (let n = 0; n < feature_geometry.getLinearRingCount(); n++) {
                            //
                            let feature_visibility = array_visibility[n];
                            //
                            let coordinates = feature_geometry.getLinearRing(n).getCoordinates();

                            let visibility_on = null;
                            //
                            let line_string = null;

                            if (feature_visibility.length === coordinates.length) {
                                //
                                function feature_visible(idx_start, idx_end) {
                                    //
                                    if (feature_visibility[idx_start] >= visibility)
                                        return false;
                                    //
                                    if (feature_visibility[idx_end] >= visibility)
                                        return false;
                                    //
                                    return true;
                                }
                                //
                                function new_style(geometry, is_visible) {
                                    //
                                    let stroke = new Stroke({
                                        color: main_color,
                                        width: is_selected ? 3.0 : 1.5
                                    });
                                    //
                                    if (!is_visible) {
                                        //
                                        /*
                                        stroke.setLineDash([4, 8]);
                                        stroke.setLineDash([3, 6]);
                                        */
                                        stroke.setLineDash([4, 8]);
                                    }
                                    //

                                    let style = new Style({
                                        geometry: geometry,
                                        stroke: stroke,
                                    });
                                    //
                                    return style;
                                }
                                //
                                for (let i = 1; i <= coordinates.length; i++) {
                                    //
                                    let idx_start = i - 1;
                                    let idx_end = (i === coordinates.length) ? 0 : i;

                                    let start = coordinates[idx_start];
                                    let end = coordinates[idx_end];

                                    if (i === 1) {
                                        //
                                        line_string = new LineString([start, end]);
                                        //
                                        visibility_on = feature_visible(i);
                                        //
                                        continue;
                                    }

                                    let is_visible = feature_visible(idx_start, idx_end);
                                    //
                                    if (is_visible === visibility_on) {
                                        //
                                        line_string.appendCoordinate(end);
                                        //
                                    } else {
                                        //
                                        let style = new_style(line_string, visibility_on);
                                        //
                                        styles.push(style);

                                        line_string = new LineString([start, end]);
                                        //
                                        visibility_on = is_visible;
                                    }

                                    if (i === coordinates.length) {
                                        //
                                        let style = new_style(line_string, visibility_on);
                                        //
                                        styles.push(style);
                                    }
                                }
                            }
                        }
                    }
                }

                return styles;
            }
            //
            feature.setStyle(styleFunction);
        }
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;

        return;

        {
            function getMaxPoly(polys) {
                var polyObj = [];
                //now need to find which one is the greater and so label only this
                for (var b = 0; b < polys.length; b++) {
                    polyObj.push({ poly: polys[b], area: polys[b].getArea() });
                }
                polyObj.sort(function (a, b) { return a.area - b.area });

                return polyObj[polyObj.length - 1].poly;
            }

            let style_func = function (feature, resolution) {
                //
                var polyStyleConfig = {
                    stroke: new Stroke({
                        color: 'rgba(255, 255, 255, 1)',
                        width: 1
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 0, 0,0.3)'
                    })
                }
                var textStyleConfig = {
                    text: new Text({
                        text: resolution < 100000 ? feature.get('name') : '',
                        fill: new Fill({ color: "#000000" }),
                        stroke: new Stroke({ color: "#FFFFFF", width: 2 })
                    }),
                    geometry: function (feature) {

                        let retPoint = getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                        /*
                        var retPoint;
                        if (feature.getGeometry().getType() === 'MultiPolygon') {
                            retPoint = getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                        } else if (feature.getGeometry().getType() === 'Polygon') {
                            retPoint = feature.getGeometry().getInteriorPoint();
                        }
                        */

                        console.log('AAAAAAAAAAAAAAAAAAAA: polygons = ', feature.getGeometry().getPolygons())
                        console.log('AAAAAAAAAAAAAAAAAAAA: feature = ', feature)
                        console.log('AAAAAAAAAAAAAAAAAAAA: retPoint = ', retPoint)

                        return retPoint;
                    },
                    /////////////////////////////////////////
                    stroke: new Stroke({
                        color: 'rgba(255, 255, 255, 1)',
                        width: 1
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 0, 0,0.3)'
                    })

                }
                var textStyle = new Style(textStyleConfig);
                var style = new Style(polyStyleConfig);

                return [style, textStyle];
            }

            /*
            feature.setStyle(style_func);
            //
            feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
            feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    
            return;
            /**/

            /*
            style: function (feature, resolution) {
                var polyStyleConfig = {
                                stroke: new ol.style.Stroke({
                            color: 'rgba(255, 255, 255, 1)',
                            width: 1
                            }),
                        fill: new ol.style.Fill({
                            color: 'rgba(255, 0, 0,0.3)'
                        })
                }
                var textStyleConfig = {
                text:new ol.style.Text({
                text:resolution < 100000 ? feature.get('name') : '' ,
                fill: new ol.style.Fill({ color: "#000000" }),
                stroke: new ol.style.Stroke({ color: "#FFFFFF", width: 2 })
                }),
                geometry: function(feature){   
                var retPoint;
                    if (feature.getGeometry().getType() === 'MultiPolygon') {
                  retPoint =  getMaxPoly(feature.getGeometry().getPolygons()).getInteriorPoint();
                  } else if (feature.getGeometry().getType() === 'Polygon') {
                  retPoint = feature.getGeometry().getInteriorPoint();
                  }
                  console.log(retPoint)
                return retPoint;
                }
                }
                var textStyle = new ol.style.Style(textStyleConfig);
                var style = new ol.style.Style(polyStyleConfig);
                return [style,textStyle];
            }
            */

            let style = this.newStyle_(feature, is_selected);
            //
            if (!style)
                return;

            let color = is_selected ? this.selectionColor_()
                : style.getText().getFill().getColor();

            var fill_color = [];
            //
            for (let i = 0; i < color.length; i++) {
                fill_color[i] = color[i];
            }
            //
            fill_color.push(is_selected ? 0.4 : 0.1);

            // Stroke
            let stroke = new Stroke({
                color: color,
                width: is_selected ? 3.0 : 1.5
            });
            //
            style.setStroke(stroke);

            // Fill
            let fill = new Fill({
                color: fill_color,
            });
            //
            style.setFill(fill);

            feature.setStyle(style);
            //
            feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
            feature.setSelectStyle = Attr_TOPO.setSelectStyle;

            return;

            // Geometry
            function __geometryOf_(feature, resolution) {
                //
                let polygons = feature.getGeometry().getPolygons();
                //
                let objects = [];

                console.log('!!!!! geometryOf_: polygons = ', polygons);

                // Find which one is the greater and so label only this
                for (let i = 0; i < polygons.length; i++) {
                    //
                    objects.push({
                        poly: polygons[i],
                        area: polygons[i].getArea()
                    });
                }

                objects.sort(function (p1, p2) {
                    //
                    return (p1.area - p2.area);
                });

                return objects[objects.length - 1].poly.getInteriorPoint();
            }
            //

            function geometryOf_(feature) {
                //
                let polygons = feature.getGeometry().getPolygons();

                var geometry = null;

                var widest = 0;

                for (var i = 0, ii = polygons.length; i < ii; ++i) {
                    var polygon = polygons[i];
                    var width = getWidth(polygon.getExtent());
                    if (width > widest) {
                        widest = width;
                        geometry = polygon;
                    }
                }

                console.log('!!!!!!geometryOf_:geometry = ', geometry);

                // Check if default label position fits in the view and move it inside if necessary
                geometry = geometry.getInteriorPoint();

                /*
                var size = map.getSize();
                var extent = map.getView().calculateExtent([size[0] - 12, size[1] - 12]);
                var textAlign = 'center';
                var coordinates = geometry.getCoordinates();
                if (!geometry.intersectsExtent(extent)) {
                    geometry = new Point(fromExtent(extent).getClosestPoint(coordinates));
                    // Align text if at either side
                    var x = geometry.getCoordinates()[0];
                    if (x > coordinates[0]) {
                        textAlign = 'left';
                    }
                    if (x < coordinates[0]) {
                        textAlign = 'right';
                    }
                }
                */

                return geometry;
            }

            let style_2 = this.newStyle_(feature, is_selected);
            //
            style_2.setStroke(stroke);
            style_2.setFill(fill);
            //
            style_2.setGeometry(geometryOf_(feature));

            console.log('!!!!! feature.NAME = ', feature.get('topo_name'));
            console.log('!!!!! feature      = ', feature);
            console.log('!!!!! feature.getStyle(): (1) = ', feature.getStyle());
            //feature.setStyle([style_2, style_2]);
            //feature.setStyle([style, style_2]);
            //feature.setStyle(style_2);
            feature.setStyle(style);
            console.log('!!!!! feature      = ', feature);
            console.log('!!!!! feature.getStyle(): (2) = ', feature.getStyle());
            //
            feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
            feature.setSelectStyle = Attr_TOPO.setSelectStyle;

            //console.log('!!!!! geometryOf_: style (1) = ', style);
            //style.setGeometry(geometryOf_);
            //console.log('!!!!! geometryOf_: style (2) = ', style);

            /*
            function getGeometry_(polygons) {
                //
                var objects = [];
            
                // Find which one is the greater and so label only this
                for (let i = 0; i < polygons.length; i++) {
                    //
                    objects.push({
                        poly: polygons[i],
                        area: polygons[i].getArea()
                    });
                }
            
                objects.sort(function (p1, p2) {
                    //
                    return (p1.area - p2.area);
                });
            
                return objects[objects.length - 1].poly.getInteriorPoint();
            }
                            //
                            //style.setg
            */

            feature.setStyle(style);
            //
            feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
            feature.setSelectStyle = Attr_TOPO.setSelectStyle;
        }
    }
    static setMultiPolylineStyle_(feature, is_selected) {
        //
        //console.log('Attr_TOPO.setMultiPolylineStyle_()...');

        // Get visibility tolerance from feature
        let visibility = feature.visibility;

        // Initialize main style
        let main_style = this.newStyle_(feature, is_selected);
        //
        if (!main_style)
            return;

        let main_color = is_selected ? this.selectionColor_()
            : main_style.getText().getFill().getColor();

        if (!visibility) {
            //
            let stroke = new Stroke({
                color: main_color,
                width: is_selected ? 3.0 : 1.5
            });
            //
            main_style.setStroke(stroke);

            feature.setStyle(main_style);
            //
        } else {
            //
            let stroke = new Stroke({
                color: 'transparent',
                width: 0
            });
            //
            main_style.setStroke(stroke);

            var styleFunction = function (feature, resolution) {
                //
                var styles = [
                    main_style
                ];

                let array_visibility = feature.get('gis_visibility');
                //
                if (array_visibility) {
                    //
                    let array_polyline = feature.getGeometry().getLineStrings();
                    //
                    for (let n = 0; n < array_polyline.length; n++) {
                        //
                        let polyline = array_polyline[n];

                        let feature_visibility = array_visibility[n];
                        //
                        let coordinates = polyline.getCoordinates();
                        //
                        let visibility_on = null;
                        //
                        let line_string = null;

                        if (feature_visibility.length === coordinates.length) {
                            //
                            function feature_visible(idx) {
                                //
                                if (feature_visibility[idx - 1] >= visibility)
                                    return false;

                                if (feature_visibility[idx] >= visibility)
                                    return false;

                                return true;
                            }
                            //
                            function new_style(geometry, is_visible) {
                                //
                                let stroke = new Stroke({
                                    color: main_color,
                                    width: is_selected ? 3.0 : 1.5
                                });
                                //
                                if (!is_visible) {
                                    //
                                    /*
                                    stroke.setLineDash([4, 8]);
                                    stroke.setLineDash([3, 6]);
                                    */
                                    stroke.setLineDash([4, 8]);
                                }
                                //
                                let style = new Style({
                                    geometry: geometry,
                                    stroke: stroke,
                                });
                                //
                                return style;
                            }
                            //
                            for (let i = 1; i < coordinates.length; i++) {
                                //
                                let start = coordinates[i - 1];
                                let end = coordinates[i];

                                if (i === 1) {
                                    //
                                    line_string = new LineString([start, end]);
                                    //
                                    visibility_on = feature_visible(i);
                                    //
                                    continue;
                                }

                                let is_visible = feature_visible(i);
                                //
                                if (is_visible === visibility_on) {
                                    //
                                    line_string.appendCoordinate(end);
                                    //
                                } else {
                                    //
                                    let style = new_style(line_string, visibility_on);
                                    //
                                    styles.push(style);

                                    line_string = new LineString([start, end]);
                                    //
                                    visibility_on = is_visible;
                                }

                                if (i === coordinates.length - 1) {
                                    //
                                    let style = new_style(line_string, visibility_on);
                                    //
                                    styles.push(style);
                                }
                            }
                        }
                    }

                    return styles;
                }
            }
            //
            feature.setStyle(styleFunction);
        }
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;

        return;



        let style = this.newStyle_(feature, is_selected);
        //
        if (!style)
            return;

        let color = is_selected ? this.selectionColor_()
            : style.getText().getFill().getColor();

        let stroke = new Stroke({
            color: color,
            width: is_selected ? 3.0 : 1.5
        });
        //
        style.setStroke(stroke);

        console.log('   style = ', style);


        feature.setStyle(style);
        //
        feature.setDefaultStyle = Attr_TOPO.setDefaultStyle;
        feature.setSelectStyle = Attr_TOPO.setSelectStyle;
    }

    static setStyle_(feature, is_selected) {
        //
        let geo_type = feature.getGeometry().getType();

        if (geo_type === GeometryType.POINT) {
            //
            Attr_TOPO.setPointStyle_(feature, is_selected);
            //
            return;
        }

        if (feature.getGeometry().getType() === GeometryType.POLYGON) {
            //
            Attr_TOPO.setPolygonStyle_(feature, is_selected);
            //
            return;
        }

        if (feature.getGeometry().getType() === GeometryType.MULTI_POLYGON) {
            //
            Attr_TOPO.setMultiPolygonStyle_(feature, is_selected);
            //
            return;
        }

        if (feature.getGeometry().getType() === GeometryType.LINE_STRING) {
            //
            Attr_TOPO.setPolylineStyle_(feature, is_selected);
            //
            return;
        }

        if (feature.getGeometry().getType() === GeometryType.MULTI_LINE_STRING) {
            //
            Attr_TOPO.setMultiPolylineStyle_(feature, is_selected);
            //
            return;
        }

        alert('Warning setting TOPO style for ' + geo_type + ' not (yet) active!');
        //
        console.log('Attr_TOPO.setStyle_()...');
        console.log('  warning: style for ' + geo_type + ' not (yet) active!');
        //
        return;
    }
    //
    static getName_(feature) {
        //
        let topo_name = feature.get('topo_name');
        //
        if (!topo_name)
            return null;

        var name = '';

        let topo_article = feature.get('topo_article');
        //
        if (topo_article) {
            //
            name += topo_article;
            //
            if (topo_article[topo_article.length - 1] !== '\'')
                name += '&nbsp;';
        }

        name += topo_name;
        //
        return name;
    }
    static getNames_(feature) {
        //
        let topo_names = feature.get('topo_names');
        //
        if (!topo_names)
            return null;

        return topo_names.split(";");
    }
    //
    static nameOf_(name, index) {
        //
        let info = '<div><b>';
        //
        info += name;
        //
        if (index && (index > 0)) {
            //
            info += '<span style="float:right;">'
            info += index;
            info += '</span>'
        }
        //
        info += '</b></div>';
        //
        return info;
    }
    static namesOf_(names, index) {
        //
        let info = Attr_TOPO.nameOf_(names[0], index);
        //
        for (let i = 1; i < names.length; i++) {
            //
            info += Attr_TOPO.nameOf_(names[i], null);
        }
        //
        return info;
    }
    //
    static setInfo_(feature) {
        //
        // Get name(s)
        let names = Attr_TOPO.getNames_(feature);
        //
        let name = Attr_TOPO.getName_(feature);
        //
        if (!names && !name)
            return;

        // Get index
        let index = feature.get('topo_index');

        var info = '';
        //
        if (names) {
            //
            info += Attr_TOPO.namesOf_(names, index);
        }
        else {
            //
            info += Attr_TOPO.nameOf_(name, index);
        }

        // Description
        let description = feature.get('topo_description');
        //
        if (description) {
            //
            info += description;
        }

        feature.info = info;

        return;

        {

            let name = feature.get('topo_name');
            //
            if (!name)
                return;

            var info = '';

            // Article + name + index
            info += '<div><b>';

            let article = feature.get('topo_article');
            //
            if (article) {
                //
                info += article;
                //
                if (article[article.length - 1] !== '\'')
                    info += '&nbsp;';
            }

            info += name;

            let index = feature.get('topo_index');
            //
            if (index && (index > 0)) {
                //
                info += '<span style="float:right;">'
                info += index;
                info += '</span>'
            }

            info += '</b></div>';

            // Description
            let description = feature.get('topo_description');
            //
            if (description) {
                //
                info += description;
            }

            feature.info = info;
        }

        //console.log('  feature.info = ', feature.info);

        /*
            <div><b><a name="0">a Curzṓnas</a>&#9;0</b></div>
    
            <div><i>2'714'988, 1'144'968, 700</i></div>
            <div align="left">
                <font color="#000000"><i>CN25, CN50, CC, CSieg, CDuf Corzoneso</i></font>
            </div>
            <div align="left"><font color="#000000"><i>MDT III, 131 (1210) Cursonexe,
               133 (1211) Cruzonixi</i></font>
            </div>
            <div align="left"><font color="#000000">L'antico nucleo centrale
            che ha dato il nome alla <i>vicinia</i> e in seguito al comune,
             che dal 2004 in seguito all'aggregazione, fa parte di Acquarossa.
              Probabilmente da <i>curt</i>, 'corte'.</font>
            </div>
        */
    }
    static setVisibility_(feature, visibility) {
        //
        feature.visibility = visibility;
    }

    static setDefaultStyle(feature) {
        //
        /*
        console.log('Attr_TOPO.setDefaultStyle()...');
        console.log('  feature = ', feature);
        */
        //
        Attr_TOPO.setStyle_(feature, false);
    }
    static setSelectStyle(feature) {
        //
        /*
        console.log('Attr_TOPO.setSelectStyle()...');
        console.log('  feature = ', feature);
        */
        //
        Attr_TOPO.setStyle_(feature, true);
    }

    static setStyle(features, visibility) {
        //
        console.log('Attr_TOPO.setStyle()...');
        console.log('   visibility = ', visibility);
        //
        for (let i = 0; i < features.length; i++) {
            //
            /*
            console.log('  feature[' + i + ']:');
            //
            console.log('    description = ' + features[i].get('topo_description'));
            /**/
            //
            Attr_TOPO.setVisibility_(features[i], visibility);
            Attr_TOPO.setStyle_(features[i], false);
            Attr_TOPO.setInfo_(features[i], false);
        }
    }
}