import Popup from '../utils/Popup'
//
import './PopupContact.css';

export default class PopupContact extends Popup {

    constructor(map) {
        //
        //console.log('PopupContact: constructor...');
        //
        super('popupContact');

        this.map = map;

        this.email = null;
        this.subject = null;
        this.message = null;

        let div = null;
        let p = null;
        let i = null;
        let l = null;
        let b = null;

        //--------------------------------------------------------------
        // Main title
        //--------------------------------------------------------------
        super.setTitle("Contact to Historical Reality");
        //----------------------------------------------------------


        //----------------------------------------------------------
        // Title
        //----------------------------------------------------------
        /*
        div = document.createElement("div");
        //
        div.className = 'contact-title';
        div.innerHTML = "Contact to Historical Reality";
        //
        super.addToContent(div);
        */
        //----------------------------------------------------------
        // Content
        //----------------------------------------------------------
        div = document.createElement("div");
        //
        div.className = 'contact-content';

        // Description
        p = document.createElement("p");
        //
        p.innerHTML = "Any remark, feedback or suggestion are welcome!";
        //
        div.appendChild(p);

        super.addToContent(div);
        //----------------------------------------------------------
        // Message
        //----------------------------------------------------------
        div = document.createElement("div");
        //
        div.className = 'contact-message';

        // Email address
        l = document.createElement("label");
        //
        l.className = 'contact-label';
        //
        l.innerHTML = "Enter your email";
        //
        div.appendChild(l);

        i = document.createElement("input");
        //
        i.className = 'contact-input';
        //
        i.type = "email";
        //
        this.from = i;
        //
        div.appendChild(i);

        // Subject
        l = document.createElement("label");
        //
        l.className = 'contact-label';
        //
        l.innerHTML = "Subject";
        //
        div.appendChild(l);

        i = document.createElement("input");
        //
        i.className = 'contact-input';
        //
        i.type = "text";
        //
        this.subject = i;
        //
        div.appendChild(i);

        // Message
        l = document.createElement("label");
        //
        l.className = 'contact-label';
        //
        l.innerHTML = "Message";
        //
        div.appendChild(l);

        i = document.createElement("textarea");
        //
        i.className = 'contact-textarea';
        //
        i.rows = 5;
        //
        this.message = i;
        //
        div.appendChild(i);

        super.addToContent(div);
        //----------------------------------------------------------
        // Submit button
        //----------------------------------------------------------
        div = document.createElement("div");
        //
        div.className = 'contact-submit';

        // Button
        b = document.createElement("button");
        //
        b.innerHTML = "Submit";
        //
        b.popup = this;
        //
        b.onclick = this.onSubmit_;
        //
        div.appendChild(b);

        super.addToFooter(div);
        //super.addToContent(div);
        //----------------------------------------------------------
        // Email address
        //----------------------------------------------------------
        div = document.createElement("div");
        //
        div.className = 'contact-email';
        //
        var text = '';
        //
        text += 'If you have any problem with the submission, please send an email to: ';
        text += '<a href="mailto:contact@hr-1.ch">Historical Reality</a>'
        //
        div.innerHTML = text;
        //

        // Address
        p = document.createElement("p");
        //
        var text = '';
        //
        text += 'If you have any problem with the submission, please send an email to: ';
        text += '<a href="mailto:contact@hr-1.ch">Historical Reality</a>'
        //
        p.innerHTML = text;
        //
        //div.appendChild(p);

        super.addToFooter(div);
        //super.addToContent(div);
        //----------------------------------------------------------
    }

    inputOk_(input, text) {
        //
        if (input.value.trim() !== '')
            return true;

        alert('Error: please enter ' + text + '!');
        //
        input.focus();
        //
        return false;
    }

    onSubmit_(e) {
        //
        /*
        console.log('PopupContact.onSubmit_()...');
        console.log('  this = ', this);
        console.log('  e = ', e);
        console.log('  e.target = ', e.target);
        console.log('  e.target.popup = ', e.target.popup);
        */
        //
        let popup = e.target.popup;
        //
        if (!popup.inputOk_(popup.from, 'your email'))
            return;
        if (!popup.inputOk_(popup.subject, 'the subject'))
            return;
        if (!popup.inputOk_(popup.message, 'the message'))
            return;

        const email = {
            from: popup.from.value,
            subject: popup.subject.value,
            message: popup.message.value,
        };

        console.log('  sending mail to ' + email.from);

        const action = 'sendMailToContact';
        //
        var socket = popup.map.getSocket(); // SocketConnection
        //
        socket.socket.on(action, PopupContact.onSocketSubmit_);
        //
        const message = {
            //
            version: 1.0,
            email: email,
        };
        //
        socket.socket.emit(action, message);
    }

    static onSocketSubmit_(message) {
        //
        console.log('PopupContact.onSocketSubmit_: message = ', message);

        // Remove callback
        const action = 'sendMailToContact';
        //
        this.off(action, PopupContact.onSocketSubmit_);

        /*
        console.log('PopupContact.onSocketSubmit_: message = ' + message);
        //
        const version = message.version;
        //
        const ok = message.ok;

        console.log('PopupContact.onSocketSubmit_:');
        console.log('      version = ' + version);
        console.log('      ok      = ' + ok);
        /**/

        if (!message.ok) {
            //
            var msg = '';
            //
            if (message.msg)
                msg += message.msg;
            else
                msg += 'Could not submit the message: unknown error!';
            //
            console.log('msg = ', msg.toString());
            //
            alert(msg);
            //
            return;
        }

        alert('Message submitted. Thank you!');
    }
}