import React from 'react';
//
import './App.css';
//
import imageInfo from './components/controls/icons/Info.svg'
//
import MyReactMap from './components/Map';
import MyReactCamera from './components/Camera';


class MyFullscreen {

  static isSupported() {
    //
    const body = document.body;
    //
    return !!(
      body.webkitRequestFullscreen ||
      (body.msRequestFullscreen && document.msFullscreenEnabled) ||
      (body.requestFullscreen && document.fullscreenEnabled)
    );
  }

  static isOn() {
    //
    return !!(
      document.webkitIsFullScreen || document.msFullscreenElement || document.fullscreenElement
    );
  }
  static isOff() {
    //
    return !this.isOn();
  }

  static request(element) {
    //
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
  }
  static exit() {
    //
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
}

function App() {

  var map_ = null;
  var camera_ = null;

  function getMap() {
    //
    return map_;
  }
  function getCamera() {
    //
    return camera_;
  }

  return (

    <div className="app">
      <
        MyReactCamera
        ref={(c) => camera_ = c}
      />
      <
        MyReactMap
        ref={(c) => map_ = c}
        getCamera={getCamera}
      />
    </div>

    /*
    <div id="title" className="title" >
      TITLE
    </div>
    <div id="attribution" class="attribution">
      ATTRIBUTION
    </div>
    <div id="position" class="position">
      POSITION
    </div>
    <div id="statusbar" class="statusbar">
      <div id="contact" class="contact button" title="Contact">
        c
      </div>
      <div id="info" class="info button" title="About Historical Reality">
        i
      </div>
      <div id="help" class="help button" title="Help">
        ?
      </div>
    </div>



    <div id="version" class="version">
        VERSION
    </div>


      <div id="info" class="info">
        <input
          id="info-button"
          type="image"
          class="info-button"
          src={imageInfo}
          title="About Historical Reality"
        />
      </div>


          onClick={() => showInfo()}

              onclick={alert('Hello world')}

      <
        new InfoButton
      />


       var btn_info = document.getElementById('info-button');
 
       var btn_zoom_all = document.createElement('input');
          //
          btn_zoom_all.className = 'zoom-button-zoom-all zoom-button';
          btn_zoom_all.title = 'Zoom all';
          btn_zoom_all.type = 'image';
          btn_zoom_all.src = imageZoomAll;
          //
          btn_zoom_all.addEventListener('click', this.handleZoomAll.bind(this), false);
          //
            *I*
  
  //          <button style="background: url(myimage.png)" ... />
  
  
        <div id="version" class="version">
          VERSION
        </div>
        <div id="info" class="info">
          I
        </div>
  */

    /*
        <div className="app">
          <FileDialogue
          />,
          <MyMap
          />
        </div>
    
        <MyMap
          displaytext="First Component Data"
        />
    
        <div className="map">
            <MyMap
              displaytext="First Component Data"
            />
        </div>
    
        <div className="App">
            <MyMap
            />
        </div>
    
        <div className="App">
            <MyMap
            />
        </div>
    */

    /*
        <div className="App">
          <div id="map" class="map">
            <MyMap
            />
        </div>
      </div>
    */
    /*
        <div className="App">
          <div id="map" class="map">
            <Map
            />
          </div>
    
          <div id="title">IMAGE TITLE</div>
          <div id="position" class="position"></div>
          <div id="attribution" class="attribution"></div>
        </div>
    */

  )
}

export default App;