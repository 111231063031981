import React from 'react';

//import 'ol-layerswitcher/dist/ol-layerswitcher.css'
//import LayerSwitcher from 'ol-layerswitcher';

//import './LayerSwitcher.css'
//import LayerSwitcher from './LayerSwitcher';

import './TestControl.css';

import { Control } from 'ol/control';

import LayerGroup from 'ol/layer/Group';

/**/
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import WMTS from 'ol/source/WMTS';
import TileJSON from 'ol/source/TileJSON';
import TileImage from 'ol/source/TileImage';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps';
import TileArcGISRest from 'ol/source/TileArcGISRest';
import ImageArcGISRest from 'ol/source/ImageArcGISRest'
//import ImageArcGISRest from 'ol/source/ImageArcGISRest';

//import { ImageArcGISRest } from 'ol/source';

//import {ImageArcGISRest, OSM} from 'ol/source';
//import ImageArcGISRest from 'ol/source/ImageArcGISRest';
/**/

import PopupPhoto from '../popup/PopupPhoto'
import LayerMngr from '../mngr/LayerMngr'
//import { url } from 'inspector';


function newPopoup_() {

  var element = document.createElement('div');
  //
  element.id = "myModal";
  //element.class = "modal";
  element.className = "modal";
  //
  element.style.display = "none";

  var content = document.createElement('div');
  //
  //content.class = "modal-content";
  content.className = "modal-content";

  var span = document.createElement("span");
  //
  span.className = "close";
  span.innerHTML = "&times;";
  //
  span.popup = element;
  //span.
  span.onclick = function (e) {
    //
    console.log('span.onclick(): e = ', e);
    console.log('  this = ', this);
    console.log('  this.popup = ', this.popup);

    this.parentElement.parentElement.style.display = "none";

    //this.popup.style.display = "none";

    //modal.style.display = "none";
  }

  window.onclick = function (event) {
    //
    if (event.target == element) {
      //
      element.style.display = "none";
    }

    /*
    if (event.target == modal) {
      modal.style.display = "none";
    }
    */
  }

  var p = document.createElement("p");
  //
  p.innerHTML = "Some text in the Modal..";

  element.appendChild(content);
  content.appendChild(span);
  content.appendChild(p);

  return element;
}

function newLayer_OSM(layer_mngr) {
  //
  let main_group_bg_layers = layer_mngr.mainGroupBgLayers();
  //
  let group_entry = main_group_bg_layers.insertGroup('OSM');

  var layer = new TileLayer({
    visible: true,
    source: new OSM(),
  });
  //
  let layer_entry = group_entry.insertLayer(layer, 'OSM');
}
function newLayer_Bing(layer_mngr) {
  //
  let main_group_bg_layers = layer_mngr.mainGroupBgLayers();
  //
  let group_entry = main_group_bg_layers.insertGroup('Bing');

  var bing_layer = [
    {
      title: "Road",
      type: 'RoadOnDemand'
    },
    {
      title: "Aerial",
      type: 'Aerial'
    },
    {
      title: "Aerial With labels",
      type: 'AerialWithLabelsOnDemand'
    },
    {
      title: "Canvas dark",
      url: 'CanvasDark'
    },
    {
      title: "Ordnance survey",
      url: 'OrdnanceSurvey'
    },
  ];
  //
  for (let i = 0; i < bing_layer.length; ++i) {
    //
    let layer = new TileLayer({
      visible: false,
      preload: Infinity,
      source: new BingMaps({
        key: '  qiQnkZNZLZzhZnCs0btD~R8L93LURnO2mn0j5mgJqAQ~AhD3iSwzw_KVO6_C3pokew8WnSMEL_VgJde3GmsTOoZtJ_hbYh2ZLYMw_G2SlbTY',
        imagerySet: bing_layer[i].type,
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        // maxZoom: 19
      })
    });
    //
    let layer_entry = group_entry.insertLayer(layer, bing_layer[i].title);
  }
}
function newLayer_MapTiler(layer_mngr) {
  //
  let main_group_bg_layers = layer_mngr.mainGroupBgLayers();
  //
  let group_entry = main_group_bg_layers.insertGroup('MapTiler');

  var maptiler_layer = [
    {
      title: "Basic",
      url: 'https://api.maptiler.com/maps/basic/?key=f1fpTyCbPrgrDXAx92SE#1.0/0.00000/0.00000'
    },
    {
      title: "Satellite",
      url: 'https://api.maptiler.com/tiles/satellite/?key=f1fpTyCbPrgrDXAx92SE#'
    },
    {
      title: "Satellite Hybrid",
      url: 'https://api.maptiler.com/tiles/terrain-rgb/tiles.json?key=f1fpTyCbPrgrDXAx92SE'
    },
    {
      title: "Topographique",
      url: 'https://api.maptiler.com/maps/topographique/?key=f1fpTyCbPrgrDXAx92SE#1.0/0.00000/0.00000'
    },
  ];
  //
  for (let i = 0; i < maptiler_layer.length; ++i) {
    //
    let layer = new TileLayer({
      visible: false,
      source: new TileJSON({
        url: maptiler_layer[i].url,
        tileSize: 256,
        crossOrigin: 'anonymous'
      })
    });
    //
    let layer_entry = group_entry.insertLayer(layer, maptiler_layer[i].title);
  }
}
function newLayer_Google(layer_mngr) {
  /*
    //
    let main_group_bg_layers = layer_mngr.mainGroupBgLayers();
    //
    let group_entry = main_group_bg_layers.insertGroup('Google');
  
    var google_layer = [
      {
        title: "Roadmap",
        type: google.maps.MapTypeId.ROADMAP
      },
      {
        title: "Satellite",
        type: google.maps.MapTypeId.SATELLITE
      },
      {
        title: "Hybrid",
        type: google.maps.MapTypeId.HYBRID
      },
      {
        title: "Terrain",
        type: google.maps.MapTypeId.TERRAIN
      },
    ];
    //
    for (let i = 0; i < google_layer.length; ++i) {
      //
      let layer = new ol.layer.Vector({
        title: google_layer[i].title,
        type: "base",
        MapTypeId: google_layer[i].type,
        source: new ol.source.Vector({})
      });
      //
      let layer_entry = group_entry.insertLayer(layer);
    }
  */
}

function ____newLayer_swissIMAGE() {
  //
  //var parser = new WMTSCapabilities();



  let top = 1349839.300000;
  let left = 2419946.600000;
  let right = 2916103.900000;
  let bottom = 1027238.700000;
  //


  var extent = [420000, 30000, 900000, 350000];

  /*
  import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
  import WMTSCapabilities from 'ol/format/WMTSCapabilities';

  var parser = new WMTSCapabilities();
  var map;
  fetch('data/WMTSCapabilities.xml')
      .then(function (response) {
          return response.text();
      })
      .then(function (text) {
          var result = parser.read(text);
          var options = optionsFromCapabilities(result, {
              layer: 'layer-7328',
              matrixSet: 'EPSG:3857',
          });

          map = new Map({
              layers: [
                  new TileLayer({
                      source: new OSM(),
                      opacity: 0.7,
                  }),
                  new TileLayer({
                      opacity: 1,
                      source: new WMTS(options),
                  })],
              target: 'map',
              view: new View({
                  center: [19412406.33, -5050500.21],
                  zoom: 5,
              }),
          });
      });
  */

  /*
  1’349’839.300000 //top
  1’027’238.700000 // bottom
  2’419’946.600000 // left
  2’916’103.900000 // right
  */


  //https://wmts.geo.admin.ch/EPSG/2056/1.0.0/WMTSCapabilities.xml

}

function newLayers(layer_mngr) {
  //
  let main_group_bg_layers = layer_mngr.mainGroupBgLayers();
  //

  // OSM layers
  newLayer_OSM(layer_mngr);

  // Bing layers
  newLayer_Bing(layer_mngr);

  /*
  var layer_2 = new TileLayer({
    extent: extent,
    source: new TileWMS({
      url: 'https://wms.geo.admin.ch/',
      crossOrigin: 'anonymous',
      attributions:
        '© <a href="https://shop.swisstopo.admin.ch/en/products/maps/national/lk1000"' +
        'target="_blank">Pixelmap 1:1000000 / geo.admin.ch</a>',
      params: {
        'LAYERS': 'ch.swisstopo.pixelkarte-farbe-pk1000.noscale',
        'FORMAT': 'image/jpeg',
      },
      serverType: 'mapserver',
    }),
  });

  var layer_3 = new TileLayer({
    extent: extent,
    source: new TileWMS({
      url: 'https://wms.geo.admin.ch/',
      crossOrigin: 'anonymous',
      attributions:
        '© <a href="https://shop.swisstopo.admin.ch/en/products/maps/national/lk1000"' +
        'target="_blank">Pixelmap 1:1000000 / geo.admin.ch</a>',
      params: {
        'LAYERS': 'ch.swisstopo.pixelkarte-farbe-pk1000.noscale',
        'FORMAT': 'image/jpeg',
      },
      serverType: 'mapserver',
    }),
  });
  */

  /*
  new TileLayer({
    extent: extent,
    source: new TileWMS({
        url: 'https://wms.geo.admin.ch/',
        crossOrigin: 'anonymous',
        attributions:
            '© <a href="https://shop.swisstopo.admin.ch/en/products/maps/national/lk1000"' +
            'target="_blank">Pixelmap 1:1000000 / geo.admin.ch</a>',
        params: {
            'LAYERS': 'ch.swisstopo.pixelkarte-farbe-pk1000.noscale',
            'FORMAT': 'image/jpeg',
        },
        serverType: 'mapserver',
    }),
  }),
  */

}

function newLayerSwitcher_OSM(layer_mngr) {
  //
  var layer_group = new LayerGroup({
    title: 'OSM',
    fold: 'open',
    layers: []
  });

  var layer = new TileLayer({
    title: 'Default',
    visible: true,
    source: new OSM(),
  });
  //
  layer_group.getLayers().push(layer);

  layer_mngr.getMap().getLayers().insertAt(0, layer_group);
  //layer_mngr.getMap().getLayers().push(layer_group);
}
function newLayerSwitcher_Bing(layer_mngr) {
  //
  var layer_group = new LayerGroup({
    title: 'Bing',
    fold: 'open',
    layers: []
  });

  var bing_layer = [
    {
      title: "Road",
      type: 'RoadOnDemand'
    },
    {
      title: "Aerial",
      type: 'Aerial'
    },
    {
      title: "Aerial With labels",
      type: 'AerialWithLabelsOnDemand'
    },
    {
      title: "Canvas dark",
      url: 'CanvasDark'
    },
    {
      title: "Ordnance survey",
      url: 'OrdnanceSurvey'
    },
  ];
  //
  for (let i = 0; i < bing_layer.length; ++i) {
    //
    let layer = new TileLayer({
      title: bing_layer[i].title,
      visible: false,
      preload: Infinity,
      source: new BingMaps({
        key: '  qiQnkZNZLZzhZnCs0btD~R8L93LURnO2mn0j5mgJqAQ~AhD3iSwzw_KVO6_C3pokew8WnSMEL_VgJde3GmsTOoZtJ_hbYh2ZLYMw_G2SlbTY',
        imagerySet: bing_layer[i].type,
        // use maxZoom 19 to see stretched tiles instead of the BingMaps
        // "no photos at this zoom level" tiles
        // maxZoom: 19
      })
    });
    //
    layer_group.getLayers().push(layer);
  }

  layer_mngr.getMap().getLayers().insertAt(0, layer_group);
  //layer_mngr.getMap().getLayers().push(layer_group);
}
function newLayerSwitcher_Census(layer_mngr) {
  //
  var layer_group = new LayerGroup({
    title: 'Census',
    fold: 'open',
    layers: []
  });

  var census_layer = [
    {
      title: "Districts",
      url: 'https://ons-inspire.esriuk.com/arcgis/rest/services/Census_Boundaries/Census_Merged_Local_Authority_Districts_December_2011_Boundaries/MapServer'
    },
    {
      title: "Wards",
      url: 'https://ons-inspire.esriuk.com/arcgis/rest/services/Census_Boundaries/Census_Merged_Wards_December_2011_Boundaries/MapServer'
    },
  ];
  //
  for (let i = 0; i < census_layer.length; ++i) {
    //
    let layer = new ImageLayer({
      title: census_layer[i].title,
      source: new ImageArcGISRest({
        ratio: 1,
        params: { LAYERS: 'show:0' },
        url: census_layer[i].url
      })
    });
    //
    layer_group.getLayers().push(layer);
  }

  layer_mngr.getMap().getLayers().insertAt(0, layer_group);
  //layer_mngr.getMap().getLayers().push(layer_group);
}
function newLayerSwitcher_MapTiler(layer_mngr) {
  //
  var layer_group = new LayerGroup({
    title: 'MapTiler',
    fold: 'open',
    layers: []
  });

  var layer = new TileLayer({
    title: 'Corzoneso 1900',
    visible: true,
    source: new TileJSON({
      url: 'https://api.maptiler.com/tiles/04d72705-c684-4503-b85c-71a4ef24a2e3/tiles.json?key=f1fpTyCbPrgrDXAx92SE',
      tileSize: 256,
      crossOrigin: 'anonymous'
    })
  });
  //
  layer_group.getLayers().push(layer);

  layer_mngr.getMap().getLayers().insertAt(0, layer_group);
  //layer_mngr.getMap().getLayers().push(layer_group);
}
function newLayerSwitcher_ArcGIS(layer_mngr) {
  //
  var layer_group = new LayerGroup({
    title: 'Corzoneso-1924',
    fold: 'open',
    layers: []
  });

  var layer = new TileLayer({
    title: 'Default',
    visible: true,
    source: new TileArcGISRest({
      ratio: 1,
      params: {},
      url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/Val_Gardena_3D_2019_12_21_M_D_WTL1/MapServer'
      //url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer'
    })

    /*
    var layer = new TileLayer({
    title: 'Default',
    visible: true,
    source: new TileArcGISRest({
      ratio: 1,
      params: {},
      url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer'
    })

    //layerUrl = "//" + location.hostname + "/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer/WMTS";
    var options = JSON.parse('{"serviceMode" : "KVP"}');
    layer = new WMTSLayer(layerUrl, options);
    */
    /*
TileArcGISRest

    source: new WMTS({
      url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer/WMTS'
    })
    */
    /*
    source: new TileArcGISRest({
      url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer'
    })
    */
    /*
    source: new ImageArcGISRest({
      ratio: 1,
      params: { LAYERS: 'show:0' },
      url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/1924___Corzoneso/MapServer'
    })
    */
  });
  //
  layer_group.getLayers().push(layer);

  layer_mngr.getMap().getLayers().insertAt(0, layer_group);
  //layer_mngr.getMap().getLayers().push(layer_group);
}

function newLayersSwitcher(layer_mngr) {
  //
  // OSM layers
  newLayerSwitcher_OSM(layer_mngr);
  //
  // Bing layers
  newLayerSwitcher_Bing(layer_mngr);
  //
  // MapTiler layers
  newLayerSwitcher_MapTiler(layer_mngr);
  //
  // ArcGIS layers
  //newLayerSwitcher_ArcGIS(layer_mngr);
}

export default class TestControl extends Control {

  constructor(opt_options) {

    console.log('TestControl: constructor...');

    var options = opt_options || {};

    var element = document.createElement('div');
    //
    element.className = 'test ol-unselectable ol-control';
    //
    super({ element: element, target: options.target });

    var button = document.createElement('button');
    //
    button.title = 'Test (debug)';
    button.innerHTML = 'T';
    //
    button.addEventListener('click', this.handleTest.bind(this), false);
    //
    element.appendChild(button);

    return;

    // Layer mngr
    this.layerMngr_ = new LayerMngr(element);

    // Layer switcher
    this.layerSwitcher_ = null;

    // Insert some layers
    //newLayers(this.layerMngr_);


    //element.appendChild(this.layerMngr_);
  }

  setMap(map) {
    //
    super.setMap(map);
    //
    //this.layerMngr_.setMap(map);
    //
    if (map) {
      //
      console.log('  map.getTargetElement() = ', map.getTargetElement());
      console.log('  map.getViewport     () = ', map.getViewport());
    }
  }

  handleTest(e) {

    console.log('hostname = "' + window.location.hostname + '"');
    //*
    if (window.location.hostname !== "localhost") {
      //
      alert('Test button: not active!');
      //
      return;
    }
    /**/

    return;

    this.Test_LayerSwitcher(); return;

    this.Test_LayerMngr(); return;

    TestControl.Test_3(this.getMap()); return;

    return;

    console.log('TestControl::handleTest');
    //
    this.getMap().askOpenPicture();

    TestControl.Test_1(); return;
    TestControl.Test_2(); return;
    TestControl.Test_2(this.getMap()); return;
  }

  Test_LayerSwitcher() {
    //
    console.log('Test_LayerSwitcher()...');

    newLayersSwitcher(this.layerMngr_);

    return;

    /*
    if (!this.layerSwitcher_) {
      //
      this.layerSwitcher_ = new LayerSwitcher({
        activationMode: 'click',
        startActive: true,
        groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
      });
      //
      this.getMap().addControl(this.layerSwitcher_);
    }
    */
  }

  Test_LayerMngr() {
    //
    //
    console.log('Test_LayerMngr()...');
    //
    //console.log('  map = ', map);

    //var mngr = new LayerMngr();
    //
    //console.log('  mngr = ', mngr);

    if (this.layerMngr_.isVisible())
      this.layerMngr_.hide();
    else
      this.layerMngr_.show();
  }

  static Test_1() {
    //
    //console.log('Test_1(): fs = ', fs);

    var dir = null;

    dir = process.env.PUBLIC_URL;
    dir = '/';

    console.log('Test_1(): dir = ', dir);

    const files = TestControl.getFilesOf(dir);
    //

    return;

    //
    console.log('Test_1(): files = ', files);

    /*
    function getFiles(dir, files_) {
      files_ = files_ || [];
      var files = fs.readdirSync(dir);
      for (var i in files) {
        var name = dir + '/' + files[i];
        if (fs.statSync(name).isDirectory()) {
          getFiles(name, files_);
        } else {
          files_.push(name);
        }
      }
      return files_;
    }

    console.log(getFiles('path/to/dir'))
    */
  }
  static Test_2(map) {
    //
    const popup = new PopupPhoto();
    //
    popup.appendTo(map.getViewport());
    //
    popup.show();
  }
  static Test_3(map) {
    //
    console.log('Test_3()...');
    //
    console.log('  map = ', map);

    const camera = map.getCamera();
    //
    console.log('  camera = ', camera);

    if (camera.isVisible())
      camera.hide();
    else
      camera.show();
  }
  static Test_4(map) {
    //
    console.log('Test_4()...');
    //
    //console.log('  map = ', map);

    //var mngr = new LayerMngr();
    //
    //console.log('  mngr = ', mngr);
  }
}