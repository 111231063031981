import VectorSource from 'ol/source/Vector';
import LayerGroup from 'ol/layer/Group';

export default class Utilities {

    // Traverse all layers and call function on each layer
    static forEachLayer(map, action) {
        //
        function check_(layer) {
            //
            if (layer instanceof LayerGroup) {
                //
                layer.getLayers().forEach(check_);
            } else {
                //
                action(layer);
            }
        }

        map.getLayers().forEach(function (layer) {
            //
            if (layer instanceof LayerGroup) {
                layer.getLayers().forEach(check_);
            } else {
                action(layer);
            }
        });

    }

    // Get layer containing feature
    static getFeatureLayer(map, feature) {
        //
        var layer_, layersToLookFor = [];
        /**
         * Populates array layersToLookFor with only
         * layers that have features
         */
        var check = function (layer) {
            //
            if (layer instanceof LayerGroup) {
                //
                layer.getLayers().forEach(check);
            } else {
                //
                var source = layer.getSource();
                //
                if (source instanceof VectorSource) {
                    //
                    var features = source.getFeatures();
                    //
                    if (features.length > 0) {
                        //
                        layersToLookFor.push({
                            layer: layer,
                            features: features
                        });
                    }
                }
            }
        }

        //loop through map layers
        map.getLayers().forEach(function (layer) {
            //
            if (layer instanceof LayerGroup) {
                layer.getLayers().forEach(check);
            } else {
                check(layer);
            }
        });

        //console.log('   aaa layersToLookFor = ', layersToLookFor);

        layersToLookFor.forEach(function (obj) {
            //
            var found = obj.features.some(function (f) {
                return (f === feature);
            });
            if (found) {
                //this is the layer we want
                layer_ = obj.layer;
            }
        });
        return layer_;
    };
}




