import React from 'react';

import NoSleep from 'nosleep.js';

import 'ol/ol.css';
import './Map.css';

import Map from 'ol/Map';
import View from 'ol/View';

import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';

import Point from 'ol/geom/Point';

import {
  Style,
  Circle,
  Fill,
  Stroke,
} from 'ol/style';

import {
  FullScreen,
  Zoom,
  Rotate,
} from 'ol/control';

import { getCenter } from 'ol/extent';

import Projection from 'ol/proj/Projection';
import { transform } from 'ol/proj';

import VectorSource from 'ol/source/Vector';
import XYZ from 'ol/source/XYZ';
import Static from 'ol/source/ImageStatic';
import ImageStatic from 'ol/source/ImageStatic';
//import Stamen from 'ol/source/Stamen';
import OSM, { ATTRIBUTION } from 'ol/source/OSM';
import TileArcGISRest from 'ol/source/TileArcGISRest';

//import Tile from 'ol/Tile';
//import ImageWrapper from 'ol/Image';

import VectorLayer from 'ol/layer/Vector';
import LayerGroup from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileJSON from 'ol/source/TileJSON';
//import ImageArcGISRest from 'ol/source/ImageArcGISRest';

import SocketConnection from './utils/SocketConnection';

import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import FullScreenControl from './controls/FullScreenControl';
import ZoomAllControl from './controls/ZoomAllControl';
import ZoomControl from './controls/ZoomControl';
import RotateControl from './controls/RotateControl';
import ImageControl from './controls/ImageControl';
import EditControl from './controls/EditControl';
import FileControl from './controls/FileControl';
import GpsControl from './controls/GpsControl';
import SessionControl from './controls/SessionControl';
import TestControl from './controls/TestControl';
import CameraControl from './controls/CameraControl';
import GoogleEarthControl from './controls/GoogleEarthControl';

import LayerMngr from './mngr/LayerMngr'

import PopupContact from './popup/PopupContact'
import PopupInfo from './popup/PopupInfo'
import PopupHelp from './popup/PopupHelp'
import PopupPictures from './popup/PopupPictures'
//
import PopupFeature from './popup/PopupFeature'

import { asArray } from 'ol/color';

/*
var os = require('os');
/**/
var path = require('path');

//var NoSleep = require('../js.lib/nosleep/NoSleep');
//var NoSleep = require('nosleep/NoSleep');

class __PopupFeature {

  constructor() {
    //
    var popup = document.createElement('div');
    //
    popup.className = 'ol-feature-popup';

    var popup_closer = document.createElement('a');
    //
    //newlink.setAttribute('href', '#');
    popup_closer.href = '#';
    //
    popup_closer.className = 'ol-feature-popup-closer';
    //
    popup.appendChild(popup_closer);

    var popup_content = document.createElement('div');
    //
    popup_content.className = 'ol-feature-popup-content';
    //
    popup.appendChild(popup_content);

    this.popup_ = popup;
    this.popup_closer_ = popup_closer;
    this.popup_content_ = popup_content;

    /*
    <div id="popup" class="ol-popup">
    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
    <div id="popup-content"></div>
    </div>
    */
  }

  setOverlay(overlay) {
    //
    var popup_closer = this.popup_closer_;
    //
    popup_closer.onclick = function () {
      //
      overlay.setPosition(undefined);
      popup_closer.blur();
      return false;
    };
    //
    overlay.setElement(this.popup_);
  }
  setContent(text) {
    //
    this.popup_content_.innerHTML = text;
  }
}


class MyMap extends Map {

  constructor(options) {
    //
    super(options);
    //
    this.socket_ = null;
    //
    this.camera_ = null;
    //
    this.pictures = null;
    //
    this.imageRect_ = null;
    //
    this.imageLayerMngr_ = null;
    //
    this.navigationMode_ = null;
    this.navigationPosition_ = null;
    //
    this.group_background_ = null;
    this.group_foreground_ = null;
    this.group_work_ = null;
    //
    this.layer_background_ = null;
    this.layer_foreground_ = null;
    this.layer_work_ = null;
    this.layer_tmp_ = null;
    this.layer_gps_ = null;
    //
    this.overlay_info_ = null;

    // Setup initial layers
    this.setupLayers();

    // Setup view
    this.setupView_();

    // Get elemets for output
    this.windowTitle = document.getElementById('title');
    this.windowAttribution = document.getElementById('attribution');
    this.windowMousePosition = document.getElementById('position');
    this.windowVersion = document.getElementById('version');
    this.windowContact = document.getElementById('contact');
    this.windowInfo = document.getElementById('info');
    this.windowHelp = document.getElementById('help');

    if (this.windowVersion)
      this.windowVersion.innerHTML = 'Hstorical Reality - V. 0.1';

    // Get parent element for popup windows
    let popupParent = this.getTargetElement();
    //let popupParent = this.getViewport(); // old version

    // Open pictures popup
    this.popupPictures = new PopupPictures(this);
    //
    this.popupPictures.appendTo(popupParent);

    // Feature info popup
    this.popupFeature_ = new PopupFeature();
    //
    this.popupFeature_.setOverlay(this.getInfoOverlay());

    // Init contact/info/help popup
    if (this.windowContact) {
      //
      this.popupContact = new PopupContact(this);
      //
      this.popupContact.appendTo(popupParent);
      //
      this.windowContact.popup = this.popupContact;
      //
      this.windowContact.onclick = this.showContact_;
    }
    if (this.windowInfo) {
      //
      this.popupInfo = new PopupInfo();
      //
      this.popupInfo.appendTo(popupParent);
      //
      this.windowInfo.popup = this.popupInfo;
      //
      this.windowInfo.onclick = this.showInfo_;
    }
    if (this.windowHelp) {
      //
      this.popupHelp = new PopupHelp();
      //
      this.popupHelp.appendTo(popupParent);
      //
      this.windowHelp.popup = this.popupHelp;
      //
      this.windowHelp.onclick = this.showHelp_;
    }

    // Controls
    this.image = new ImageControl();
    //
    this.full_screen = new FullScreenControl();
    this.rotate = new RotateControl();
    //
    this.zoom = new ZoomControl();
    this.zoom_all = new ZoomAllControl();
    //
    this.edit = new EditControl();
    this.file = new FileControl();
    //
    this.gps = new GpsControl();
    this.session = new SessionControl();
    //
    this.camera = new CameraControl();
    //
    this.google = new GoogleEarthControl();

    this.test = new TestControl();

    this.addControl(this.image);
    //
    this.addControl(this.full_screen);
    this.addControl(this.rotate);

    this.addControl(this.zoom_all);
    //this.addControl(this.zoom);
    //
    this.addControl(this.gps);
    //this.addControl(this.session);
    //
    this.addControl(this.camera);
    //
    this.addControl(this.google);
    //
    this.addControl(this.test);

    // Disable double click zoom
    let interactions = this.getInteractions();
    //
    for (let i = 0; i < interactions.getLength(); i++) {
      //
      let interaction = interactions.item(i);
      //
      if (interaction instanceof DoubleClickZoom) {
        //
        this.removeInteraction(interaction);
        break;
      }
    }

    // Navigation mode (to be called after controls initialization)
    this.setNavigationMode(false, true);

    // Get initialization data from server
    new SocketConnection(this, null);

    var noSleep = new NoSleep();
    //
    function enableNoSleep() {
      //
      console.log('MAIN: noSleep.enable()');
      //
      noSleep.enable();
      //
      document.removeEventListener('click', enableNoSleep, false);
    }
    // Enable wake lock (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener('click', enableNoSleep, false);

    // enable/disable stadby when page visibility change
    document.addEventListener("visibilitychange", function () {
      //
      if (document.visibilityState === 'visible')
        noSleep.enable();
      else
        noSleep.disable();
      //
      if (document.visibilityState === 'visible')
        console.log('MAIN -> visibilitychange: noSleep.enable()');
      else
        console.log('MAIN -> visibilitychange: noSleep.disable()');
    });
  }


  // Setup view
  setupView_() {
    //
    let map = this;
    let view = this.getView();
    //
    view.on('change:center', function () {
      //
      console.log('VIEW:change:center...');
      //
      map.updateURL_();
    });
    //
    view.on('change:zoom', function () {
      //
      console.log('VIEW:change:zoom...');
      //
      map.updateURL_();
    });
    //
    view.on('change:rotation', function () {
      //
      console.log('VIEW:change:rotation...');
      //
      map.updateURL_();
    });
  }

  // Setup initial layers
  newLayer_ArcGIS_() {
    //
    var layer = new TileLayer({
      title: 'Corzoneso - 1924',
      visible: true,
      source: new TileArcGISRest({
        ratio: 1,
        params: {},
        url: 'https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/Corzoneso_1924/MapServer',
      })
    });
    //
    console.log('  layer = ', layer);
    //
    return layer;
    /*
    https://tiles.arcgis.com/tiles/TobWdCQGhoLO4BT5/arcgis/rest/services/Corzoneso_1924/MapServer
    */
  }
  newLayer_Wikimedia() {
    //
    console.log('MyMap.newLayer_Wikimedia()...');
    //
    const image_title = 'Corzoneso - San Remigio';
    //
    const width = 800;
    const height = 533;
    //
    /*
    const extent = [0.00000000, -width, height, 0.00000000];

    const extent = [0, 0, width, height];
    //
    let projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });
    */

    let url = 'https://upload.wikimedia.org/wikipedia/commons/d/d8/San_Remigio_Corzoneso_%E2%80%93_08.jpg';
    //
    console.log('  url = "' + url + '"');

    {
      const extent = [0, 0, width, height];
      //
      var layer = new ImageLayer({
        title: image_title,
        type: 'base',
        visible: true,
        source: new Static({
          url: url,
          projection: 'PIXELS',
          imageExtent: extent,
        }),
      });
      //
      layer.setExtent(extent);
      //
      return layer;
    }
    {
      //
      const extent = [0.00000000, -width, height, 0.00000000];
      //
      var layer = new ImageLayer({
        title: image_title,
        type: 'base',
        visible: true,
        source: new ImageStatic({
          url: url,
          projection: 'PIXELS',
          imageExtent: extent,
        }),
      });
      //
      return layer;
    }

    {
      const extent = [0, 0, width, height];
      //
      let projection = new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent,
      });
      //
      var layer = new ImageLayer({
        title: image_title,
        type: 'base',
        visible: true,
        source: new Static({
          url: url,
          projection: projection,
          imageExtent: extent,
        }),
      });
      //
      return layer;
    }

    //
    return layer;
    /*
    <img crossorigin="anonymous" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/San_Remigio_Corzoneso_%E2%80%93_08.jpg/1280px-San_Remigio_Corzoneso_%E2%80%93_08.jpg" class="jpg mw-mmv-dialog-is-open" width="528" height="352">

    <img style="-webkit-user-select: none;margin: auto;cursor: 
    zoom-in;background-color: hsl(0, 0%, 90%);
    transition: background-color 300ms;" 
    src="https://upload.wikimedia.org/wikipedia/commons/d/d8/San_Remigio_Corzoneso_%E2%80%93_08.jpg" 
    width="545" height="363">

    */
  }
  newLayer_Corzoneso_1924_xyz_() {
    //
    console.log('MyMap.newLayer_Corzoneso_1924_xyz_()...');
    //
    const title = 'Corzoneso - 1924';
    //
    const uuid = '7cf8dcb0-8138-4500-8cd2-02e4a1556a83';
    //
    const width = 7050;
    const height = 4621;
    //
    const minZoom = 0;
    const maxZoom = 5;
    const maxResolution = 1.00000000;
    //
    const projection = "PIXELS";
    //
    const crossOrigin = "anonymous";
    //
    const extent = [0.00000000, -width, height, 0.00000000];

    let url = 'http://localhost:3000/';
    //
    url = url + uuid;
    //
    url = url + '/image/{z}/{x}/{y}.png';
    //
    console.log('  url = "' + url + '"');

    var resolutions = [];
    //
    for (var z = 0; z <= maxZoom; z++) {
      //
      resolutions.push(Math.pow(2, maxZoom - z) * maxResolution);
    }

    const tileGrid = new TileGrid({
      extent: extent,
      minZoom: minZoom,
      resolutions: resolutions,
    });

    var source = new XYZ({
      url: url,
      tileGrid: tileGrid,
      projection: projection,
      crossOrigin: crossOrigin,
    });

    var layer = new TileLayer({
      title: title,
      type: 'base',
      visible: true,
      source: source,
    });
    //
    layer.setExtent(extent);
    //
    console.log('  layer = ', layer);
    //
    return layer;

    /*
    "source": {
      "url": "/app/data/7cf8dcb0-8138-4500-8cd2-02e4a1556a83/image/{z}/{x}/{y}.png",
      "projection": "PIXELS",
      "crossOrigin": "anonymous",
      "minZoom": 0,
      "maxZoom": 5,
      "maxResolution": 1.00000000
    */
  }
  newLayer_Corzoneso_1924_static_() {
    //
    console.log('MyMap.newLayer_Corzoneso_1924_static_()...');
    //
    const image_title = 'Corzoneso - 1924';
    //
    /*
    const image_name = 'Corzoneso-Blenio_40_590.png';
    const image_name = 'logo512.png';
    */
    const image_name = 'Corzoneso-Blenio_40_590.png';
    //
    const width = 7050;
    const height = 4621;
    //
    /*
    const extent = [0.00000000, -width, height, 0.00000000];

    const extent = [0, 0, width, height];
    //
    let projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });
    */
    const extent = [0, 0, width, height];
    //
    let projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });

    let url = 'http://localhost:3000/';
    //
    //url = url + '/public/';
    //
    url = url + image_name;
    //
    console.log('  url = "' + url + '"');

    var layer = new ImageLayer({
      title: image_title,
      type: 'base',
      visible: true,
      source: new Static({
        url: url,
        projection: projection,
        imageExtent: extent,
      }),
    });
    //
    layer.setExtent(extent);
    //
    return layer;
  }
  newLayer_Corzoneso_1924_image_static_() {
    //
    console.log('MyMap.newLayer_Corzoneso_1924_static_()...');
    //
    const image_title = 'Corzoneso - 1924';
    //
    /*
    const image_name = 'Corzoneso-Blenio_40_590.png';
    const image_name = 'logo512.png';
    */
    const image_name = 'Corzoneso-Blenio_40_590.png';
    //
    const width = 7050;
    const height = 4621;
    //
    /*
    const extent = [0.00000000, -width, height, 0.00000000];

    const extent = [0, 0, width, height];
    //
    let projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });
    */
    const extent = [0, 0, width, height];
    //
    let projection = new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: extent,
    });

    let url = 'http://localhost:3000/';
    //
    //url = url + '/public/';
    //
    url = url + image_name;
    //
    console.log('  url = "' + url + '"');

    var layer = new ImageLayer({
      title: image_title,
      type: 'base',
      visible: true,
      source: new ImageStatic({
        url: url,
        projection: 'PIXELS',
        imageExtent: extent,
      }),
    });
    //
    return layer;
  }
  //
  newLayer_Background_(view) {
    //
    console.log('MyMap.newLayer_Background_()...');
    //
    var layer_group = new LayerGroup({
      title: 'Base image',
      fold: 'open',
      layers: []
    });

    /*
    var layer = null;
    //
    if (layer == null)
      layer = this.newLayer_Corzoneso_1924_xyz_();
    //
    if (layer == null)
      layer = this.newLayer_Corzoneso_1924_static_();
    //
    if (layer == null)
      layer = this.newLayer_Wikimedia();
    //
    if (layer == null)
      layer = this.newLayer_ArcGIS_();
    //
    if (layer == null)
      layer = this.newLayer_Corzoneso_1924_image_static_();
    //
    //
    console.log('  layer = ', layer);
    /*/
    var layer = new TileLayer({
      title: 'OSM',
      type: 'base',
      visible: true,
      source: new OSM(),
    });
    /**/
    //
    console.log('  layer = ', layer);
    console.log('  view  = ', view);
    //
    /*
    let Bern = [7.444608, 46.947922]; // EPSG:4326
    */
    let center_Bern = transform([7.444608, 46.947922], 'EPSG:4326', view.getProjection());
    //
    let extent_CH = [5.9700, 45.8300, 10.4900, 47.8100]; // EPSG:4326
    //
    let center_CH = transform(getCenter(extent_CH), 'EPSG:4326', view.getProjection())
    //
    /*
    let center = center_Bern;
    let center = center_CH;
    */
    let center = center_CH;

    view.setCenter(center);
    view.setZoom(8);
    //
    this.group_background_ = layer_group;
    this.layer_background_ = layer;

    layer_group.getLayers().push(layer);
    //
    this.getLayers().insertAt(0, layer_group);
  }
  newLayer_Foreground_() {
    //
    var layer_group = new LayerGroup({
      title: 'Overlays',
      fold: 'open',
      layers: []
    });
    //
    this.group_foreground_ = layer_group;
    //
    this.getLayers().insertAt(0, layer_group);

    return;

    var layer = new VectorLayer({
      title: 'Default',
      visible: true,
      //declutter: true,
      source: new VectorSource(),
    });
    //
    this.group_foreground_ = layer_group;
    this.layer_foreground_ = layer;

    layer_group.getLayers().push(layer);
    //
    this.getLayers().insertAt(0, layer_group);
  }
  newLayer_Work_() {
    //
    var layer_group = new LayerGroup({
      title: 'Work',
      fold: 'open',
      layers: []
    });

    var layer = new VectorLayer({
      title: 'Scratch',
      source: new VectorSource()
    });
    //
    this.group_work_ = layer_group;
    this.layer_work_ = layer;

    layer_group.getLayers().push(layer);
    //
    this.getLayers().insertAt(0, layer_group);

  }
  newLayer_Tmp_() {
    //
    var layer = new VectorLayer({
      source: new VectorSource()
    });
    //
    this.layer_tmp_ = layer;

    this.getLayers().insertAt(0, layer);
  }
  newLayer_GPS_() {
    //
    var layer = new VectorLayer({
      source: new VectorSource()
    });
    //
    this.layer_gps_ = layer;

    this.getLayers().insertAt(0, layer);
  }
  //
  newOverlay_Info_() {
    //
    var overlay = new Overlay({
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    //
    this.overlay_info_ = overlay;

    this.getOverlays().insertAt(0, overlay);
  }
  //
  setupLayers() {
    //
    this.getLayers().clear();
    this.getOverlays().clear();

    this.layerMngr_ = new LayerMngr();

    // Layers
    this.newLayer_GPS_();
    //
    this.newLayer_Tmp_();
    //
    this.newLayer_Work_();
    //
    this.newLayer_Foreground_();
    //
    this.newLayer_Background_(this.getView());

    // Overlays
    this.newOverlay_Info_();

    this.layerMngr_.setMap(this);
  }
  resetLayers() {
    //
    this.group_foreground_.getLayers().clear();

    //this.layerMngr_.hide();
    //
    //this.layerMngr_.setupLayers(this.socket_.uuid());

    //this.layer_foreground_.getSource().clear();
    this.layer_work_.getSource().clear();
  }
  updateLayers() {
    //
    if (!this.socket_)
      return false;

    this.layerMngr_.setupLayers(this.socket_.uuid());

    return;

    let uuid = this.socket_.uuid();

    let path_root = '/app/data/' + uuid + '/';

    this.layerMngr_.setupLayers(path_root + 'visible.json.pix');

    //this.layer_foreground_.getSource().clear();
    //this.layer_work_.getSource().clear();
  }
  //
  insertWorkLayer(layer) {
    //
    this.group_work_.getLayers().insertAt(0, layer);

    //this.group_foreground_.getLayers().push(layer);
  }
  insertForegroungLayer(layer) {
    //
    this.group_foreground_.getLayers().insertAt(0, layer);

    //this.group_foreground_.getLayers().push(layer);
  }

  // URL management
  __getView_URL_() {
    //
    const view = this.getView();
    //
    const view_data = {
      center: view.getCenter(),
      rotation: view.getRotation(),
      resolution: view.getResolution(),
    }
    //
    return view_data;
  }
  __setView_URL_(view_data) {
    //
    let view = this.getView();
    //
    view.setCenter(view_data.center);
    view.setRotation(view_data.rotation);
    view.setResolution(view_data.resolution);
    //
    return
  }

  uuid2url_() {
    //
    if (!this.socket_)
      return "";

    console.log('MyMap:uuid2url_(): this.socket_.uuid() = ' + this.socket_.uuid());

    let uuid = this.socket_.uuid();
    //
    if (!uuid)
      return "";

    let str = '';
    //
    str += '&'
    str += 'image=' + uuid;
    //
    return str;
  }
  url2uuid_(url) {
    //
    let uuid = url.searchParams.get('image');
    //
    console.log('MyMap:url2uuid_(): uuid = ', uuid);

    const data = this.pictures.find(function (data) {
      //
      return (data.uuid === uuid);
    });
    //
    if (!data) {
      //
      console.log('MyMap:url2uuid_(): warning: picture for ' + uuid + ' not found!');
      //
      return false;
    }

    const obj = data.object;

    console.log('MyMap:url2uuid_(): open picture:', obj);
    console.log('    obj :', obj);

    new SocketConnection(this, obj);
  }

  view2url_() {
    //
    const view = this.getView();
    //
    let str = '';
    //
    str += '&'
    str += 'center=' + view.getCenter();
    str += '&'
    str += 'rotation=' + view.getRotation();
    str += '&'
    str += 'resolution=' + view.getResolution();
    //
    return str;
  }
  url2view_(url) {
    //
    let view = this.getView();

    let do_zoom_all = true;

    let center = url.searchParams.get('center');
    //
    if (center) {
      //
      console.log('  center.split(', ') = ', center.split(','));
      //
      do_zoom_all = false;
      //
      view.setCenter(center.split(','));
    }

    let rotation = url.searchParams.get('rotation');
    //
    if (rotation) {
      //
      do_zoom_all = false;
      //
      view.setRotation(rotation);
    }

    let resolution = url.searchParams.get('resolution');
    //
    if (resolution) {
      //
      do_zoom_all = false;
      //
      view.setResolution(resolution);
    }

    let uuid = this.socket_.uuid();
    //
    if (!uuid)
      return;

    if (do_zoom_all) {
      //
      view.fit(this.getBackground().getExtent(), this.getSize());
    }
  }

  mode2url_() {
    //
    let mode = (this.navigationMode_ === true) ? 1 : 2;
    //
    let str = '';
    //
    str += '&'
    str += 'mode=' + mode;
    //
    return str;
  }
  url2mode_(url) {
    //
    let mode = url.searchParams.get('mode');
    //
    if (mode === null)
      return;

    if (mode === 1) {
      //
      this.setNavigationMode(true);
    }
    else if (mode === 2) {
      //
      this.setNavigationMode(false);
    }
    else {
      //
      console.log('MyMap:url2mode_(): warning: unknown view mode ' + mode);
    }
  }

  layers2url_() {
    //
    return this.layerMngr_.layers2url();

    let str = '';
    //
    /*
    str += '&'
    str += 'mode=' + mode;
    */
    //
    return str;

  }
  url2layers_(url) {
    //
    this.layerMngr_.url2layers(url);
  }

  updateURL_() {
    //
    console.log('MyMap:updateURL_()...');

    if (this.updating_from_url_)
      return;

    let session = {};
    //
    let url = window.location.origin;
    //
    console.log('  window.location (1) = ', window.location);
    //
    url += '/?'
    //
    url += 'version=1.0'

    url += this.uuid2url_();
    url += this.mode2url_();
    url += this.view2url_();
    url += this.layers2url_();

    console.log('  url = ', url);

    console.log('  window.location (2) = ', window.location.href);  // whatever your current location href is
    //
    window.history.replaceState({}, '', url);
    //
    console.log('  window.location (3) = ', window.location.href);

    return;

    window.location.href = url;

    return;

    url += '/' + JSON.stringify(this.getView_URL_());


    console.log('  url = ', url);
  }
  updateFromURL_() {
    //
    console.log('MyMap:updateFromURL_()...');

    // Get url
    let url = new URL(window.location.href);

    let version = url.searchParams.get('version');
    //
    if (!version)
      return false;

    //return false;

    if (version == '1.0') {
      //
      console.log('  version = ', version);
      //
      this.updating_from_url_ = true;
      //
      this.url2uuid_(url);
      this.url2mode_(url);
      this.url2view_(url);
      this.url2layers_(url);

      this.updating_from_url_ = false;
    }

    return true;
  }

  updateURL() {
    //
    this.updateURL_();
  }

  // Ok: true if a picture is open
  ok(display_message) {
    //
    /*
    console.log('MyMap.ok()...');
    console.log('  this.socket_ = ', this.socket_);
    console.log('  this.getBackground() = ', this.getBackground());
    /**/
    //
    const background = this.getBackground();
    //
    if (!background.session_data) {
      //
      if (display_message) {
        //
        alert('Warning: no image open!')
      }
      //
      return false;
    }

    return true;

    let all_ok = true;


    if (!this.socket_.ok()) {
      //
      console.log('!!!! NOT this.socket_.ok !!!!')
      //
      all_ok = false;
    }

    if (!this.getBackground()) {
      //
      console.log('!!!! this.getBackground() UNDEFINED !!!!')
    }
    else {
      //
      console.log('this.getBackground() OK !!')
    }


    return true;

  }
  // Camera
  setCamera(camera) {
    //
    this.camera_ = camera;
  }
  getCamera() {
    //
    return this.camera_;
  }

  // Show contact/info/help
  showContact_() {
    //
    console.log('MyMap.showContact_ = ', this);
    //
    this.popup.show();
  }
  showInfo_() {
    //
    console.log('MyMap.showInfo_ = ', this);
    //
    this.popup.show();
  }
  showHelp_() {
    //
    console.log('MyMap.showHelp_ = ', this);
    //
    this.popup.show();
  }

  // Pictures
  askOpenPicture() {
    //
    this.popupPictures.show();
  }

  // Title, attribution, position
  setTitle(title) {
    //
    if (this.windowTitle)
      this.windowTitle.innerHTML = title;
  }
  setAttribution(attribution) {
    //
    if (this.windowAttribution)
      this.windowAttribution.innerHTML = attribution;
  }
  setMousePosition(position) {
    //
    if (this.windowMousePosition)
      if (position)
        this.windowMousePosition.innerHTML = position;
      else
        this.windowMousePosition.innerHTML = '&#xA0';
  }

  // Background/foreground layers
  getBackground() {
    //
    return this.layer_background_;

    return this.getLayers().item(0);
  }
  getForeground() {
    //
    return this.layer_foreground_;

    return this.getLayers().item(1);
  }
  getWorkLayer() {
    //
    return this.layer_work_;

    return this.getLayers().item(3);
  }
  getTmpLayer() {
    //
    return this.layer_tmp_;
  }
  getGpsLayer() {
    //
    return this.layer_gps_;
  }

  // Overays
  getInfoOverlay() {
    //
    return this.overlay_info_;

    return this.getOverlays().item(0);
  }

  // Popup with feature information
  featureInfoOn() {
    //
    var overlay = this.getInfoOverlay();
    //
    if (!overlay) {
      //
      return undefined;
    }

    return overlay.getPosition();
  }
  showFeatureInfo(pos, text) {
    //
    if (!this.popupFeature_) {
      //
      console.log('MyMap:showFeatureInfo(): PopupFeature undefined!');
      //
      return;
    }

    var overlay = this.getInfoOverlay();
    //
    if (!overlay) {
      //
      console.log('MyMap:showFeatureInfo(): InfoOverlay undefined!');
      //
      return;
    }

    console.log('  text = ', text);

    this.popupFeature_.setContent(text);
    //
    overlay.setPosition(pos);

    /*
    content.innerHTML = '<p>You clicked here:</p><code>' + hdms +
      '</code>';
    overlay.setPosition(coordinate);
    */
  }
  hideFeatureInfo() {
    //
    var overlay = this.getInfoOverlay();
    //
    if (!overlay) {
      //
      console.log('MyMap:hideFeatureInfo(): InfoOverlay undefined!');
      //
      return;
    }

    overlay.setPosition(undefined);
  }

  // Available pictures: called from SocketConnection just after startup
  //   view_mode  1 -> navigation
  //              2 -> webGIS
  openPictureFromUuid_(uuid, view_mode) {
    //
    if (!uuid)
      return false;

    console.log('MyMap:openPictureFromUuid_(): uuid = ', uuid);

    const data = this.pictures.find(function (data) {
      //
      return (data.uuid === uuid);
    });
    //
    if (!data) {
      //
      console.log('MyMap:openPictureFromUuid_(): warning: picture for ' + uuid + ' not found!');
      //
      return false;
    }

    const obj = data.object;

    console.log('MyMap:openPictureFromUuid_(): open picture:', obj);
    console.log('    obj :', obj);
    console.log('    mode:', view_mode);

    new SocketConnection(this, obj);
    //
    if (view_mode == 1) {
      //
      this.setNavigationMode(true);
    }
    else if (view_mode == 2) {
      //
      this.setNavigationMode(false);
    }
    else {
      //
      console.log('MyMap:openPictureFromUuid_(): warning: unknown view mode ' + view_mode)
    }

    return true;
  }
  //
  setAvailablePictures(pictures) {
    //
    this.pictures = pictures;
    //
    this.popupPictures.setPictures(pictures);

    // Update from URL
    if (this.updateFromURL_())
      return;

    let uuid = this.socket_.uuid();
    //
    if (!uuid) {
      //
      this.askOpenPicture();
    }

    return;

    {
      // Get url
      let url = new URL(window.location.href);

      // Check if url contains picture to open
      let uuid = url.searchParams.get('image');
      //
      console.log('MyMap:setAvailablePictures(): uuid = ', uuid);

      // Check view mode
      let view_mode = 0;

      let navigation = url.searchParams.get('navigation'); // Very first version...
      //
      if (navigation) {
        //
        if (navigation === 'on') view_mode = 1;
        else view_mode = 2;
      }
      else {
        //
        let mode = url.searchParams.get('mode');
        //
        if (mode) {
          //
          view_mode = mode;
        }
      }

      console.log('MyMap:setAvailablePictures(): view_mode = ', view_mode);
      //
      if (!this.openPictureFromUuid_(uuid, view_mode))
        return;

      console.log('MyMap:setAvailablePictures(): uuid = ', uuid);

      // Check if url contains background data to open
      let background = url.searchParams.get('background');
      //
      if (background) {
        //
        console.log('MyMap:setAvailablePictures(): background = ', background);
        //
        let data = background.split(';');
        //
        for (let i = 0; i < data.length; i++) {
          //
          console.log('   background [' + i + ']= ', data[i]);
          //

        }

      }
    }
    // ...

    /*
    if (uuid) {
      //
      const obj = pictures.find(function (obj) {
        //
        return (obj.uuid == uuid);
      });
      //
      if (obj)
   
   
   
        var found = data.find(function (element) {
          return element < 12;
        });
   
    }
   
    console.log(parsedUrl.searchParams.get("id")); // "123"
   
    console.log('MyMap:setAvailablePictures(): url = ', url);
   
    //var query = url.parse(window.location.href, true).query;
   
    //console.log('MyMap:setAvailablePictures(): query = ', query);
   
    const link = window.location.href;
    //const parsedUrl = new URL(window.location.href)
   
    //console.log('MyMap:setAvailablePictures(): link = ', window.location.href);
    */

    /*
    var url = require('url');
   
        var query = url.parse(message, true).query;
    
        var img_name = query.img;
        var dtm_name = query.dtm;
    
        var x = parseFloat(query.x);
        var y = parseFloat(query.y);
        */

  }

  // Socket connection
  getSocket() {
    //
    return this.socket_;
  }
  setSocket(socket) {
    //
    this.socket_ = socket;
  }

  // Image rect
  getImageRect() {
    //
    return this.imageRect_;
  }
  setImageRect(rect) {
    //
    this.imageRect_ = rect;
  }

  // Navigation mode on/off
  updateNavigationControls_(mode) {
    //
    if (mode) {
      //
      this.removeControl(this.zoom);
      this.removeControl(this.edit);
      this.removeControl(this.file);
      this.removeControl(this.session);
    }
    else {
      //
      this.addControl(this.zoom);
      this.addControl(this.edit);
      this.addControl(this.file);
      this.addControl(this.session);
    }
    //
    this.gps.updateGpsButtons();
  }
  //
  getNavigationMode() {
    //
    return this.navigationMode_;
  }
  setNavigationMode(mode, on_init = false) {
    //
    if (on_init) {
      //
      this.navigationMode_ = mode;
      //
      this.updateNavigationControls_(mode);
      //
      this.navigationPosition_ = this.newPositionFeature_();
      //
      return;
    }

    var socket = this.getSocket();
    //
    if (!socket) {
      //
      if (this.navigationMode_) {
        //
        this.navigationMode_ = false;
        //
        this.updateNavigationControls_(false);
      }
      //
      return;
    }

    var source = this.getGpsLayer().getSource();
    //
    if (mode) {
      //
      if (!source.hasFeature(this.navigationPosition_))
        source.addFeature(this.navigationPosition_);
    }
    else {
      //
      if (source.hasFeature(this.navigationPosition_))
        this.getGpsLayer().getSource().removeFeature(this.navigationPosition_);
    }

    this.navigationMode_ = mode;
    //
    this.updateNavigationControls_(mode);
    //
    socket.setGps(mode);
    //
    this.updateURL_();
    //
    return true;
  }
  //
  newPositionFeature_() {
    //
    var positionFeature = new Feature();
    //
    positionFeature.setStyle(new Style({
      //
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: 'red'
          //          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        })
      })
    }));
    //
    return positionFeature;
  }
  //
  setNavigationPosition(position) {
    //
    const geometry = new Point(position);
    //
    this.navigationPosition_.setGeometry(geometry);
  }

  _____setNavigationMode_(mode) {
    //
    var socket = this.getSocket();
    //
    if (!socket)
      return false;

    socket.setGps(mode);
    //
    return true;
  }
}

class MyReactMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      map: null,
      view: null,
    };
    //
    console.log('MyReactMap:constructor(): this.props = ', this.props)

    //const parsedUrl = new URL(window.location.href)

    console.log('MyReactMap:constructor(): url = ', window.location.href);

    //console.log(parsedUrl.searchParams.get('id'))
    //
    this.popup = null;
  }

  _____newControls() {

    /*
    var zoom = new Zoom({
      className: "zoom",
    });
    */

    var full_screen = new FullScreen();
    var zoom_all = new ZoomAllControl();

    var zoom = new Zoom();
    var rotate = new Rotate();

    var image = new ImageControl();

    var gps = new GpsControl();
    var file = new FileControl();
    var edit = new EditControl();
    var session = new SessionControl();

    var test = new TestControl();

    return [full_screen, rotate, zoom, zoom_all, image, edit, file, gps, session, test];
    return [full_screen, rotate, zoom, zoom_all, image, edit, file, session];
  }

  newMapOSM() {

    var layer = new TileLayer({
      source: new OSM(),
    });

    var view = new View({
      center: [-11718716.28195593, 4869217.172379018], //Boulder
      zoom: 13,
    });

    var map = new Map({
      target: 'map',
      layers: [
        layer,
        //        featuresLayer
      ],
      view: view,
    });

    return map;
  }

  newMapIMG() {

    var imageWidth = 8333.00000000;
    var imageHeight = 5988.00000000;

    var mapExtent = [0.00000000, -imageHeight, imageWidth, 0.00000000];
    var mapMinZoom = 0;
    var mapMaxZoom = 6;
    var tileExtent = [0.00000000, -imageHeight, imageWidth, 0.00000000];
    var mapMaxResolution = 1.00000000;

    var mapResolutions = [];
    for (var z = 0; z <= mapMaxZoom; z++) {
      mapResolutions.push(Math.pow(2, mapMaxZoom - z) * mapMaxResolution);
    }

    var mapTileGrid = new TileGrid({
      extent: tileExtent,
      minZoom: mapMinZoom,
      resolutions: mapResolutions
    });

    var source = new TileJSON({
      url: 'https://api.maptiler.com/tiles/04d72705-c684-4503-b85c-71a4ef24a2e3/tiles.json?key=f1fpTyCbPrgrDXAx92SE',
      tileSize: 256,
      projection: 'PIXELS',
      crossOrigin: 'anonymous',
      //attributions: '<a href="http://www.archiviodonetta.ch">AAAAAAAAA Archivio Donetta</a>'
    });

    var layer = new TileLayer({
      source: source,
      extent: mapExtent,

      /*
      
            attributions: [
        '<a href="http://www.archiviodonetta.ch">Archivio Donetta</a>',
      ],
 
            source: new XYZ({
              projection: 'PIXELS',
              tileGrid: mapTileGrid,
              https: "//api.maptiler.com/tiles/04d72705-c684-4503-b85c-71a4ef24a2e3/{z}/{x}/{y}.png?key=f1fpTyCbPrgrDXAx92SE",
            })
      */
    });

    var view = new View({
      //      extent: mapExtent,
      constrainResolution: true,
    });

    var map = new Map({
      target: 'map',
      layers: [
        layer,
        //        featuresLayer
      ],
      view: view,
      controls: this.newControls(),
      /* 
            controls: defaultControls().extend([
              new OpenFileControl(),
              new RotateNorthControl(),
            ]),
       */
    });

    view.fit(mapExtent, map.getSize());
    //    view.fit(mapExtent, map.getSize());

    return map;
  }

  newMapPNG() {

    var imageWidth = 8333.00000000;
    var imageHeight = 5988.00000000;

    var mapExtent = [0.00000000, -imageHeight, imageWidth, 0.00000000];
    var mapMinZoom = 0;
    var mapMaxZoom = 6;
    var tileExtent = [0.00000000, -imageHeight, imageWidth, 0.00000000];
    var mapMaxResolution = 1.00000000;

    var mapResolutions = [];
    for (var z = 0; z <= mapMaxZoom; z++) {
      mapResolutions.push(Math.pow(2, mapMaxZoom - z) * mapMaxResolution);
    }

    var mapTileGrid = new TileGrid({
      extent: tileExtent,
      minZoom: mapMinZoom,
      resolutions: mapResolutions
    });

    var file_dir = "C:/Users/claudio/Dropbox/Work/data/MPT/HR/test/";
    var file_name = "DON_3171.png";
    //
    var file_path = file_dir + file_name;

    console.log('file_path = ' + file_path);

    console.log('__dirname = ' + __dirname);

    var APP_DIR = path.resolve(__dirname, 'src');
    console.log('APP_DIR = ' + APP_DIR);

    /*
        var A = os.path.join(os.path.dirname(__file__), '..')
        var B = os.path.dirname(os.path.realpath(__file__))
        var C = os.path.abspath(os.path.dirname(__file__))
    */
    //var A = os.path.join(os.path.dirname(__dirname), '..')
    //var B = os.path.realpath(APP_DIR);
    //var C = os.path.abspath(APP_DIR);

    //console.log('A = ' + A);
    //console.log('B = ' + B);
    //console.log('C = ' + C);

    var source = new ImageStatic({
      url: path,
      projection: 'PIXELS',
      crossOrigin: 'anonymous',
      imageExtent: mapExtent,
    });

    var layer = new ImageLayer({
      source: source
    });

    console.log('layer = ' + layer);

    var view = new View({
      constrainResolution: true,
    });

    var map = new Map({
      target: 'map',
      layers: [
        layer,
        //        featuresLayer
      ],
      view: view,
      controls: this.newControls(),
    });

    view.fit(mapExtent, map.getSize());
    //    view.fit(mapExtent, map.getSize());

    return map;
  }

  newControls() {

    /*
    var zoom = new Zoom({
      className: "zoom",
    });
    */

    var full_screen = new FullScreen();
    var zoom_all = new ZoomAllControl();

    var zoom = new Zoom();
    var rotate = new Rotate();

    var image = new ImageControl();

    var gps = new GpsControl();
    var file = new FileControl();
    var edit = new EditControl();
    var session = new SessionControl();

    var test = new TestControl();

    return [full_screen, rotate, zoom, zoom_all, image, edit, file, gps, session, test];
    return [full_screen, rotate, zoom, zoom_all, image, edit, file, session];
  }

  newInitialLayers_() {

    var background = new TileLayer({
      title: 'Background',
      source: new OSM()
    });

    var foreground = new VectorLayer({
      title: 'Foreground',
      source: new VectorSource(),
      declutter: true,
    });

    var gps = new VectorLayer({
      source: new VectorSource()
    });

    var work = new VectorLayer({
      title: 'Work',
      source: new VectorSource()
    });

    return [background, foreground, gps, work];
  }

  newInitialOverlays_() {
    //
    //var popup_feature = new PopupFeaature();
    //
    var overlay_feature = new Overlay({
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    });
    //
    //popup_feature.setOverlay(overlay_feature);

    return [overlay_feature];
  }

  newMap() {

    var layers = this.newInitialLayers_();
    var overlays = this.newInitialOverlays_();

    var view = new View({
      constrainResolution: true,
    });

    view = new View({
      center: [-11000000, 4600000],
      zoom: 4
    });

    var map = new MyMap({
      target: 'map',
      //*
      layers: [],
      //layers: layers != null ? layers : [],
      /*/
      layers: [
        new LayerGroup({
          // A layer must have a title to appear in the layerswitcher
          'title': 'Base maps',
          layers: [
            new LayerGroup({
              // A layer must have a title to appear in the layerswitcher
              title: 'Water color with labels',
              // Setting the layers type to 'base' results
              // in it having a radio button and only one
              // base layer being visibile at a time
              type: 'base',
              // Setting combine to true causes sub-layers to be hidden
              // in the layerswitcher, only the parent is shown
              combine: true,
              visible: false,
              layers: [
                new TileLayer({
                  source: new Stamen({
                    layer: 'watercolor'
                  })
                }),
                new TileLayer({
                  source: new Stamen({
                    layer: 'terrain-labels'
                  })
                })
              ]
            }),
            new TileLayer({
              // A layer must have a title to appear in the layerswitcher
              title: 'Water color',
              // Again set this layer as a base layer
              type: 'base',
              visible: false,
              source: new Stamen({
                layer: 'watercolor'
              })
            }),
            new TileLayer({
              // A layer must have a title to appear in the layerswitcher
              title: 'OSM',
              // Again set this layer as a base layer
              type: 'base',
              visible: true,
              source: new OSM()
            })
          ]
        }),
        new LayerGroup({
          // A layer must have a title to appear in the layerswitcher
          title: 'Overlays',
          // Adding a 'fold' property set to either 'open' or 'close' makes the group layer
          // collapsible
          fold: 'open',
          layers: [
            new ImageLayer({
              // A layer must have a title to appear in the layerswitcher
              title: 'Countries',
              source: new ImageArcGISRest({
                ratio: 1,
                params: { 'LAYERS': 'show:0' },
                url: "https://ons-inspire.esriuk.com/arcgis/rest/services/Administrative_Boundaries/Countries_December_2016_Boundaries/MapServer"
              })
            }),
            new LayerGroup({
              // A layer must have a title to appear in the layerswitcher
              title: 'Census',
              fold: 'open',
              layers: [
                new ImageLayer({
                  // A layer must have a title to appear in the layerswitcher
                  title: 'Districts',
                  source: new ImageArcGISRest({
                    ratio: 1,
                    params: { 'LAYERS': 'show:0' },
                    url: "https://ons-inspire.esriuk.com/arcgis/rest/services/Census_Boundaries/Census_Merged_Local_Authority_Districts_December_2011_Boundaries/MapServer"
                  })
                }),
                new ImageLayer({
                  // A layer must have a title to appear in the layerswitcher
                  title: 'Wards',
                  visible: false,
                  source: new ImageArcGISRest({
                    ratio: 1,
                    params: { 'LAYERS': 'show:0' },
                    url: "https://ons-inspire.esriuk.com/arcgis/rest/services/Census_Boundaries/Census_Merged_Wards_December_2011_Boundaries/MapServer"
                  })
                })
              ]
            })
          ]
        })
      ],
      /**/
      //overlays: overlays,
      overlays: [],
      view: view,
      controls: [],
      //controls: this.newControls(),
    });

    return map;
  }

  componentDidMount() {
    //
    console.log('MyReactMap:componentDidMount()...');
    console.log('  this.props = ', this.props);
    //
    console.log('  this = ', this);

    const camera = this.props.getCamera();
    //
    console.log('  camera = ', camera);

    //*
    var map = this.newMap();
    //
    map.setCamera(camera);
    /*/
    var layers = this.newInitialLayers();
    //
    var map = this.newMap(layers);
    /**/
    //
    //map.getLayerGroup().setLayers(layers);

    map.on('change', function () {

      console.log('componentDidUpdate: ' + 'INIT');

    });

    /*
    map.windowTitle = document.getElementById('title');
    map.windowAttribution = document.getElementById('attribution');
    map.windowMousePosition = document.getElementById('position');
    map.windowVersion = document.getElementById('version');
    map.windowInfo = document.getElementById('info');

    if (map.windowVersion)
      map.windowVersion.innerHTML = 'Hstorical Reality - V. 0.1';
    */
  }

  componentDidUpdate() {
    //
    console.log('MyReactMap.componentDidUpdate()...');
    //alert('MyReactMap.componentDidUpdate()...');
  }

  componentWillUnmount() {
    //
    console.log('MyReactMap.componentWillUnmount()...');
    //alert('MyReactMap.componentWillUnmount()...');
  }

  render() {

    console.log('render: ' + 'INIT');

    return (
      <div>

        <div id="map" className="map" className="map" >
        </div>

        <div id="titlebar" className="titlebar">

          <div id="title" className="title" >
            TITLE
          </div>

          <div id="attribution" className="attribution">
            ATTRIBUTION
          </div>

        </div>

        <div id="position" className="position">
          POSITION
        </div>

        <div id="statusbar" className="statusbar">

          <div id="contact" className="contact button" title="Contact">
            c
          </div>
          <div id="info" className="info button" title="About Historical Reality">
            i
          </div>
          <div id="help" className="help button" title="Help">
            ?
          </div>

        </div>

      </div>
    );

    /*

      <div id="version" class="version">
          VERSION
      </div>


        return (
      <div id="map" className="map" >
        <div id="title" className="title" >
          TITLE
        </div>
        <div id="attribution" class="attribution">
          ATTRIBUTION
        </div>
      </div>
    );
   
        return (
      <div id="map" className="map" >
        <div id="mouse-position" className="mouse-position"></div>
      </div>
    );
   
        const element = (<div>Text from Element</div>)
        return (<div className="comptext">
        <h3>First Component</h3>
            {this.props.displaytext}
            {element}
        </div>)
    */

    /*
        return (
          <div ref="mapContainer" className="App-map"> </div>
        );
    */
  }
}

export default MyReactMap;